import { AxiosInstance } from "axios";

import {
	GetSpecialAccountScope,
	ICardImage,
	IIncustCheckPayload,
	IIncustCouponAddedResultUser,
	IIncustMessage,
	IIncustUserCardQr,
	IInvitationInfo,
	ILoyaltyAcceptInvitation,
	ILoyaltyInfo,
	ILoyaltyReferralCode,
	IncustWallet,
	IReferralProgramChain,
	IReferralProgramSummary,
	SpecialAccountsMapping,
} from "./types";
import {
	IIncustCheck,
	IIncustCoupon,
	IIncustCouponExt,
	IIncustSettings,
	IncustTransaction,
	SpecialAccount,
} from "../../../shop/loyalty/types";


export default function ShopLoyaltyModule(apiInstance: AxiosInstance) {
	const getHeaders = (token: string | null | undefined) =>
		token ? { "Authorization": `Bearer ${token}` } : undefined;
	const getPath = (path: string) => `/external/incust/${path}`;

	return {
		async processCheck(brand_id: number, payload: IIncustCheckPayload) {
			return apiInstance.post<IIncustCheck>(getPath(`process_check/${brand_id}`), payload);
		},

		async getIncustSettings(brandId: number, storeId?: number | null, invoiceTemplateId?: number | null) {
			let params = {}
			if(storeId){
				params = {store_id: storeId}
			}
			if(invoiceTemplateId){
				params = {invoice_template_id: invoiceTemplateId}
			}
			return apiInstance.get<IIncustSettings>(getPath(`settings/${brandId}`), {params: params});
		},

		async getIncustUserCardQr(brandId: number, storeId: number | null) {
			let params = {}
			if (storeId){
				params = {store_id: storeId}
			}
			return apiInstance.get<IIncustUserCardQr>(getPath(`user_qr_card/${brandId}`), {params: params});
		},

		async getUserCardImageLink(groupId: number, args: ICardImage, asLink: boolean = false) {
			let url = getPath(`${groupId}/card_image`);
			url += `?card_code=${args.card_code}`;
			url += `&username=${args.username}`;
			url += `&qr=${encodeURIComponent(args.qr)}`;
			url += `&title=${args.title}`;
			url += `&photo=${args.photo}`;
			if (asLink) return url;

			return await apiInstance.get(
				getPath(`${groupId}/card_image`), { params: args, responseType: "blob" },
			);
		},
		async getCouponInfo(brandId: number, id: string) {
			return await apiInstance.get<IIncustCoupon>(
				getPath(`get_coupon_info/${brandId}/${id}`),
			);
		},
		async addCouponToWallet(brandId: number, couponCode: string, storeId?: number | null) {
			let payload = null
			if(storeId){
				payload = {store_id: storeId}
			}
			return await apiInstance.post<IIncustCouponAddedResultUser>(
				getPath(`add_coupon_to_wallet/${brandId}/${couponCode}`), payload,
			);
		},
		async redeemUserCoupon(brandId: number, couponId: string) {
			return await apiInstance.post<IIncustMessage>(
				getPath(`redeem/${brandId}/${couponId}`),
			);
		},
		async redeemTermCoupon(brandId: number, couponId: string) {
			return await apiInstance.post<IIncustMessage>(
				getPath(`redeem_term/${brandId}/${couponId}`),
			);
		},
		async getUserCoupons(brandId: number, couponIds?: string[] | null) {
			let url = getPath(`${brandId}/coupons`);
			if (couponIds) {
				url += `?ids=${couponIds.join(",")}`;
			}
			return await apiInstance.get<IIncustCoupon[]>(url);
		},
		async getCouponById(brandId: number, couponId: string) {
			return await apiInstance.get<IIncustCoupon>(
				getPath(`${brandId}/coupons/${couponId}`),
			);
		},
		async getReferralCode(brandId: number) {
			return await apiInstance.get<ILoyaltyReferralCode>(getPath(`get_referral_code/${brandId}`));
		},
		async acceptInvitation(brandId: number, code: string, storeId?: number | null) {
			let payload = null
			if(storeId){
				payload = {store_id: storeId}
			}
			return await apiInstance.post<ILoyaltyAcceptInvitation>(
				getPath(`accept_invitation/${brandId}/${code}`), payload,
			);
		},
		async getLoyaltySettings() {
			return await apiInstance.get<ILoyaltyInfo>(getPath(`loyalty_settings`));
		},
		async getInvitationInfo(brandId: number, code: string) {
			return await apiInstance.get<IInvitationInfo>(getPath(`invitation/${brandId}/${code}`));
		},
		async getReferralSummary(brandId: number) {
			return await apiInstance.get<IReferralProgramSummary>(getPath(`referral_summary/${brandId}`));
		},
		async getReferralChain(brandId: number, withRewards: boolean) {
			return await apiInstance.get<IReferralProgramChain>(
				getPath(`referral_chain/${brandId}`), {
					params: { with_rewards: withRewards },
				},
			);
		},
		async getTransaction(brandId: number, transaction_id: string) {
			return await apiInstance.get<IncustTransaction>(
				getPath(`${brandId}/transactions/${transaction_id}`),
			);
		},
		async shareCoupon(brandId: number, coupon_id: string) {
			return await apiInstance.get<IIncustMessage>(
				getPath(`${brandId}/share/${coupon_id}`),
			);
		},

		async deleteCoupon(brandId: number, coupon_id: string) {
			return await apiInstance.delete<IIncustMessage>(
				getPath(`${brandId}/coupons/${coupon_id}`),
			);
		},

		async getSpecialAccounts(scope?: GetSpecialAccountScope) {
			return await apiInstance.get<SpecialAccount[]>(
				getPath("special_account"),
				{
					params: { scope },
				},
			);
		},

		async getSpecialAccountsMapping(scope?: GetSpecialAccountScope, storeId?: number | null) {
			let params: {store_id?: number | null, scope?: GetSpecialAccountScope} = {}
			if(storeId){
				params.store_id = storeId
			}
			if(scope){
				params.scope = scope
			}

			return await apiInstance.get<SpecialAccountsMapping>(
				getPath("special_accounts_mapping"),
				{
					params: params,
				},
			);
		},
		async processCoupon(codeOrId: string, storeId?: number | null) {
			let params = {}
			if(storeId){
				params = {store_id: storeId}
			}
			return await apiInstance.get<IIncustCouponExt>(
				getPath(`coupons/${codeOrId}/process`), {params: params},
			);
		},

		async checkCoupon(coupon_id: string, orderToken: string | null | undefined = null) {
			let config: { headers?: any } = {};
			if (orderToken)
				config.headers = getHeaders(orderToken);
			let url = getPath(`coupons/${coupon_id}/check`);
			return await apiInstance.get<IIncustCouponExt>(url, config);
		},

		async getWallet() {
			return await apiInstance.get<IncustWallet[]>(
				getPath("wallet"),
			);
		},
	};
}
