import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Button, CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";
import { AspectRatio } from "@mui/joy";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMediaAspectRatio from "../../features/CardMediaAspectRatio";

import api from "../../api";
import useAppContext from "../../useAppContext";
import useCurrency from "../../services/useCurrencyService";
import useIncustService from "../../features/services/useIncustService";
import ImageStub from "../../features/ImageStub";
import { ICouponBatch, IInvitationInfo, ILoyaltyInfo } from "../../api/shop/loyalty/types";
import useScreenService from "../../services/useScreenService";
import formatCurrency from "../../helpers/formatCurrency";
import { ShareAndEarnContentItem } from "../profile/pages/ProfileShareAndEarn";
import changePage from "../../helpers/changePage";
import CollapsableComponent from "../../features/CollapsableComponent";
import { ApiUrl } from "../../config";
import Interweave from "../../features/Interweave";
import { useSelectedStoreOptional } from "../SelectedStore/context";
import useUserIncustCustomerData from "../../hooks/incust/useUserIncustCustmerData";
import ShareAndEarnAuthAuth from "./ShareAndEarnAuth";

const LoyaltyImage = styled("img")({
	borderRadius: 4,
	borderBottomLeftRadius: 0,
	borderBottomRightRadius: 0,
	width: "100%",
	height: "auto",
});

export default function ShareAndEarnPage() {
	const {
		brandInfo,
		setPageTitle,
		loadingManager: { setIsLoading: lmSetIsLoading },
		localisation: { profile },
		lang,
	} = useAppContext();

	const { referralCode } = useParams();

	const [loyaltySettings, setLoyaltySettings] = useState<ILoyaltyInfo | null>(null);
	const [invitationInfo, setInvitationInfo] = useState<IInvitationInfo | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [acceptLoading, setAcceptLoading] = useState<boolean>(false);

	const isValidRefCode = useMemo(() => {
		return referralCode?.startsWith("5") && referralCode?.length === 12;
	}, [referralCode]);

	const handleBeforeUnload = useCallback(
		(e: BeforeUnloadEvent) => {
			if (acceptLoading) {
				e.preventDefault();
			}
		},
		[acceptLoading]
	);

	useEffect(() => {
		if (!isValidRefCode) {
			setError(profile.notValidReferralCode);
		}
	}, [isValidRefCode, profile.notValidReferralCode]);

	useEffect(() => {
		setPageTitle(profile.acceptInvitation);
	}, [setPageTitle, profile.acceptInvitation]);

	useEffect(() => {
		if (brandInfo && referralCode && isValidRefCode && lang) {
			try {
				lmSetIsLoading("invitation", true);

				api.shop.loyalty
					.getLoyaltySettings()
					.then(response => setLoyaltySettings(response.data));

				api.shop.loyalty
					.getInvitationInfo(brandInfo?.id, referralCode)
					.then(response => setInvitationInfo(response.data));
			} catch (ex: any) {
				setError(ex.response?.data?.detail || ex.message);
			} finally {
				lmSetIsLoading("invitation", false);
			}
		}
	}, [brandInfo, lmSetIsLoading, referralCode, isValidRefCode, lang]);

	useEffect(() => {
		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [handleBeforeUnload]);

	return (
		<Container
			maxWidth={"lg"}
			sx={{
				p: 0,
				mt: 3,
				flex: 1,
				width: "100%",
				display: "flex",
				alignItems: "center",
			}}
		>
			<Paper elevation={3} sx={{ width: "100%" }}>
				{!!(error || !referralCode) ? (
					<Box p={3}>
						<Typography variant={"h6"} color={"error"}>
							<Interweave content={error ? error : profile.noReferralCodeErr} />
						</Typography>
					</Box>
				) : (
					<Invitation
						invitationInfo={invitationInfo as IInvitationInfo}
						loyaltyInfo={loyaltySettings as ILoyaltyInfo}
						referralCode={referralCode}
						acceptLoading={acceptLoading}
						setAcceptLoading={setAcceptLoading}
					/>
				)}
			</Paper>
		</Container>
	);
}

interface IInvitationProps {
	invitationInfo: IInvitationInfo;
	loyaltyInfo: ILoyaltyInfo;
	referralCode: string;
	photo?: string;
	acceptLoading: boolean;
	setAcceptLoading: (value: boolean) => void;
}

function Invitation(props: IInvitationProps) {
	const { brandInfo, lang } = useAppContext();
	const selectedStore = useSelectedStoreOptional();

	const currency = useCurrency();
	const incustService = useIncustService(
		brandInfo?.id,
		selectedStore?.id, null,
		brandInfo?.incust_data,
		lang,
		currency || ""
	);

	const computedLoyaltyPhoto = useMemo(() => {
		if (props.loyaltyInfo?.referral_program?.referral_logo?.url) {
			return props.loyaltyInfo.referral_program?.referral_logo.url;
		}

		if (
			incustService.settings &&
			!!incustService.settings.loyalty.photos &&
			incustService.settings.loyalty.photos.length > 0
		) {
			return incustService.settings.loyalty.photos[0];
		}
		return `${ApiUrl}/static/images/default-404-image.png`;
	}, [incustService.settings, props.loyaltyInfo?.referral_program?.referral_logo?.url]);

	return (
		<Grid container columnSpacing={3} justifyContent={"center"} alignItems={"stretch"}>
			<Grid
				item
				xs={12}
				md={5}
				py={3}
				display={"flex"}
				alignItems={"center"}
				sx={{
					borderRight: { xs: 0, md: 1 },
					borderRightColor: { xs: "none", md: "text.primary" },
					pr: { xs: 0, md: 2 },
				}}
			>
				<Box sx={{ width: "100%" }}>
					<AspectRatio ratio={"6/4"} objectFit={"contain"}>
						{!!(
							incustService.settings?.loyalty.photos &&
							incustService.settings?.loyalty.photos.length > 0
						) ? (
							<LoyaltyImage
								src={computedLoyaltyPhoto}
								alt={incustService.settings?.loyalty.title || ""}
							/>
						) : (
							<ImageStub emptyStub={false} ratio={[6, 4]} />
						)}
					</AspectRatio>
				</Box>
			</Grid>

			<Grid item xs={12} md={7} alignSelf={"center"} textAlign={"center"} p={3}>
				{!!(props.invitationInfo && props.loyaltyInfo && props.referralCode) && (
					<InvitationContent
						invitationInfo={props.invitationInfo}
						loyaltyInfo={props.loyaltyInfo}
						referralCode={props.referralCode}
						photo={computedLoyaltyPhoto}
						acceptLoading={props.acceptLoading}
						setAcceptLoading={props.setAcceptLoading}
					/>
				)}
			</Grid>
		</Grid>
	);
}

function InvitationContent(props: IInvitationProps) {
	const {
		brandInfo,
		authService: { user, isLoading: isAuthLoading },
		localisation: { profile, global },
	} = useAppContext();
	const store = useSelectedStoreOptional();

	const { isMobile } = useScreenService();

	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const [isLoggedInByMessanger, setIsLoggedInByMessanger] = useState<boolean>(false);

	const [showCustomAuth, setShowCustomAuth] = useState(false);

	const { customerData, isCustomerFetching } = useUserIncustCustomerData();

	const computedReferralUser = useMemo(() => {
		if (props.invitationInfo.referrer_user?.name) {
			return props.invitationInfo.referrer_user.name;
		}

		if (props.invitationInfo.referrer_user?.phone) {
			const stringLength = props.invitationInfo.referrer_user.phone.length;
			if (stringLength <= 3) return props.invitationInfo.referrer_user.phone;
			return props.invitationInfo.referrer_user.phone.substring(0, stringLength - 3) + "***";
		}

		if (props.invitationInfo.referrer_user?.email) {
			const stringLength = props.invitationInfo.referrer_user.email.length;
			if (stringLength <= 3) return props.invitationInfo.referrer_user.email;
			return "***" + props.invitationInfo.referrer_user.email.substring(3);
		}

		if (props.invitationInfo.referrer_user?.external_id) {
			const stringLength = props.invitationInfo.referrer_user.external_id.length;
			if (stringLength <= 3) return props.invitationInfo.referrer_user.external_id;
			return (
				props.invitationInfo.referrer_user.external_id.substring(0, stringLength - 3) +
				"***"
			);
		}

		const stringLength = props.invitationInfo.referrer_user?.id?.length || 0;
		if (stringLength <= 3) return props.invitationInfo.referrer_user?.id || "";

		return props.invitationInfo.referrer_user?.id?.substring(0, stringLength - 3) + "***";
	}, [
		props.invitationInfo.referrer_user?.email,
		props.invitationInfo.referrer_user?.external_id,
		props.invitationInfo.referrer_user?.id,
		props.invitationInfo.referrer_user?.phone,
		props.invitationInfo.referrer_user?.name,
	]);

	const computedIsSameUser = useMemo(() => {
		return customerData?.user_card?.customer?.id === props.invitationInfo.referrer_user?.id;
	}, [props.invitationInfo.referrer_user?.id, customerData?.user_card?.customer?.id]);

	const setAcceptLoading = props.setAcceptLoading;
	const acceptLoading = props.acceptLoading;
	const acceptInvitation = useCallback(async () => {
		if (!brandInfo || !props.referralCode) return;

		if (!user) {
			return setShowCustomAuth(true);
		}

		setAcceptLoading(true);
		try {
			if (isLoggedInByMessanger) return setSuccess(true);

			const response = await api.shop.loyalty.acceptInvitation(
				brandInfo.id,
				props.referralCode,
				store?.id
			);
			if (response.status === 200 && response.data.message === "Ok") {
				setSuccess(true);
			} else {
				if (response.data.message) setError(response.data.message);
			}
		} catch (ex: any) {
			setError(ex.response?.data?.detail || ex.message);
		} finally {
			setAcceptLoading(false);
			setIsLoggedIn(false);
			setIsLoggedInByMessanger(false);
		}
	}, [brandInfo, props.referralCode, user, setAcceptLoading, isLoggedInByMessanger, store?.id]);

	const onAuthCallback = useCallback(() => {
		setIsLoggedIn(true);
	}, []);

	const computedDescription = useMemo(() => {
		if (props.loyaltyInfo.referral_program?.referral_description) {
			return props.loyaltyInfo.referral_program?.referral_description;
		}
		if (props.loyaltyInfo.referral_program?.description) {
			return props.loyaltyInfo.referral_program?.description;
		}

		return "";
	}, [
		props.loyaltyInfo.referral_program?.description,
		props.loyaltyInfo.referral_program?.referral_description,
	]);

	const computedTitle = useMemo(() => {
		if (props.loyaltyInfo.referral_program?.referral_title) {
			return props.loyaltyInfo.referral_program?.referral_title;
		}
		if (props.loyaltyInfo.referral_program?.title) {
			return props.loyaltyInfo.referral_program?.title;
		}

		return "";
	}, [
		props.loyaltyInfo.referral_program?.referral_title,
		props.loyaltyInfo.referral_program?.title,
	]);

	useEffect(() => {
		if (!user) setError(null);
	}, [user]);

	useEffect(() => {
		if (isLoggedIn && customerData?.token && !success && !acceptLoading)
			acceptInvitation().then();
	}, [acceptInvitation, acceptLoading, isLoggedIn, success, customerData?.token]);

	useEffect(() => {
		document.addEventListener("onAuth", onAuthCallback);

		return () => {
			document.removeEventListener("onAuth", onAuthCallback);
		};
	}, [onAuthCallback]);

	return (
		<Box ml={isMobile ? 3 : 0} sx={{ wordBreak: "break-word" }}>
			{!!computedTitle && (
				<Typography variant={"h4"} sx={{ my: 2 }}>
					{computedTitle}
				</Typography>
			)}

			{!!computedDescription &&
				(isMobile ? (
					<CollapsableComponent collapsedSize={47} sx={{ width: "100%" }}>
						<Typography
							variant={"body1"}
							sx={{ mb: 4, pb: 2 }}
							className={"border-bottom"}
						>
							<Interweave content={computedDescription.replaceAll("\n", "<br/>")} />
						</Typography>
					</CollapsableComponent>
				) : (
					<Typography variant={"body1"} sx={{ mb: 4, pb: 2 }} className={"border-bottom"}>
						<Interweave content={computedDescription.replaceAll("\n", "<br/>")} />
					</Typography>
				))}

			{!computedIsSameUser && (
				<Typography variant={"h6"} sx={{ mb: 3 }}>
					<>{profile.invitedHeader.replace("{referrer_id}", computedReferralUser)}</>
				</Typography>
			)}

			{!computedIsSameUser &&
				(success ? (
					<>
						<Alert
							severity={"success"}
							variant={"outlined"}
							className={"small p-3 w-100 mt-2 text-start"}
						>
							<Interweave content={profile.invitationAccepted} />
						</Alert>

						<Benefits loyaltyInfo={props.loyaltyInfo} />

						<Box sx={{ width: "100%", mt: 2 }} textAlign={"center"}>
							<Button variant={"text"} onClick={() => changePage("/")}>
								{global.backHome}
							</Button>
						</Box>
					</>
				) : (
					<Button
						variant={"contained"}
						disabled={acceptLoading || isAuthLoading || isCustomerFetching}
						onClick={acceptInvitation}
					>
						{(acceptLoading || isAuthLoading || isCustomerFetching) && (
							<CircularProgress
								style={{ width: "18px", height: "18px" }}
								className={"me-2"}
							/>
						)}
						{profile.acceptInvitation}
					</Button>
				))}

			{computedIsSameUser && (
				<SameUserContent referralCode={props.referralCode} photo={props.photo || ""} />
			)}

			{!!error && (
				<Alert
					severity={"error"}
					variant={"outlined"}
					className={"small p-3 w-100 mt-2 text-start"}
				>
					<Interweave content={error} />
				</Alert>
			)}

			<ShareAndEarnAuthAuth
				show={showCustomAuth}
				setShow={setShowCustomAuth}
				refCode={props.referralCode}
				externalLoginCallback={() => setIsLoggedInByMessanger(true)}
			/>
		</Box>
	);
}

interface ISameUserContentProps {
	referralCode: string;
	photo: string;
}

function SameUserContent(props: ISameUserContentProps) {
	const {
		localisation: { profile },
	} = useAppContext();
	const store = useSelectedStoreOptional();

	const baseUrl = window.location.origin;

	const computedShareLink = useMemo(() => {
		if (store?.id) {
			return `${baseUrl}/shop/${store.id}/share_and_earn/${props.referralCode}`;
		}
		return `${baseUrl}/shop/share_and_earn/${props.referralCode}`;
	}, [baseUrl, props.referralCode, store?.id]);

	return (
		<Box textAlign={"start"}>
			<ShareAndEarnContentItem
				linkText={profile.sameReferralHeader}
				qrUrl={computedShareLink}
				qrText={profile.shareQrHeader}
				photo={props.photo}
			/>
		</Box>
	);
}

interface IBenefitsProps {
	loyaltyInfo: ILoyaltyInfo;
}

function Benefits(props: IBenefitsProps) {
	const {
		localisation: { profile },
		lang,
		brandInfo,
	} = useAppContext();

	const currency = useCurrency();

	const computedCurrency = useMemo(() => {
		if (props.loyaltyInfo.referral_program?.referral_reward_bonuses_currency) {
			return props.loyaltyInfo.referral_program.referral_reward_bonuses_currency;
		}
		return currency;
	}, [currency, props.loyaltyInfo.referral_program?.referral_reward_bonuses_currency]);

	return (
		<Box textAlign={"start"} mt={2} sx={{ wordBreak: "break-word" }}>
			{props.loyaltyInfo.referral_program?.referral_reward_type === "coupon" && (
				<>
					<Typography variant={"h6"}>{profile.loyaltyVouchersAwardedAfter}:</Typography>
					{!!props.loyaltyInfo.referral_program.referral_reward_coupon_batch && (
						<BenefitCoupon
							coupon={props.loyaltyInfo.referral_program.referral_reward_coupon_batch}
						/>
					)}
				</>
			)}
			{(props.loyaltyInfo.referral_program?.referral_reward_type === "regular-bonuses" ||
				props.loyaltyInfo.referral_program?.referral_reward_type ===
					"promotional-bonuses") && (
				<>
					<Typography variant={"h6"}>{profile.loyaltyBonusesAwardedAfter}:</Typography>
					<Typography variant={"h6"}>
						{formatCurrency(
							props.loyaltyInfo.referral_program.referral_reward_value || 0,
							brandInfo?.default_lang || lang,
							computedCurrency || ""
						)}
					</Typography>
				</>
			)}
			{props.loyaltyInfo.referral_program?.referral_reward_type === "special-account" && (
				<>
					<Typography variant={"h6"}>
						{profile.accountAwarded.replace(
							"{account}",
							props.loyaltyInfo.referral_program.referral_reward_special_account
								?.title || ""
						)}
						:
					</Typography>
					<Typography variant={"h6"}>
						{formatCurrency(
							props.loyaltyInfo.referral_program.referral_reward_value || 0,
							brandInfo?.default_lang || lang,
							computedCurrency || ""
						)}
					</Typography>
				</>
			)}
		</Box>
	);
}

interface IBenefitCouponProps {
	coupon: ICouponBatch;
}

function BenefitCoupon(props: IBenefitCouponProps) {
	const { isMobile } = useScreenService();

	return (
		<Card className={"no-shadow border mt-1"} sx={{ width: isMobile ? "50%" : "30%" }}>
			{props.coupon.image ? (
				<CardMediaAspectRatio
					aspectRatio={"16/9"}
					imageSrc={props.coupon.image}
					alt={props.coupon.title || ""}
					sx={{ flex: "1 0 auto" }}
				/>
			) : (
				<ImageStub emptyStub={false} ratio={[16, 9]} />
			)}

			<CardHeader sx={{ py: 2 }} title={<Interweave content={props.coupon.title} />} />

			<CardContent className={"py-0"}>
				<Interweave content={props.coupon.description} />
			</CardContent>
		</Card>
	);
}
