import api from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { useShopContext } from "../../../shop/context";
import useAppContext from "../../../useAppContext";

export const useGetClientWebPage = (slug?: string) => {
	const { brandInfo } = useShopContext();
	const { lang } = useAppContext();

	return useQuery({
		queryKey: ["client_web_pages/one", slug, lang],
		queryFn: () =>
			api.clientWebPages.getClientWebPage(brandInfo.group_id, slug).then(({ data }) => data),
		enabled: !!slug?.length,
	});
};
