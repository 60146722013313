import { Outlet } from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";
import EmailAlert from "../../components/alerts/EmailAlert";
import SelectedStoreShopHeader from "../ShopHeader/SelectedStoreShopHeader";
import SelectedStoreShopFooter from "../ShopFooter/SelectedStoreShopFooter";
import MainSidebarDrawer from "../Sidebars/MainSidebarDrawer/MainSidebarDrawer";
import ProfileSidebarDrawer from "../Sidebars/ProfileSidebarDrawer/ProfileSidebarDrawer";
import { SelectedStoreContextProvider, useSelectedStoreContextStoreOptional } from "./context";

export function SelectedStoreWrapper() {
	return (
		<SelectedStoreContextProvider>
			<SelectedStoreWrapperInner />
		</SelectedStoreContextProvider>
	);
}

function SelectedStoreWrapperInner() {
	const { selectedStore } = useSelectedStoreContextStoreOptional();

	if (!selectedStore) return null;

	const needEmailAlert = !window.location.pathname.includes("share_and_earn");

	return (
		<>
			<SelectedStoreShopHeader />
			<MainSidebarDrawer withStore={true} />
			<ProfileSidebarDrawer />

			<MDBContainer
				id={"shop-container-main"}
				breakpoint={"xxl"}
				className={"px-0 pt-2"}
				style={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
				}}
			>
				{needEmailAlert && <EmailAlert />}
				<Outlet />
			</MDBContainer>

			<SelectedStoreShopFooter />
		</>
	);
}
