import { OutputBlockData } from "@editorjs/editorjs";
import { JsEditorListParserItem } from "../types";

/**
 * Parses a block of data from the Editor.js editor and converts it into an HTML string representation of a list.
 *
 * @param jsEditorBlockData - The block data from Editor.js containing the list information.
 * @returns The HTML string representation of the list.
 *
 * @example
 * ```typescript
 * import { OutputBlockData } from "@editorjs/editorjs";
 * import { listParserPlugin } from "./listParser.plugin";
 *
 * const blockData: OutputBlockData = {
 *   id: "block-id",
 *   type: "list",
 *   data: {
 *     style: "ordered",
 *     items: [
 *       { content: "First item" },
 *       { content: "Second item" }
 *     ]
 *   }
 * };
 *
 * const htmlString = listParserPlugin(blockData);
 * console.log(htmlString);
 * // Output: <ol><li>First item</li><li>Second item</li></ol>
 * ```
 */
export const listParserPlugin = (jsEditorBlockData: OutputBlockData): string => {
	const renderChecklist = (items: JsEditorListParserItem[]): string => {
		return items
			.map(
				item => `
          <li style="list-style: none; display: flex; align-items: center; margin-bottom: 4px;">
			<div style="width: 19px; height: 19px; border: 1px solid ${item?.meta?.checked ? "#369fff" : "#c9c9c9"}; border-radius: 5px; margin-right: 8px; background-color: ${item.meta?.checked ? "#369fff" : "white"}; display: flex; justify-content: center; align-items: center;">
				<svg xmlns="http://www.w3.org/2000/svg" style="display: ${item?.meta?.checked ? "block" : "none"}" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="#fff" stroke-linecap="round" stroke-width="2" d="M7 12L10.4884 15.8372C10.5677 15.9245 10.705 15.9245 10.7844 15.8372L17 9"></path></svg>
			</div>
            <span>${item.content}</span>
          </li>`
			)
			.join("");
	};

	if (jsEditorBlockData?.data?.style === "ordered") {
		const items = jsEditorBlockData.data.items
			.map(
				(item: { content: string }) =>
					`<li style="margin-bottom: 4px;">${item?.content}</li>`
			)
			.join("");
		return `<ol>${items}</ol>`;
	} else if (jsEditorBlockData?.data?.style === "unordered") {
		const items = jsEditorBlockData.data.items
			.map(
				(item: { content: string }) =>
					`<li style="list-style-type: none; position: relative; margin-bottom: 4px;">
						<span style="position: absolute; left: -1em; top: 50%; transform: translateY(-50%); content: ''; background-color: rgb(54, 65, 82); width: 4px; height: 4px; border-radius: 10px; "></span>
						${item?.content}
					</li>`
			)
			.join("");
		return `<ul>${items}</ul>`;
	} else if (jsEditorBlockData.data?.style === "checklist") {
		const items = renderChecklist(jsEditorBlockData.data.items);
		return `<ul>${items}</ul>`;
	} else {
		return "";
	}
};

// <input
//               type="checkbox"
//               disabled
//               ${item.meta?.checked ? "checked" : undefined}
//               style="margin-right: 8px;"
//             />
