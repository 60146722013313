import { ChangeEvent, useMemo } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { getPrivacyPolicyLink, getTermsOfUseLink } from "../../../helpers/consents";
import useAppContext from "../../../useAppContext";
import Interweave from "../../../features/Interweave";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";

interface AgreementCheckboxProps {
	value: boolean;
	setValue: (newValue: boolean) => void;
	isOfferEnabled?: boolean;
}

export default function AgreementCheckbox(props: AgreementCheckboxProps) {
	const { lang, brandInfo, localisation } = useAppContext();
	const [searchParams] = useSearchParamsFixed();

	const handleIsAcceptAgreementChanged = (e: ChangeEvent<HTMLInputElement>) => {
		props.setValue(e.target.checked);
	};

	const computedText = useMemo(() => {
		if (props.isOfferEnabled) {
			return localisation.auth.agreementAndOfferText
				.replace("{terms_of_use_link}", getTermsOfUseLink(brandInfo, lang))
				.replace("{privacy_policy_link}", getPrivacyPolicyLink(brandInfo, lang))
				.replace("{offer_link}", `/shop/document/agreement/?${searchParams.toString()}`);
		}
		return localisation.auth.agreementText
			.replace("{terms_of_use_link}", getTermsOfUseLink(brandInfo, lang))
			.replace("{privacy_policy_link}", getPrivacyPolicyLink(brandInfo, lang));
	}, [
		brandInfo,
		lang,
		localisation.auth.agreementAndOfferText,
		localisation.auth.agreementText,
		props.isOfferEnabled,
		searchParams,
	]);

	return (
		<FormControlLabel
			sx={{ mt: 1 }}
			control={
				<Checkbox
					name={"Agreement"}
					checked={props.value}
					onChange={handleIsAcceptAgreementChanged}
				/>
			}
			label={<Interweave className={"user-select-none"} content={computedText} />}
		/>
	);
}
