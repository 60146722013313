import React from "react";
import rehypeRaw from "rehype-raw";
import { Box } from "@mui/material";
import Markdown from "react-markdown";
import { RichTextRendererOptions } from "./types";
import { useRichTextRendererPlugins } from "./hooks/useRichTextRendererPlugins";

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		edjsHTML: any;
	}
}

const RichTextRenderer = React.memo((props: RichTextRendererOptions): React.JSX.Element => {
	const plugins = useRichTextRendererPlugins(props?.selectedPluginsRichTextRenderer);

	const rendererContent = React.useMemo(() => {
		if (props?.rendererRicheTextEditorContent) {
			const edjsParser = window?.edjsHTML(plugins);
			const htmlContent = String(edjsParser?.parse(props?.rendererRicheTextEditorContent));
			return htmlContent;
		} else if (props?.rendererTextContent) {
			return props?.rendererTextContent;
		} else {
			return props?.localization?.noContentToRender || "";
		}
	}, [plugins, props?.rendererRicheTextEditorContent, props?.rendererTextContent]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 1,
				flex: 1,
				px: "12px",
				minHeight: "100%",

				"& *": {
					margin: 0,
				},

				...props?.richeTextRendererContainerStyles,
			}}
		>
			<Markdown rehypePlugins={[rehypeRaw]}>{rendererContent}</Markdown>
		</Box>
	);
});

export default RichTextRenderer;
