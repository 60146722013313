import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { IUseIncustServiceType, OnCheckProcessedCallbackType } from "./types";
import api from "../../../api";
import { IIncustCheckItem, IIncustCoupon, IIncustSettings } from "../../../shop/loyalty/types";
import { getBonusesItemByCurrency, makeIncustCheck } from "./functions";
import { IIncustCheckPayload, IIncustData, ILoyaltyInfo, IncustRulesType } from "../../../api/shop/loyalty/types";
import useAppContext from "../../../useAppContext";
import { useShopContext } from "../../../shop/context";
import { InvoiceTemplateItem } from "../../../api/invoice/types";
import { Cart } from "../../../api/shop/cart/types";
import { useLocation } from "react-router-dom";
import useDebounced from "../../hooks/useDebounced";
import { AxiosError } from "axios";
import { Product } from "../../../api/shop/basic/types";
import useUserIncustCustomerData from "../../../hooks/incust/useUserIncustCustmerData";
import f from "../../../helpers/formatText";

export const IncustLoyaltyBonusesStorageKey = "incust-loyalty-bonuses";
export const IncustLoyaltyCouponsStorageKey = "incust-loyalty-coupons";
export const IncustLoyaltyAppliedCouponsStorageKey = "incust-loyalty-coupons";

const processCheckAvailablePages = ["cart", "checkout", "fast_pay", "qr_menu"];

export default function useIncustService(
	brandId: number | null | undefined,
	storeId: number | null | undefined,
	invoiceTemplateId: number | null | undefined,
	incustData: IIncustData | null | undefined,
	lang: string | null,
	currency: string,
	checkItems?: IIncustCheckItem[] | null,
	checkAmount?: number | null,
	enabled: boolean = true,
	forceProcessCheckAvailableOnPage: boolean = false,
	rulesType: IncustRulesType | null = null,
	defaultRedeemBonusesValue: number | null = null,
): IUseIncustServiceType {
	const { localisation, showError, brandInfo } = useAppContext();

	const location = useLocation();

	// for calculating computedMaxBonusesForCheck
	const [checkDiscountAmount, setCheckDiscountAmount] = useState<number | null>(null);

	const [bonusesRedeemInput, setBonusesRedeemInput] = useState("");
	const [bonusesRedeem, setBonusesRedeem] = useState<number>(0.0);
	const [usedCoupons, setUsedCoupons] = useState<IIncustCoupon[] | null>(null);

	const [processCheckLoading, setProcessCheckLoading] = useState<boolean>(false);

	const [processCheckPayload, setProcessCheckPayload] = useState<IIncustCheckPayload | null>(
		null
	);

	const onCheckProcessedCallbackRef = useRef<OnCheckProcessedCallbackType | null>(null);
	const setOnCheckProcessedCallback = useCallback(
		(callback: OnCheckProcessedCallbackType | null) => {
			onCheckProcessedCallbackRef.current = callback;
		},
		[]
	);

	const isIncustData = !!incustData;
	const {
		data: settings,
		error,
		isFetching: isSettingsFetching,
	} = useQuery<IIncustSettings | null, AxiosError<any>>({
		queryKey: ["incust-settings", brandId, isIncustData, lang, storeId, invoiceTemplateId],
		placeholderData: keepPreviousData,
		queryFn: async () => {
			if (!isIncustData || !brandId || !lang) return null;

			const response = await api.shop.loyalty.getIncustSettings(brandId, storeId, invoiceTemplateId);

			return response.data;
		},
	});

	const incustCurrency = useMemo(() => {return settings?.currency || null}, [settings]);

	const { customerData, isCustomerFetching, isCustomerPending } =
		useUserIncustCustomerData(storeId, invoiceTemplateId);

	const fetchLoyaltySettings = useCallback(async () => {
		if (!brandId || !isIncustData) return null;
		return await api.shop.loyalty.getLoyaltySettings().then(response => response.data);
	}, [brandId, isIncustData]);

	const referralQuery = useQuery<ILoyaltyInfo | null, any>({
		queryKey: ["brand-loyalty-settings", brandId, lang],
		queryFn: fetchLoyaltySettings,
		enabled: !!brandId,
		initialData: null,
	});

	const loyaltyReferralSettings = useMemo(() => {
		const { data: loyaltySettings } = referralQuery;
		return loyaltySettings;
	}, [referralQuery]);

	const referralInfoLoading = useMemo(() => {
		return referralQuery.isFetching;
	}, [referralQuery.isFetching]);

	const terminalUnauth = useMemo(() => {
		if (error?.response?.status === 401) {
			return error.response.data?.detail || "Terminal APIKEY error";
		}
		return null;
	}, [error]);

	const computedCheckItemsAmount = useMemo(() => {
		if (!checkItems) return null;
		return checkItems.reduce((acc, item) => {
			return acc + (item.amount || 0);
		}, 0);
	}, [checkItems]);

	const amountForCheck = computedCheckItemsAmount || checkAmount || 0;

	const computedMaxBonusesForCheck = useMemo(() => {
		if (!checkItems?.length && !checkAmount) return 0;

		let amount = computedCheckItemsAmount || checkAmount || 0;

		if (checkDiscountAmount) {
			amount -= checkDiscountAmount;
		}

		const maxPercent = settings?.loyalty_settings?.bonus_payment_limit ?? 0;
		const maxBonusesAmount = amount * (maxPercent / 100);

		const bonusesItem = getBonusesItemByCurrency(customerData?.user_card, currency);
		if (!bonusesItem) return 0;

		const availableBonuses = bonusesItem.bonuses_amount;

		return Math.min(availableBonuses, maxBonusesAmount);
	}, [
		settings?.loyalty_settings?.bonus_payment_limit,
		checkItems?.length,
		checkAmount,
		computedCheckItemsAmount,
		checkDiscountAmount,
		customerData?.user_card,
		currency,
	]);

	const showCouponsError = useCallback(
		(invalidCoupons: IIncustCoupon[]) => {
			if (!invalidCoupons.length) {
				return {
					errorText: null,
					couponsErrors: null,
				};
			}

			let errorTexts: string[] = [];
			const couponsErrors: { [key: string]: string } = {};

			invalidCoupons.forEach(coupon => {
				let couponText = "";
				if (coupon.title && coupon.code) {
					couponText += `${coupon.code} - ${coupon.title}</br>`;
				}

				if (couponText !== "") {
					let couponErrorText =
						`<div class="mb-2">${localisation.profile.loyaltyCouponsErrHeader}:</div>` +
						couponText;
					if (coupon.error) {
						couponErrorText += `<div class="mb-2">${coupon.error}</div>`;
					}
					errorTexts.push(couponErrorText);
					if (coupon.id) {
						couponsErrors[coupon.id] = couponErrorText;
					}
				}
			});

			const errorText = errorTexts.join("\n\n");
			showError(errorText);
			return {
				errorText,
				couponsErrors,
			};
		},
		[localisation.profile.loyaltyCouponsErrHeader, showError]
	);

	useEffect(() => {
		if (bonusesRedeem > computedMaxBonusesForCheck) {
			setBonusesRedeem(computedMaxBonusesForCheck);
			setBonusesRedeemInput(computedMaxBonusesForCheck.toString());
		}
	}, [bonusesRedeem, computedMaxBonusesForCheck]);

	const calculateBonuses = useDebounced(
		() => {
			if (processCheckLoading) return;

			let bonuses: number;

			const parsedBonusesRedeem = parseFloat(bonusesRedeemInput);
			if (bonusesRedeemInput === "") {
				bonuses = 0;
			} else if (isNaN(parsedBonusesRedeem)) {
				bonuses = bonusesRedeem;
			} else {
				bonuses = parsedBonusesRedeem;
			}

			if (bonuses <= 0 || !customerData?.token) {
				bonuses = 0;
				sessionStorage.removeItem(IncustLoyaltyBonusesStorageKey);
			} else {
				if (bonuses > computedMaxBonusesForCheck) {
					// rounding to fixed, 2
					bonuses = parseFloat(computedMaxBonusesForCheck.toFixed(2));
				}
				sessionStorage.setItem(IncustLoyaltyBonusesStorageKey, bonuses.toString());
			}

			setBonusesRedeem(bonuses);
			setBonusesRedeemInput(bonuses.toString());
		},
		[
			bonusesRedeem,
			bonusesRedeemInput,
			computedMaxBonusesForCheck,
			customerData?.token,
			processCheckLoading,
		],
		500
	);

	useEffect(() => {
		calculateBonuses();
	}, [calculateBonuses]);

	useEffect(() => {
		if (!usedCoupons?.length || !customerData?.token) {
			setUsedCoupons(null);
			sessionStorage.removeItem(IncustLoyaltyCouponsStorageKey);
		} else {
			sessionStorage.setItem(IncustLoyaltyCouponsStorageKey, JSON.stringify(usedCoupons));
		}
	}, [usedCoupons, customerData?.token]);

	const createAndSetProcesCheckPayload = useDebounced(
		() => {
			let isProcessCheckAvailableOnPage = processCheckAvailablePages.some(page =>
				location.pathname.includes(page)
			);
			if (forceProcessCheckAvailableOnPage) isProcessCheckAvailableOnPage = true;

			if (
				!enabled ||
				!brandId ||
				!incustData ||
				!isProcessCheckAvailableOnPage ||
				settings?.currency.toLowerCase() !== currency?.toLowerCase() ||
				(!customerData?.token &&
					incustData?.loyalty_applicable_type === "for_participants") ||
				(!checkItems?.length && !checkAmount)
			) {
				return setProcessCheckPayload(null);
			}

			const calculatedCheckItems = checkItems?.length
				? checkItems
				: [
						{
							code: "entered_amount",
							title: localisation.payment.enteredAmountItemName,
							category: "entered_amount",
							price: amountForCheck,
							amount: amountForCheck,
							quantity: 1,
						},
					];

			const bonuses = defaultRedeemBonusesValue ? defaultRedeemBonusesValue : bonusesRedeem;
			const check = makeIncustCheck(
				calculatedCheckItems,
				amountForCheck,
				currency,
				bonuses,
				usedCoupons,
				customerData?.external_id,
				customerData?.token || ""
			);
			if (!check) {
				console.log("check not made");
				return setProcessCheckPayload(null);
			}

			setProcessCheckPayload({
				...check,
				with_user: !!customerData?.token,
				payment_id: check.payment_id.toUpperCase(),
				rules_type: rulesType,
				store_id: storeId,
				invoice_template_id: invoiceTemplateId,
			});
		},
		[
			forceProcessCheckAvailableOnPage,
			enabled,
			brandId,
			incustData,
			settings?.currency,
			currency,
			customerData?.token,
			customerData?.external_id,
			checkItems,
			checkAmount,
			localisation.payment.enteredAmountItemName,
			amountForCheck,
			defaultRedeemBonusesValue,
			bonusesRedeem,
			usedCoupons,
			rulesType,
			storeId,
			location.pathname,
			invoiceTemplateId,
		],
		500
	);

	useEffect(() => {
		createAndSetProcesCheckPayload();
	}, [createAndSetProcesCheckPayload]);

	const processCheck = useCallback(async () => {
		console.log("setting wait for process check false");

		if (!processCheckPayload || !brandId) {
			console.log("no payload process check");
			return null;
		}

		console.log("process check", processCheckPayload);

		try {
			// making check for request
			const response = await api.shop.loyalty.processCheck(brandId, processCheckPayload);
			const processedCheck = response.data;

			let errorText: string | null = null;
			let couponsErrors: { [key: number]: string } | null = null;
			const applicableCoupons: IIncustCoupon[] = [];
			const couponsWithError: IIncustCoupon[] = [];

			// adding only applicable coupons to usedCoupons
			if (processedCheck.redeemed_coupons?.length) {
				processedCheck.redeemed_coupons?.forEach(coupon => {
					if (coupon.applicable) {
						if (coupon.id) {
							applicableCoupons.push(coupon);
						}
					} else {
						couponsWithError.push(coupon);
					}
				});
				const couponsErrorsData = showCouponsError(couponsWithError);
				errorText = couponsErrorsData.errorText;
				couponsErrors = couponsErrorsData.couponsErrors;
				setUsedCoupons(applicableCoupons);
			}

			const isError = !!errorText;

			const onCheckProcessedCallback = onCheckProcessedCallbackRef.current;
			if (onCheckProcessedCallback) {
				onCheckProcessedCallback({
					processedCheck,
					isError,
					errorText,
					applicableCoupons,
					couponsWithError,
					couponsErrors,
				});
			}

			return response.data;
		} catch (ex) {
			console.log(ex);
			showError(ex);
			return null;
		}
	}, [brandId, processCheckPayload, showCouponsError, showError]);

	const processCheckQuery = useQuery({
		queryKey: ["try-process-check", brandId, processCheckPayload, showCouponsError, showError],
		queryFn: processCheck,
		placeholderData: keepPreviousData,
	});

	const processedCheck = processCheckQuery.data;

	useEffect(() => {
		setCheckDiscountAmount(processedCheck?.discount_amount || 0);
	}, [processedCheck?.discount_amount]);

	useEffect(() => {
		setProcessCheckLoading(processCheckQuery.isLoading || processCheckQuery.isFetching);
	}, [processCheckQuery.isLoading, processCheckQuery.isFetching]);

	const clearLoyaltyData = useCallback(() => {
		setBonusesRedeem(0);
		setUsedCoupons(null);
	}, []);

	const computedIsInternalLoading = useMemo(() => {
		if (brandInfo && brandInfo.loyalty_info && !brandInfo.loyalty_info.loyalty_enabled)
			return false;
		return processCheckQuery.isPending || isSettingsFetching || isCustomerFetching;
	}, [brandInfo, isCustomerFetching, isSettingsFetching, processCheckQuery.isPending]);

	const isAddBonusesOnlyError = useMemo(() => {
		return (
			(!!bonusesRedeem || !!defaultRedeemBonusesValue) &&
			customerData?.user_card?.customer.access_type === "add-bonuses-only"
		);
	}, [bonusesRedeem, customerData?.user_card?.customer.access_type, defaultRedeemBonusesValue]);

	const reFetchProcessCheck = useCallback(async () => {
		if (settings) {
			await processCheckQuery.refetch();
		}
	}, [processCheckQuery, settings]);

	const computedCurrencyError = useMemo(() => {
		if (settings && currency) {
			if (settings?.currency.toLowerCase() !== currency?.toLowerCase()) {
				return f(localisation.menu.loyaltyCurrencyError, {
					terminal_currency: settings.currency,
					store_currency: currency,
				});
			}
		}
		return null;
	}, [currency, settings, localisation.menu.loyaltyCurrencyError]);

	return useMemo(
		(): IUseIncustServiceType => ({
			isAddBonusesOnlyError,
			settings,
			processedCheck,
			bonusesRedeem,
			bonusesRedeemInput,
			setBonusesRedeemInput,
			usedCoupons,
			setUsedCoupons,
			computedMaxBonusesForCheck,
			internalLoading: computedIsInternalLoading,
			processCheckLoading,
			terminalUnauth,
			clearLoyaltyData,
			onCheckProcessedCallbackRef,
			setOnCheckProcessedCallback,
			loyaltyReferralSettings,
			referralInfoLoading,
			isCustomerFetching: isCustomerFetching,
			reFetchProcessCheck,
			computedCurrencyError,
			isCustomerPending: isCustomerPending,
			incustCurrency: incustCurrency,
			invoiceTemplateId: invoiceTemplateId,
		}),
		[
			isAddBonusesOnlyError,
			settings,
			processedCheck,
			bonusesRedeem,
			bonusesRedeemInput,
			usedCoupons,
			computedMaxBonusesForCheck,
			computedIsInternalLoading,
			processCheckLoading,
			terminalUnauth,
			clearLoyaltyData,
			setOnCheckProcessedCallback,
			loyaltyReferralSettings,
			referralInfoLoading,
			isCustomerFetching,
			reFetchProcessCheck,
			computedCurrencyError,
			isCustomerPending,
			incustCurrency,
			invoiceTemplateId,
		]
	);
}

export function useIncustCheckItemsFromCart(
	cart: Cart | null,
	calculateProductTotalPrice: any,
	getCategoryById: any
) {
	const { brandInfo } = useShopContext();
	const products = cart?.cart_products;

	return useMemo((): IIncustCheckItem[] => {
		if (!products?.length || brandInfo.loyalty_info?.loyalty_type !== "incust") {
			return [];
		}

		return products.map(product => {
			let category = "uncategorized";
			if (product.product.categories && product.product.categories.length > 0) {
				const categoryId = product.product.categories[0];
				if (categoryId) {
					const categoryObj = getCategoryById(categoryId);
					if (categoryObj) {
						category = categoryObj.external_id || categoryObj.name;
					}
				}
			}

			category = category.toString().toLowerCase();
			return {
				title: product.product.name,
				code: product.product.product_id.toString(),
				price: calculateProductTotalPrice(product) / product.quantity,
				quantity: product.quantity,
				amount: calculateProductTotalPrice(product),
				category: category,
			};
		});
	}, [
		brandInfo.loyalty_info?.loyalty_type,
		calculateProductTotalPrice,
		getCategoryById,
		products,
	]);
}

export function useIncustCheckItemsFromInvoiceTemplateItems(
	items: InvoiceTemplateItem[] | null | undefined,
	count: number | null | undefined
) {
	return useMemo((): IIncustCheckItem[] => {
		const computedCount = count || 1;

		return (
			items?.map(item => ({
				title: item.name,
				code: item.item_code || item.name,
				price: item.price,
				quantity: item.quantity * computedCount,
				amount: item.price * item.quantity * computedCount,
				category: item.category,
			})) || []
		);
	}, [count, items]);
}

export function useIncustCheckItemsFromProducts(
	products: Product[] | null | undefined,
	count: number | null | undefined,
	getCategoryById: any,
	floatingSum: number
) {
	return useMemo(() => {
		if (!products?.length) {
			return [];
		}

		const computedCount = count || 1;

		return products.map(product => {
			let category = "uncategorized";
			if (product.categories && product.categories.length > 0) {
				const categoryId = product.categories[0];
				if (categoryId) {
					const categoryObj = getCategoryById(categoryId);
					if (categoryObj) {
						category = categoryObj.external_id || categoryObj.name;
					}
				}
			}
			return {
				title: product.name,
				code: product.product_id,
				price: product.floating_sum_settings?.is_enabled ? floatingSum : product.price,
				quantity: computedCount,
				amount: product.floating_sum_settings?.is_enabled
					? floatingSum
					: product.price * computedCount,
				category: category,
			};
		});
	}, [products, count, floatingSum, getCategoryById]);
}
