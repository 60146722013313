import { IIncustCoupon, IncustTransaction } from "../../types";
import useAppContext from "../../../../useAppContext";
import { Box, Typography } from "@mui/material";
import f from "../../../../helpers/formatText";
import formatCurrency from "../../../../helpers/formatCurrency";
import { Interweave } from "interweave";
import { AnimatePresence, motion } from "framer-motion";

const AnimatePresenceFixedType = AnimatePresence as React.ElementType;


export interface ICouponResult {
	addedCoupon: IIncustCoupon | null;
	redeemedCouponCheck: IncustTransaction | null;
	animate?: boolean;
	isInsideCoupon?: boolean;
}

export function CouponResult({
	addedCoupon,
	redeemedCouponCheck,
	animate,
	isInsideCoupon,
}: ICouponResult) {
	const {
		authService: { user },
		localisation: { profile },
		brandInfo,
	} = useAppContext();

	if (addedCoupon?.status !== "redeemed" || !redeemedCouponCheck) return null;

	return (
		<AnimatePresenceFixedType mode="wait">
			<Box
				component={motion.div}
				initial={false}
				animate={
					animate
						? {
								opacity: 0,
								height: 0,
								transition: { duration: 0.3 },
							}
						: {
								opacity: 1,
								height: "fit-content",
								transition: { duration: 0.3 },
							}
				}
				sx={{ textAlign: { xs: "center", sm: "end" } }}
				color={{ xs: "primary.contrastText", sm: "primary.main" }}
				bgcolor={{ xs: "primary.main", sm: "primary.contrastText" }}
				width={"fit-content"}
				borderRadius="5px"
				mx={{ ...(!isInsideCoupon && { xs: "auto", sm: "unset" }) }}
				ml={{ ...(!isInsideCoupon && { sm: "auto" }) }}
				mr={{ ...(isInsideCoupon && { sm: "auto" }) }}
				my={{ xs: 2, sm: "unset" }}
				px={2}
				py={1}
			>
				{redeemedCouponCheck?.bonuses_added > 0 && (
					<Typography variant={"body1"}>
						{profile.loyaltyBonusesAwardedAfter}:{" "}
						<Box component={"span"} fontWeight={"bold"}>
							{redeemedCouponCheck.bonuses_added}
						</Box>
					</Typography>
				)}

				{user &&
					redeemedCouponCheck.check?.special_account &&
					redeemedCouponCheck.summary_amount && (
						<Interweave
							content={f(profile.loyaltySpAccountTopped, {
								sp_account_name: redeemedCouponCheck.check.special_account.title,
								amount: redeemedCouponCheck.check.special_account.currency
									? formatCurrency(
											redeemedCouponCheck.summary_amount,
											brandInfo?.default_lang || user?.lang || "en",
											redeemedCouponCheck.check.special_account.currency ??
												"UAH"
										)
									: redeemedCouponCheck.summary_amount,
							})}
						/>
					)}
			</Box>
		</AnimatePresenceFixedType>
	);
}
