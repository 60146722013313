import { AxiosInstance } from "axios";
import { ClientWebPageBase, ClientWebPageItem } from "../../components/ClientWebPages/types";

export default function ClientWebPagesApiModule(instance: AxiosInstance) {
	const getPath = (path: string) => "/" + path;

	return {
		getClientWebPages(profileId: number) {
			return instance.get<ClientWebPageItem[]>(getPath(`client_web_pages/${profileId}`));
		},
		getClientWebPage(profileId: number, slug?: string) {
			return instance.get<ClientWebPageBase>(
				getPath(`client_web_pages/${profileId}/${slug}/`)
			);
		},
	};
}
