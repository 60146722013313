import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { IIncustBonusItem, IIncustCoupon } from "../../types";
import useLocalisation from "../../../../hooks/localisation/useLocalisation";
import IncustAuth from "../IncustAuth/IncustAuth";
import DiscountAmount from "./DiscountAmount";
import Bonuses from "./Bonuses";
import CouponsLink from "./CouponsLink";
import { Alert, AlertTitle, Box, Button, CircularProgress, Collapse, IconButton, Link } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import useAppContext from "../../../../useAppContext";
import { IUseIncustServiceType } from "../../../../features/services/useIncustService/types";
import useCurrency from "../../../../services/useCurrencyService";
import ConnectMessangerModal from "./ConnectMessangerModal";
import MessangerIconAndName from "./MessangerIconAndName";
import {
	IncustLoyaltyBonusesStorageKey,
	IncustLoyaltyCouponsStorageKey,
} from "../../../../features/services/useIncustService";
import useSearchParamsFixed from "../../../../features/hooks/useSearchParamsFixed";
import CouponsModal from "./CouponsModal";
import api from "../../../../api";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeModal } from "../../../../helpers/ThemeComponents";
import SendIcon from "@mui/icons-material/Send";
import extractDigits from "../../../../helpers/extractDigits";
import InputWithEndButton from "../../../../features/InputWithEndButton";
import { QrScanner } from "@yudiel/react-qr-scanner";
import Interweave from "../../../../features/Interweave";
import CouponModal from "../Coupon/CouponModal";
import { useSelectedStoreOptional } from "../../../SelectedStore/context";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";
import { getBonusesItemByCurrency } from "../../../../features/services/useIncustService/functions";

interface IncustLoyaltyProps {
	needFullPageLoading?: boolean;
	disableLogin?: boolean;
	incustService: IUseIncustServiceType;
	hidden?: boolean;
	withoutPreSum?: boolean;
	_currency?: string | null | undefined;
}

export default function IncustLoyalty({
	needFullPageLoading,
	disableLogin,
	incustService,
	hidden,
	withoutPreSum = false,
	_currency,
}: IncustLoyaltyProps) {
	const [searchParams, setSearchParams] = useSearchParamsFixed();
	const [addCouponValue, setAddCouponValue] = useState("");

	const {
		brandInfo,
		bot,
		authService: { user, loadUser },
		loadingManager: { setIsLoading },
		toastService,
	} = useAppContext();

	const store = useSelectedStoreOptional();

	const currency = useCurrency(_currency);

	const localisation = useLocalisation();

	const { customerData, isCustomerPending } = useUserIncustCustomerData(null, incustService.invoiceTemplateId);

	const [editBonuses, setEditBonuses] = useState<boolean>(false);
	const [isCouponsOpen, setIsCouponsOpen] = useState<boolean>(false);

	const [showConnectMessangerModal, setShowConnectMessangerModal] = useState(false);
	const [showCouponMessage, setShowCouponModal] = useState(false);
	const [addedCoupon, setAddedCoupon] = useState<IIncustCoupon | null>(null);
	const [startScan, setStartScan] = useState(false);
	const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
	const [couponError, setCouponError] = useState<string | null>(null);

	const [showCouponInput, setShowCouponInput] = useState(false);
	const couponInputRef = useRef<HTMLInputElement>(null);

	const onNewScanResult = (decodedText: string) => {
		setAddCouponValue(decodedText);
		setStartScan(false);
		setCouponError(null);
	};

	const { setBonusesRedeemInput, setUsedCoupons } = incustService;

	const addCouponButtonHandler = useCallback(async () => {
		if (!brandInfo?.id || !addCouponValue) {
			return;
		}
		setCouponError(null);
		setAddedCoupon(null);
		setIsLoadingBtn(true);

		try {
			const { data: couponExt } = await api.shop.loyalty.processCoupon(
				addCouponValue,
				store?.id
			);

			if (!couponExt.coupon) {
				toastService.showToast({
					severity: "error",
					message: localisation.profile.loyaltyAddCouponFailedText,
				});
				return;
			}

			if (couponExt && couponExt.coupon && couponExt.coupon.id) {
				if (couponExt.coupon.batch?.type === "check-modifier") {
					toastService.showToast({
						severity: "info",
						message: couponExt.message || "",
					});
				}
				loadUser().then();
			}

			setAddedCoupon(couponExt.coupon);

			let prevUsedCoupons = [...(incustService.usedCoupons || [])];
			if (couponExt.coupon.type === "check-modifier") {
				if (!prevUsedCoupons.find(x => x.id === couponExt.coupon.id)) {
					prevUsedCoupons.push(couponExt.coupon);
					setUsedCoupons(prevUsedCoupons);
				}
			} else {
				setShowCouponModal(true);
			}
			setAddCouponValue("");
		} catch (ex: any) {
			setCouponError(ex.response?.data?.detail || ex.message || "An error occurred.");
		} finally {
			console.log(
				"coupons = ",
				incustService.usedCoupons?.map(cp => `${cp.code} ${cp.title}`)
			);
			setIsLoadingBtn(false);
		}
	}, [
		store?.id,
		addCouponValue,
		brandInfo?.id,
		loadUser,
		setUsedCoupons,
		toastService,
		incustService.usedCoupons,
		localisation.profile.loyaltyAddCouponFailedText,
		setIsLoadingBtn,
	]);

	useEffect(() => {
		console.log("mount IncustLoyalty");

		return () => {
			console.log("unmount IncustLoyalty");
		};
	}, []);

	useEffect(() => {
		if (!addCouponValue.length) setCouponError(null);
	}, [addCouponValue]);

	useEffect(() => {
		if (
			(needFullPageLoading && !incustService.internalLoading && !isCustomerPending) ||
			!customerData?.token
		) {
			setIsLoading("cart-loyalty", false);
		}
	}, [customerData?.token, incustService.internalLoading, needFullPageLoading, setIsLoading, isCustomerPending]);

	useEffect(() => {
		const bonuses = sessionStorage.getItem(IncustLoyaltyBonusesStorageKey);
		if (bonuses) {
			const bonusesParsed = parseFloat(bonuses);
			if (bonusesParsed) {
				setBonusesRedeemInput(bonusesParsed.toString());
			}
		}
		const coupons = JSON.parse(sessionStorage.getItem(IncustLoyaltyCouponsStorageKey) || "[]");
		if (Array.isArray(coupons) && !!coupons.length) {
			setUsedCoupons(coupons);
		}
	}, [setBonusesRedeemInput, setUsedCoupons]);

	const bonusesRedeemParam = searchParams.get("bonuses_redeem");
	useEffect(() => {
		if (bonusesRedeemParam) {
			const bonusesRedeemParsed = parseFloat(bonusesRedeemParam);
			if (bonusesRedeemParsed) {
				setBonusesRedeemInput(bonusesRedeemParsed.toString());
			}
		}
		setSearchParams(prev => {
			const params = new URLSearchParams(prev.toString());
			params.delete("bonuses_redeem");
			return params;
		});
	}, [bonusesRedeemParam, setSearchParams, setBonusesRedeemInput]);

	const computedError = useMemo(() => {
		if (incustService.terminalUnauth) {
			return incustService.terminalUnauth;
		}
		if (
			!brandInfo?.loyalty_info?.loyalty_enabled ||
			brandInfo.loyalty_info.loyalty_type !== "incust" ||
			(user?.id && !customerData?.token && !isCustomerPending)
		) {
			return localisation.global.loyaltyNotConnectedText;
		}
	}, [
		incustService.terminalUnauth,
		user?.id,
		customerData?.token,
		brandInfo?.loyalty_info?.loyalty_enabled,
		brandInfo?.loyalty_info?.loyalty_type,
		localisation.global.loyaltyNotConnectedText,
		isCustomerPending,
	]);

	const handleCouponRemove = useCallback(
		(couponId: string) => {
			let used_coupons = incustService.usedCoupons;
			if (used_coupons) setUsedCoupons(used_coupons.filter(pc => pc.id !== couponId));
		},
		[incustService.usedCoupons, setUsedCoupons]
	);

	const isAnyBonusesAmountAvailable = useMemo(() => {
		if (
			customerData?.user_card &&
			customerData?.user_card.bonuses &&
			customerData?.user_card.bonuses.length > 0 &&
			currency
		) {
			const bonusesItem: IIncustBonusItem | undefined | null = getBonusesItemByCurrency(
				customerData?.user_card,
				currency
			);
			if (bonusesItem) return !!bonusesItem.bonuses_amount;
		}
		return false;
	}, [currency, customerData?.user_card]);

	useEffect(() => {
		if (!incustService.processedCheck && incustService.usedCoupons) {
			setUsedCoupons(null);
			if (isCouponsOpen) {
				setIsCouponsOpen(false);
			}
		}
	}, [incustService.processedCheck, isCouponsOpen, incustService.usedCoupons, setUsedCoupons]);

	if (hidden || (!incustService.settings && !incustService.terminalUnauth)) return null;

	if (computedError) {
		return (
			<Alert severity={"error"} variant={"outlined"} className={"small p-3"}>
				<Interweave content={computedError} />
			</Alert>
		);
	}

	if (!customerData?.token && brandInfo?.incust_data?.loyalty_applicable_type !== "for_all") {
		if (disableLogin) return null;

		return (
			<IncustAuth
				description={incustService.settings?.loyalty.public_description}
				localizations={{
					loyaltyInfo: localisation.orders.loyaltyInfo,
					incustLogin: localisation.profile.incustLogin,
				}}
			/>
		);
	}

	if (!withoutPreSum && incustService.internalLoading) {
		if (needFullPageLoading) return null;
		return (
			<Box textAlign={"center"} mt={1} mb={2}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<>
			{!!incustService.processedCheck && (
				<div className={"theme-text text-start"}>
					{!!incustService.processedCheck.discount_amount && (
						<DiscountAmount
							discount={incustService.processedCheck.discount_amount}
							currency={incustService.settings?.currency}
						/>
					)}
					{currency === incustService.settings?.currency && (
						<>
							{!!(
								customerData?.user_card &&
								incustService.settings?.loyalty_settings?.bonus_payment_limit
							) && (
								<>
									{!brandInfo?.incust_data?.prohibit_redeeming_bonuses &&
										isAnyBonusesAmountAvailable && (
											<Bonuses
												editBonuses={editBonuses}
												setEditBonuses={setEditBonuses}
												bonusesRedeem={incustService.bonusesRedeem}
												bonusesRedeemInput={
													incustService.bonusesRedeemInput
												}
												setBonusesRedeemInput={
													incustService.setBonusesRedeemInput
												}
												computedMaxBonusesForCheck={
													incustService.computedMaxBonusesForCheck
												}
												currency={incustService.incustCurrency}
												invoice_template_id={incustService.invoiceTemplateId}
											/>
										)}
									{!brandInfo?.incust_data?.prohibit_redeeming_coupons && (
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<CouponsLink
												setIsCouponsOpen={setIsCouponsOpen}
												usedCoupons={incustService.usedCoupons}
												invoice_template_id={incustService.invoiceTemplateId}
											/>
											<Link
												component={"button"}
												type={"button"}
												onClick={() =>
													setShowCouponInput(prev => {
														if (!prev) {
															setTimeout(() => {
																couponInputRef.current?.focus();
															}, 250);
														}
														return !prev;
													})
												}
												sx={{
													ml: 2,
												}}
											>
												{showCouponInput
													? localisation.profile
															.loyaltyHideCouponInputButton
													: localisation.profile
															.loyaltyEnterCouponCodeText}
											</Link>
										</Box>
									)}
								</>
							)}
						</>
					)}
				</div>
			)}

			<div>
				{!brandInfo?.incust_data?.prohibit_redeeming_coupons && (
					<Collapse in={showCouponInput}>
						<InputWithEndButton
							textFieldProps={{
								inputRef: couponInputRef,
								sx: { my: 2 },
								label: localisation.profile.loyaltyEnterCouponCodeText,
								value: addCouponValue,
								onChange: e => {
									setAddCouponValue(extractDigits(e.target.value || ""));
								},
								InputProps: {
									endAdornment: (
										<IconButton onClick={() => setStartScan(true)}>
											<QrCodeScannerIcon fontSize={"small"} />
										</IconButton>
									),
								},
							}}
							inputEndAdornmentProps={{ sx: { gap: 1 } }}
							isLoading={isLoadingBtn}
							onEndButtonClick={addCouponButtonHandler}
							endButtonNode={<SendIcon />}
							error={!!couponError}
							helperText={!!couponError ? couponError : ""}
						/>
					</Collapse>
				)}

				{incustService.usedCoupons &&
					incustService.usedCoupons.map((coupon: IIncustCoupon) => (
						<Box key={coupon.id} display="flex" alignItems="center">
							<CheckIcon style={{ color: "green" }} />
							<span
								style={{
									flexGrow: 1,
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{coupon.code} - {coupon.title}
							</span>
							<IconButton
								onClick={() => handleCouponRemove(coupon.id || "")}
								size={"small"}
							>
								<CloseIcon fontSize={"small"} style={{ color: "red" }} />
							</IconButton>
						</Box>
					))}
			</div>

			{incustService.processCheckLoading && (
				<div className={"mb-2"}>
					{localisation.global.loadingText}
					<CircularProgress
						className={"ms-2"}
						style={{ width: "14px", height: "14px" }}
					/>
				</div>
			)}

			{incustService.isAddBonusesOnlyError && bot && (
				<Alert severity={"error"} sx={{ mb: 2 }}>
					<AlertTitle>{localisation.global.incustAddBonusesOnlyErrorTitle}</AlertTitle>

					{localisation.global.incustAddBonusesOnlyErrorDescription}
					<MessangerIconAndName messanger={bot.bot_type} />
					<Box
						gap={1}
						mt={1}
						display={"flex"}
						alignItems={"stretch"}
						justifyContent={"end"}
					>
						<Button
							size={"small"}
							color={"inherit"}
							onClick={() => incustService.setBonusesRedeemInput("")}
						>
							{localisation.global.loyaltyCancelBonusesButton}
						</Button>
						<Button
							size={"small"}
							color={"inherit"}
							onClick={() => setShowConnectMessangerModal(true)}
						>
							{localisation.global.loyaltyConnectMessangerButton}
						</Button>
						<ConnectMessangerModal
							open={showConnectMessangerModal}
							setOpen={setShowConnectMessangerModal}
							incustService={incustService}
						/>
					</Box>
				</Alert>
			)}

			<ThemeModal
				open={startScan}
				setOpen={setStartScan}
				title={localisation.profile.loyaltyScanCodeHeader}
				contentProps={{
					sx: { p: 0 },
				}}
				sx={{
					"& .MuiDialog-container .MuiDialog-paper": {
						margin: "8px",
					},
				}}
			>
				{startScan && (
					<QrScanner
						onDecode={result => onNewScanResult(result)}
						onError={error => console.log(error?.message)}
						scanDelay={1000}
					/>
				)}
			</ThemeModal>

			<CouponsModal
				setIsCouponsOpen={setIsCouponsOpen}
				isCouponsOpen={isCouponsOpen}
				isLoading={incustService.internalLoading}
				incustSettings={incustService.settings}
				checkedCoupons={incustService.usedCoupons || []}
				incustService={incustService}
			/>
			{addedCoupon?.id && (
				<CouponModal
					show={showCouponMessage}
					setShow={setShowCouponModal}
					couponId={addedCoupon.id}
					invoice_template_id={incustService.invoiceTemplateId}
				/>
			)}
		</>
	);
}
