import IncustPayAccount from "../PaymentButtons/IncustPay/IncustPayAccount";
import IncustPayCardModal, { IncustPayCardTitle } from "../PaymentButtons/IncustPay/modals/IncustPayCardModal";
import IncustPay, { IncustContentData } from "../PaymentButtons/IncustPay/IncustPay";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import f from "../../../helpers/formatText";
import { IUsePaymentsService } from "../hooks/useNewPaymentsService";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import {
	IncustPayCardInfo,
	IncustPayConfiguration,
	IncustPayPaymentData,
	PaymentPayload,
} from "../../../api/shop/payments/types";
import useIncustPayDataService from "../PaymentButtons/IncustPay/useIncustPayDataService";
import useUnifiedSpecialAccountData, {
	UnifiedSpecialAccountData,
} from "../PaymentButtons/IncustPay/useUnifiedSpecialAccountData";
import { IncustPayAccountData } from "../PaymentButtons/IncustPay/types";
import { SetState } from "../../../types";
import { Box, Typography } from "@mui/material";

interface IIncustPaymentItemsProps {
	type: "order" | "invoice";
	selectedStoreId?: number | null;
	paymentService: IUsePaymentsService;
	incustPayData?: IncustPayPaymentData | null;
	totalAmount: number;
	currency: string;
	incustFormData: IncustContentData | null;
	setInternalIncustFormData: SetState<IncustContentData | null>;
	setIncustFormData: (data: IncustContentData | null) => void;
	topupAccountId?: string;
	payButtonMaxHeight: number | null;
	setPayButtonMaxHeight: (value: number) => void;
}

export default function IncustPaymentItems(props: IIncustPaymentItemsProps) {
	const getIncustPaymentIcon = useCallback(
		(settingsId: number) => {
			const method = props.paymentService.methods.find(x => x.settings_id === settingsId);
			if (method) {
				return method.logo;
			}
			return "";
		},
		[props.paymentService.methods]
	);

	const getIncustMethod = useCallback(
		(settingsId: number) => {
			return props.paymentService.methods.find(x => x.settings_id === settingsId);
		},
		[props.paymentService.methods]
	);

	const setInternalIncustFormData = props.setInternalIncustFormData;
	useEffect(() => {
		if (
			props.paymentService.selectedProvider &&
			props.paymentService.selectedProvider.provider !== "incust_pay" &&
			props.incustFormData
		) {
			setInternalIncustFormData(null);
		}
	}, [props.incustFormData, props.paymentService.selectedProvider, setInternalIncustFormData]);

	const amountToPay = useMemo(() => {
		return props.paymentService.sumsToPay.sumToPay + props.paymentService.feeValue;
	}, [props.paymentService.feeValue, props.paymentService.sumsToPay.sumToPay]);

	if (!props.incustPayData || !props.paymentService.paymentData) return null;

	return (
		<>
			<IncustPay
				incustPay={props.incustPayData}
				amountToPay={amountToPay}
				type={props.type}
				selectedStoreId={props.selectedStoreId}
				toShow={true}
				paymentData={props.paymentService.paymentData}
				noModals={true}
				externalContentData={props.incustFormData}
				setExternalContentData={props.setIncustFormData}
				getIncustPaymentIcon={getIncustPaymentIcon}
				totalAmount={props.totalAmount}
				currency={props.currency}
				getIncustMethod={getIncustMethod}
				topupAccountId={props.topupAccountId}
				payButtonMaxHeight={props.payButtonMaxHeight}
				setPayButtonMaxHeight={props.setPayButtonMaxHeight}
				anyFee={!!props.paymentService.calculateFeeRange()}
				paymentService={props.paymentService}
			/>
		</>
	);
}

interface IncustPaymentFormProps {
	formData: IncustContentData;
	amountToPay: number;
	setContentData: (data: IncustContentData | null) => void;
	type: "order" | "invoice";
	paymentData?: PaymentPayload | null;
	selectedStoreId?: number | null;
	incustPayDataService: ReturnType<typeof useIncustPayDataService>;
	additionalFields?: ReactNode | null;
	payedCallback?: () => void;
	descAndFeeNode?: ReactNode | null;
	buttonsNode?: ReactNode | null;
	paymentService: IUsePaymentsService;
  	is_ewallet_payment?: boolean;
}

export function IncustPaymentForm(props: IncustPaymentFormProps) {
	const [cardInfo, setCardInfo] = useState<IncustPayCardInfo | null>(null);
	const [selectedAccount, setSelectedAccount] = useState<IncustPayAccountData | null>(null);

	const specialAccountData = useUnifiedSpecialAccountData(selectedAccount);

	if (!props.paymentData) return null;

	return (
		<>
			{props.formData.type !== "account" && !props.paymentService.paymentsCollapseOpen && (
				<IncustPaymentTitle
					specialAccountData={specialAccountData}
					orderToken={props.paymentData.order_token}
					invoiceToken={props.paymentData.invoice_token}
					cardInfo={cardInfo}
					setCardInfo={setCardInfo}
					formData={props.formData}
				/>
			)}

			{props.formData.type === "account" && (
				<IncustPayAccount
					accountData={props.formData.accountData}
					onClose={() => props.setContentData(null)}
					id_type={"user"}
					is_ewallet_payment={props.is_ewallet_payment}
					{...props}
				/>
			)}

			{props.formData.type === "card" && !props.paymentService.paymentsCollapseOpen && (
				<Box px={2}>
					<IncustPayCardModal
						incustPayDataService={props.incustPayDataService}
						paymentService={props.paymentService}
						amountToPay={props.amountToPay}
						modalData={props.formData?.cardData ?? null}
						setModalData={(
							data: IncustPayConfiguration | null,
							accountData?: IncustPayAccountData | null
						) => {
							if (accountData) {
								//@ts-ignore
								props.setContentData(prev => {
									return {
										...prev,
										accountData: accountData,
									};
								});
							} else if (data) {
								props.setContentData({
									type: "card",
									cardData: data,
								});
							} else {
								props.setContentData(null);
							}
						}}
						type={props.type}
						selectedStoreId={props.selectedStoreId}
						orderId={props.paymentData.order_id}
						orderToken={props.paymentData.order_token}
						invoiceToken={props.paymentData.invoice_token}
						noModal={true}
						externalCardInfo={cardInfo}
						externalSelectedAccount={selectedAccount}
						externalSetCardInfo={setCardInfo}
						setExternalSelectedAccount={setSelectedAccount}
						buttonsNode={props.buttonsNode}
						paymentData={props.paymentData}
						descAndFeeNode={props.descAndFeeNode}
					/>
				</Box>
			)}
		</>
	);
}

interface IIncustPaymentTitleProps {
	formData: IncustContentData;
	specialAccountData: UnifiedSpecialAccountData | null;
	orderToken?: string | null;
	invoiceToken?: string | null;
	cardInfo: IncustPayCardInfo | null;
	setCardInfo: SetState<IncustPayCardInfo | null>;
}

function IncustPaymentTitle(props: IIncustPaymentTitleProps) {
	const localisation = useLocalisation();

	return (
		<Box pl={4}>
			{props.formData.type === "account" ? (
				props.orderToken || props.invoiceToken ? (
					f(localisation.payment.incustPayModalTitle, {
						account_title: props.formData.accountData?.special?.title,
					})
				) : (
					<Typography
						variant={"h6"}
						fontWeight={"bold"}
						lineHeight={1.3}
						sx={{ pt: 2, pb: 4 }}
					>
						{props.formData.accountData?.special?.title}
					</Typography>
				)
			) : (
				<IncustPayCardTitle
					cardInfo={props.cardInfo}
					setCardInfo={props.setCardInfo}
					modalData={props.formData.cardData}
					specialAccountData={props.specialAccountData}
				/>
			)}
		</Box>
	);
}
