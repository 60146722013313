import { MouseEvent, useMemo, useRef, useState } from "react";
import { Product } from "../../../api/shop/basic/types";
import { CartProduct } from "../../../api/shop/cart/types";
import { useShopContext } from "../../context";
import ProductQty from "./ProductQty/ProductQty";
import { Box, Button, ButtonGroup, ButtonOwnProps, IconOwnProps, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddIcon from "@mui/icons-material/Add";
import useAppContext from "../../../useAppContext";
import { useSelectedStoreContext } from "../../SelectedStore/context";
import { TgLink } from "../../../helpers/ThemeComponents";
import { LoadingButton } from "@mui/lab";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useProductQuantity from "../../services/useCartService/hooks/useProductQuantity";
import DeleteProductsPopConfirmWrapper from "./DeleteProductsPopConfirm";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { PopupBody } from "../../../features/PopupBody";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import InfoIcon from "@mui/icons-material/Info";
import { IUseFloatingSumType } from "./hooks/useFloatingSum";
import { IUseProductGiftType } from "./hooks/useProductGift";
import Interweave from "../../../features/Interweave";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import changePage from "../../../helpers/changePage";
import { IUseProductTopup } from "./hooks/useProductTopup";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import useScreenService from "../../../services/useScreenService";
import { canAddProductWithAnotherAttributes } from "../../services/useNewProductsService/helpers";
import useSelectProduct from "../../services/useNewProductsService/useSelectProduct";
import useSelectedProductParams from "../../services/useNewProductsService/useSelectedProductParams";
import { useSetRecoilState } from "recoil";
import HideDetailedProductModal from "../../services/useNewProductsService/HideDetailedProductModal";

export type ProductCardButtonsProps = FavoritesButtonProps & {
	product: Product;
	cartProduct: CartProduct | null;
	isInCart: boolean;
	addToCartLoading: boolean;
	addProductToCart: () => any;
	removeProductFromCart: () => any;
	iconSize?: IconOwnProps["fontSize"];
	textSize?: string;
	buttonSize?: ButtonOwnProps["size"];
	productQty?: number;
	setProductQty?: (qty: number) => any;
	isCard?: boolean;
	isGridView?: boolean;
};

export default function ProductCardButtons(props: ProductCardButtonsProps) {
	const { isEMenu } = useShopContext();
	const { isMobile } = useScreenService();

	const [isQtyInputVisible, setIsQtyInputVisible] = useState(false);

	const textSize = props.textSize || "fs-6";

	return (
		<>
			<Box
				gap={2}
				display={"flex"}
				alignItems={"center"}
				flexWrap={"wrap"}
				onClick={e => {
					e.stopPropagation();
				}}
			>
				{(!isQtyInputVisible || !isMobile) && (
					<FavoritesButton
						isInFavorites={props.isInFavorites}
						addOrRemoveFavoritesProduct={props.addOrRemoveFavoritesProduct}
						favoritesIconFontSize={props.favoritesIconFontSize}
					/>
				)}

				{((!isEMenu && props.product.type !== "info") || props.product.type === "gift") && (
					<>
						{props.isInCart &&
							(canAddProductWithAnotherAttributes(props.product) ||
								props.product.floating_sum_settings?.is_enabled ||
								props.product.floating_qty_enabled) &&
							props.isGridView && <ProductControls product={props.product} />}
						{(!props.isCard || props.isInCart) &&
							(props.isCard &&
							(canAddProductWithAnotherAttributes(props.product) ||
								props.product.floating_sum_settings?.is_enabled ||
								props.product.floating_qty_enabled) ? (
								<></>
							) : (
								<ProductQty
									cartProduct={props.isInCart ? props.cartProduct : null}
									textSize={textSize}
									iconSize={props.iconSize}
									buttonSize={props.buttonSize}
									productQty={props.productQty}
									setProductQty={props.setProductQty}
									isSingleCartProduct={!props.isCard}
									isCard={props.isCard}
									setIsQtyInputVisible={setIsQtyInputVisible}
									isQtyInputVisible={isQtyInputVisible}
								/>
							))}
						{(!props.isInCart || !props.isCard) && (
							<ProductCardToCartButton
								iconSize={props.iconSize}
								textSize={textSize}
								buttonSize={props.buttonSize}
								isInCart={props.isInCart}
								addToCartLoading={props.addToCartLoading}
								product={props.product}
								addProductToCart={props.addProductToCart}
								cartProduct={props.cartProduct ? props.cartProduct : null}
								fullWidth={false}
								isCard={props.isCard}
							/>
						)}
					</>
				)}

				{props.product.type === "info" && <ProductInfoButton product={props.product} />}
			</Box>
		</>
	);
}

interface IProductCardToCartWithOtherAttributesButtonProps {
	product: Product;
}

export function ProductCardToCartWithOtherAttributesButton(
	props: IProductCardToCartWithOtherAttributesButtonProps
) {
	const { cartService } = useSelectedStoreContext();
	const selectProduct = useSelectProduct();
	const {
		localisation: { menu },
	} = useAppContext();

	const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
		e.stopPropagation();
		cartService.updateProductVariationsState(false, false);
		cartService.setAddingProductWithNewAttributesId(props.product.id);
		selectProduct(props.product);
	};

	return (
		<Box width={"100%"} textAlign={"start"}>
			<TgLink onClick={handleClick}>
				<Typography variant={"body2"}>{menu.addWithOtherAttributes}</Typography>
			</TgLink>
		</Box>
	);
}

interface IProductCardToCartButtonProps {
	iconSize?: IconOwnProps["fontSize"];
	textSize?: string;
	buttonSize?: ButtonOwnProps["size"];
	isInCart: boolean;
	addToCartLoading: boolean;
	product: Product;
	addProductToCart: () => any;
	fullWidth?: boolean;
	variant?: ButtonOwnProps["variant"];
	cartProduct?: CartProduct | null;
	price?: string | null;
	errorText?: string | null;
	floatingSum?: IUseFloatingSumType;
	gift?: IUseProductGiftType | null;
	topupService?: IUseProductTopup | null;
	isCard?: boolean;
}

export function ProductCardToCartButton(props: IProductCardToCartButtonProps) {
	const { cartService } = useSelectedStoreContext();
	const selectedProductParams = useSelectedProductParams();
	const btnRef = useRef<HTMLButtonElement | null>(null);
	const {
		localisation,
		authService: { user, setShowAuthorisationModal },
	} = useAppContext();

	const [showPopup, setShowPopup] = useState(false);

	const textSize = props.textSize || "fs-6";

	const handleAddToCartClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (
			props.gift?.computedNeedAuth ||
			(props.product.type === "topup" && !user && !props.isCard)
		)
			return setShowAuthorisationModal(true);
		if (props.gift) return props.gift.setShowGiftModal(true);
		if (
			props.product.type === "topup" &&
			!!props.topupService?.optionsService.selectedOption?.specialData
		)
			return props.topupService?.setShowConfirmModal(true);
		if (!props.addToCartLoading) {
			props.addProductToCart();
		}
	};

	const computedCartButtonText = useMemo(() => {
		if (props.product.type === "topup") {
			if (!user && !props.isCard) {
				return localisation.auth.loginRegisterButton;
			}
			return localisation.topup.topupButton;
		}
		if (props.product.type === "gift") {
			if (props.gift && props.gift.computedNeedAuth)
				return localisation.auth.loginRegisterButton;
			return localisation.menu.giftActionButton;
		}
		if (cartService.addingProductWithNewAttributesId) return localisation.menu.addOptionBtn;
		if (!props.isInCart) return localisation.global.toCart;
		return localisation.global.update;
	}, [
		props.isCard,
		props.product.type,
		props.isInCart,
		props.gift,
		user,
		cartService.addingProductWithNewAttributesId,
		localisation.menu.addOptionBtn,
		localisation.menu.giftActionButton,
		localisation.global.toCart,
		localisation.global.update,
		localisation.auth.loginRegisterButton,
		localisation.topup.topupButton,
	]);

	const computedCartButtonIcon = useMemo(() => {
		if (props.product.type === "topup") {
			return <AccountBalanceWalletOutlinedIcon fontSize={props.iconSize} />;
		}
		if (props.product.type === "gift") {
			if (props.gift?.computedNeedAuth) return undefined;
			return <CardGiftcardOutlinedIcon fontSize={props.iconSize} />;
		} else {
			if (!!props.errorText) return <InfoIcon fontSize={props.iconSize} />;
			if (props.isInCart) {
				return <SaveAsIcon fontSize={props.iconSize} />;
			}
		}
		return <AddIcon fontSize={props.iconSize} />;
	}, [
		props.errorText,
		props.gift?.computedNeedAuth,
		props.iconSize,
		props.isInCart,
		props.product.type,
	]);

	const computedDisabled = useMemo(() => {
		if (!props.product.is_available) return true;
		if (props.product.type === "topup" && !user) return false;
		if (
			props.product.type === "topup" &&
			!props.product.price &&
			!props.product.floating_sum_settings?.is_enabled &&
			!props.product.floating_sum_settings?.user_sum_enabled &&
			!props.product.floating_sum_settings?.options?.length
		) {
			return true;
		}
		if (
			props.product.type === "topup" &&
			!!selectedProductParams.product_id &&
			(props.topupService?.isLoading ||
				!props.topupService?.optionsService.selectedOption?.specialData)
		)
			return true;

		if (props.gift?.computedNeedAuth) return false;
		if (
			props.gift &&
			!props.gift.showGiftModal &&
			(props.gift.isGiftSomethingLoading ||
				!props.gift.processedCheck ||
				props.gift.computedNotEnoughBonuses ||
				props.gift.isOnlyAddBonuses)
		) {
			return true;
		}
		if (!!props.errorText) return true;
		if (props.floatingSum?.errorText) return true;
		return !!(
			props.product.floating_sum_settings?.is_enabled &&
			props.floatingSum?.floatingSumAmount === 0 &&
			!props.isInCart
		);
	}, [
		user,
		selectedProductParams.product_id,
		props.errorText,
		props.floatingSum?.errorText,
		props.floatingSum?.floatingSumAmount,
		props.gift,
		props.isInCart,
		props.product.floating_sum_settings?.is_enabled,
		props.product.floating_sum_settings?.options?.length,
		props.product.floating_sum_settings?.user_sum_enabled,
		props.product.is_available,
		props.product.price,
		props.product.type,
		props.topupService?.isLoading,
		props.topupService?.optionsService.selectedOption?.specialData,
	]);

	return (
		<>
			<Box
				width={props.fullWidth ? "100%" : "auto"}
				onMouseOver={() => setShowPopup(true)}
				onMouseLeave={() => setShowPopup(false)}
				ref={btnRef}
				sx={{ cursor: !!props.errorText ? "not-allowed" : "pointer" }}
			>
				<LoadingButton
					size={props.buttonSize}
					variant={props.variant ? props.variant : "outlined"}
					fullWidth={props.fullWidth}
					className={
						"d-flex align-items-center " +
						"white-space-nowrap " +
						`px-1 px-sm-2 ${textSize} `
						// + (props.isInCart ? 'ms-2' : '')
					}
					onClick={handleAddToCartClick}
					startIcon={computedCartButtonIcon}
					loading={props.addToCartLoading}
					loadingPosition="start"
					disabled={computedDisabled}
				>
					<span className={`${textSize}`}>
						{computedCartButtonText}
						{(props.product.type !== "topup" ||
							(props.product.type === "topup" && !!user)) &&
							props.price &&
							props.product.type !== "gift" && (
								<span className={"ms-2"}>{props.price}</span>
							)}
					</span>
				</LoadingButton>
			</Box>

			<BasePopup
				id={"cart-error"}
				open={showPopup && !!props.errorText}
				anchor={btnRef.current}
				disablePortal={true}
				placement={"top"}
			>
				<PopupBody sx={{ maxWidth: 250 }}>
					<Interweave content={props.errorText} />
				</PopupBody>
			</BasePopup>
		</>
	);
}

type FavoritesButtonProps = {
	isInFavorites: boolean;
	addOrRemoveFavoritesProduct: () => any;
	favoritesIconFontSize?: IconOwnProps["fontSize"];
};

export function FavoritesButton(props: FavoritesButtonProps) {
	const fontSize = props.favoritesIconFontSize || "large";

	return (
		<Box
			onClick={(e: MouseEvent<HTMLSpanElement>) => {
				e.stopPropagation();
				props.addOrRemoveFavoritesProduct();
			}}
			sx={{ cursor: "pointer" }}
			color={"error.main"}
		>
			{props.isInFavorites ? (
				<FavoriteIcon color={"inherit"} fontSize={fontSize} />
			) : (
				<FavoriteBorderIcon color={"inherit"} fontSize={fontSize} />
			)}
		</Box>
	);
}

interface IProductControlsProps {
	product: Product;
	quantity?: number;
	isListView?: boolean;
}

export function ProductControls(props: IProductControlsProps) {
	const delRef = useRef<HTMLDivElement | null>(null);
	const {
		localisation: { menu },
	} = useAppContext();
	const { cartService } = useSelectedStoreContext();
	const selectProduct = useSelectProduct();
	const { computedQuantity } = useProductQuantity(null, false, 1, props.product);

	const handleClick = (e: any) => {
		e.stopPropagation();
		if (!props.product.floating_sum_settings?.is_enabled) {
			cartService.updateProductVariationsState(true, false);
		}
		selectProduct(props.product);
	};

	const computedButtonText = useMemo(() => {
		if (props.product.floating_sum_settings?.is_enabled) {
			return menu.floatingSumEdit;
		}
		return `${menu.managePositionBtn} (${computedQuantity})`;
	}, [
		computedQuantity,
		menu.floatingSumEdit,
		menu.managePositionBtn,
		props.product.floating_sum_settings?.is_enabled,
	]);

	return (
		<>
			<ButtonGroup variant="outlined">
				<Button
					onClick={(e: any) => {
						e.stopPropagation();
						if (delRef.current) {
							const child = delRef.current.children[0];
							if (child) {
								// @ts-ignore
								child.click();
							}
						}
					}}
					sx={{ paddingX: "4px", paddingY: "7px", minWidth: "30px" }}
				>
					<Box ref={delRef}>
						<DeleteProductsPopConfirmWrapper
							deleteSingle={false}
							productId={props.product.id}
						>
							<DeleteForeverIcon color={"error"} />
						</DeleteProductsPopConfirmWrapper>
					</Box>
				</Button>
				<Button onClick={handleClick} sx={{ paddingX: "4px", paddingY: "7px" }}>
					{computedButtonText}
				</Button>
			</ButtonGroup>
			{props.isListView &&
				!props.product.floating_sum_settings?.is_enabled &&
				!props.product.floating_qty_enabled && (
					<ProductCardToCartWithOtherAttributesButton product={props.product} />
				)}
		</>
	);
}

interface IProductInfoButtonProps {
	product: Product;
	fromDetailed?: boolean;
}

export function ProductInfoButton(props: IProductInfoButtonProps) {
	const isSameOrigin = props.product.pti_info_link?.startsWith("/");

	const setHideDetailedProductModal = useSetRecoilState(HideDetailedProductModal);

	const handleClick = (e: any) => {
	   e.preventDefault();

	   const url = props.product.pti_info_link;
	   if (!url) return;

	   const isTelegramBot = url.toLowerCase().includes('t.me/') || url.toLowerCase().startsWith('https://telegram.me/');

	   if (window?.Telegram?.WebApp?.initData && isTelegramBot) {
			window.Telegram.WebApp.openLink(url);
			window.Telegram.WebApp.close();
	   } else {
		  if (isSameOrigin) {
			 setHideDetailedProductModal(true);
			 changePage(url);
		  } else {
			 window.open(url, "_blank");
		  }
	   }
	};


	const buttonStyle = useMemo(() => {
		const style = { "&a.MuiButton-root:hover": { color: "primary.main" } };
		if (props.fromDetailed) {
			//@ts-ignore
			style["mb"] = 3;
		} else {
			//@ts-ignore
			style["paddingX"] = "4px";
			//@ts-ignore
			style["paddingY"] = "7px";
		}

		return style;
	}, [props.fromDetailed]);

	if (!props.product.pti_info_text || !props.product.pti_info_link) return <></>;

	return (
		<Box className={"product-info-button"}>
			<Button
				fullWidth={props.fromDetailed}
				size={props.fromDetailed ? "large" : "medium"}
				variant={props.fromDetailed ? "contained" : "outlined"}
				onClick={handleClick}
				sx={buttonStyle}
				href={props.product.pti_info_link}
				target={"_blank"}
				LinkComponent={"a"}
			>
				{props.product.pti_info_text}
			</Button>
		</Box>
	);
}
