import { MDBFooter } from "mdb-react-ui-kit";

import { BrandInfo } from "../../api/shop/basic/types";
import useAppContext from "../../useAppContext";
import { Box, Link } from "@mui/material";
import { getPrivacyPolicyLink, getTermsOfUseLink } from "../../helpers/consents";
import Interweave from "../../features/Interweave";


interface BaseShopFooterProps {
    brandInfo?: BrandInfo | null
    children?: any
}

export default function BaseShopFooter({brandInfo: propsBrandInfo, children}: BaseShopFooterProps){
    const {
        lang,
        localisation,
        brandInfo: contextBrandInfo,
    } = useAppContext()

    const brandInfo: BrandInfo | null | undefined = propsBrandInfo || contextBrandInfo

    if (!brandInfo) return null

    return (
        <MDBFooter
            id={'shop-footer'}
            className={'theme-bg-paper text-start text-muted border-top w-100 px-0 mt-5'}
        >
            {children}

            <div className={'text-center px-3 pt-3 pb-2 theme-text'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                <div className={'small'}>
                    © {new Date().getFullYear()} <strong>{brandInfo.name}</strong>
                </div>
                <div>
                    <Interweave content={brandInfo.footer_sign}/>
                </div>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    gap={2}
                    mt={1}
                >
                    <Link
                        target={'_blank'}
                        variant={'body2'}
                        color={'text.secondary'}
                        href={getTermsOfUseLink(brandInfo, lang)}
                    >
                        {localisation.global.termsOfUseText}
                    </Link>
                    <Link
                        target={'_blank'}
                        variant={'body2'}
                        color={'text.secondary'}
                        href={getPrivacyPolicyLink(brandInfo, lang)}
                    >
                        {localisation.global.privacyPolicyText}
                    </Link>
                </Box>
            </div>
        </MDBFooter>
    )
}
