import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "mdb-react-countdown/dist/css/countdown.min.css";
import "mdb-react-treeview/dist/css/treeview.min.css";
import "mdb-react-table-editor/dist/css/table-editor.min.css";

import "leaflet/dist/leaflet.css";

import "./static/App.scss";

import ErrorBoundary from "./features/ErrorBoundary";
import Shop from "./shop/Shop";
import ShopMenu from "./shop/menu/ShopMenu";
import StoreSelect from "./shop/select/StoreSelect";
import App from "./App";
import ShopCart from "./shop/cart/ShopCart";
import { EmailConfirmPage } from "./auth/EmailConfirmPage";
import MakeOrderPage from "./shop/order/MakeOrder/MakeOrderPage";
import { RedirectOrdersPage } from "./shop/order/OrderList/OrdersPage";
import ShopNotFound from "./components/ShopNotFound";
import ShopDocument from "./shop/ShopDocument";
import FavoritesPage from "./shop/favorites/FavoritesPage";
import QrMenu from "./shop/qr_menu/QrMenu";
import InvoicePage from "./shop/payments/InvoicePage/InvoicePage";
import QrCodePage from "./shop/payments/QrCode";
import CustomerStoreOrderPage, { RedirectCustomerOrderPage } from "./shop/order/CustomerOrderPage";
import SuccessPaymentPage from "./shop/payments/SuccessPaymentPage";
import ShopMenuRedirectToNew from "./shop/menu/ShopMenuRedirectToNew";
import ProductPageRedirectToNew from "./shop/menu/product/ProductPageRedirectToNew";
import ShopDocumentRedirect from "./shop/ShopDocumentRedirect";
import SelectedStoreWrapper from "./shop/SelectedStore";
import ShopNoStoreSelectedWrapper from "./shop/ShopNoStoreSelectedWrapper";
import RootLayout from "./components/RootLayout";
import ProfileOverall from "./shop/profile/pages/ProfileOverall";
import ProfileFavorites from "./shop/profile/pages/ProfileFavorites";
import ProfileFriends from "./shop/profile/pages/ProfileFriends";
import ProfileLoyalty from "./shop/profile/pages/ProfileLoyalty";
import ProfileOrders from "./shop/profile/pages/ProfileOrders";
import ProfileMyCard from "./shop/profile/pages/ProfileMyCard";
import FastPayPage from "./shop/payments/FastPayPage";
import ResetPasswordPage from "./auth/ResetPasswordPage";
import ChangeEmailPage from "./auth/ChangeEmailPage";
import AddCouponPage from "./shop/loyalty/AddCouponPage";
import CouponPage from "./shop/loyalty/CouponPage";
import WalletPage from "./shop/loyalty/components/Wallet/WalletPage";
import UserCouponPage from "./shop/loyalty/UserCouponPage";
import ProfileShareAndEarn from "./shop/profile/pages/ProfileShareAndEarn";
import ShareAndEarnPage from "./shop/loyalty/ShareAndEarnPage";
import AddFriendPage from "./shop/friends/AddFriendPage";
import CustomHTMLPage from "./components/DocumentPage";
import { ReviewPage } from "./shop/review";
import ShortTokenPage from "./ShortTokenPage";
import MarketingPage from "./shop/MarketingPage";
import ReceiptPage from "./features/Receipt/ReceiptPage";
import OAuthWidget from "./features/OAuthWidget/OAuthWidget";
import StorePlaceholder from "./components/StorePlaceholder";
import FriendPaymentPage from "./shop/payments/FriendPaymentPage";
import ScanReceiptLandingPage from "./features/Receipt/scan/landing/ScanReceiptLandingPage";
import OAuthConsentPage from "./auth/Autorisation/external/oauth/OAuthConsent/OAuthConsentPage";
import ClientWebPageView from "./components/ClientWebPages/ClientWebPageView/ClientWebPageView";

export const router = createBrowserRouter([
	{
		element: <RootLayout />,
		children: [
			{
				path: "/",
				element: <App />,
				children: [
					{
						index: true,
						element: <StorePlaceholder />,
					},
					{
						path: "auth",
						children: [
							{
								path: "confirm",
								element: <EmailConfirmPage />,
							},
							{
								path: "reset_password",
								element: <ResetPasswordPage />,
							},
							{
								path: "change_email",
								element: <ChangeEmailPage />,
							},
						],
					},
					{
						path: "shop",
						element: <Shop />,
						children: [
							{
								element: <ShopNoStoreSelectedWrapper />,
								children: [
									// MOVED ROUTES

									// moved to /shop/document/:documentType
									{
										path: "agreement",
										element: (
											<ShopDocumentRedirect documentType={"agreement"} />
										),
									},
									// moved to /shop/document/:documentType
									{
										path: "about",
										element: <ShopDocumentRedirect documentType={"about"} />,
									},
									// moved to /shop/:storeId/document/:documentType
									{
										path: "agreement/:storeId",
										element: (
											<ShopDocumentRedirect documentType={"agreement"} />
										),
									},
									// moved to /shop/:storeId/document/:documentType
									{
										path: "about/:storeId",
										element: <ShopDocumentRedirect documentType={"about"} />,
									},
									// moved to /shop/:storeId/menu
									{
										path: "menu/:storeId",
										element: <ShopMenuRedirectToNew />,
									},
									{
										path: "menu/*",
										element: <ShopMenuRedirectToNew />,
									},
									// moved to /shop/:storeId/menu/product/:productId
									{
										path: "product/:storeId/:productId",
										element: <ProductPageRedirectToNew />,
									},
									// moved to /shop/:storeId/orders/
									{
										path: "order/list",
										element: <RedirectOrdersPage />,
									},
									// moved to /shop/:storeId/orders/:orderId
									{
										path: "order/:orderId",
										element: <RedirectCustomerOrderPage />,
									},

									// END MOVED ROUTES

									{
										path: "select",
										element: <StoreSelect />,
									},

									{
										path: "custom_page",
										children: [
											{
												path: ":slug",
												element: <ClientWebPageView />,
											},
										],
									},

									// OPTIONAL STORE ROUTES

									// qr menu no store; with store /shop/:storeId/qr_menu
									{
										path: "qr_menu",
										element: <QrMenu />,
									},
									{
										path: "review",
										element: <ReviewPage />,
									},
									// profile no store; with store /shop/:storeId/profile/...
									{
										path: "profile",
										children: [
											{
												index: true,
												element: <ProfileOverall />,
											},
											{
												path: "favorites",
												element: <ProfileFavorites />,
											},
											{
												path: "friends",
												element: <ProfileFriends />,
											},
											{
												path: "loyalty",
												element: <ProfileLoyalty />,
											},
											{
												path: "orders",
												element: <ProfileOrders />,
											},
											{
												path: "my_card",
												element: <ProfileMyCard />,
											},
											{
												path: "share_and_earn",
												element: <ProfileShareAndEarn />,
											},
										],
									},
									// invoice no store; with store /shop/:storeId/invoice/...
									{
										path: "invoice",
										children: [
											{
												index: true,
												element: <InvoicePage />,
											},
											{
												path: ":id",
												element: <InvoicePage />,
											},
										],
									},
									// document no store; with store /shop/:storeId/document/:documentType
									{
										path: "document/:documentType",
										element: <ShopDocument />,
									},

									// /fast_pay
									FastPayPage(),

									{
										path: "add_coupon",
										element: <AddCouponPage />,
									},
									{
										path: "coupon/show/:codeOrId",
										element: <CouponPage />,
									},
									{
										path: "coupon/:couponId",
										element: <UserCouponPage />,
									},
									{
										path: "share_and_earn/:referralCode",
										element: <ShareAndEarnPage />,
									},
									{
										path: "wallet/show/",
										element: <WalletPage />,
									},
									{
										path: "add_friend/:req_user_id",
										element: <AddFriendPage />,
									},
									{
										path: "receipt/:receiptId",
										element: <ReceiptPage />,
									},
									{
										path: "scan_receipt",
										element: <ScanReceiptLandingPage />,
									},
									{
										path: "marketing",
										element: <MarketingPage />,
									},
								],
							},
							{
								path: ":storeId",
								element: <SelectedStoreWrapper />,
								children: [
									{
										path: "menu",
										children: [
											{
												index: true,
												element: <ShopMenu />,
											},
										],
									},

									{
										path: "custom_page",
										children: [
											{
												path: ":slug",
												element: <ClientWebPageView />,
											},
										],
									},
									// qr_menu with store; no store /shop/qr_menu
									{
										path: "qr_menu",
										element: <QrMenu />,
									},
									{
										path: "review",
										element: <ReviewPage />,
									},

									// profile with store; no store /shop/profile/...
									{
										path: "profile",
										children: [
											{
												index: true,
												element: <ProfileOverall />,
											},
											{
												path: "favorites",
												element: <ProfileFavorites />,
											},
											{
												path: "friends",
												element: <ProfileFriends />,
											},
											{
												path: "loyalty",
												element: <ProfileLoyalty />,
											},
											{
												path: "orders",
												element: <ProfileOrders />,
											},
											{
												path: "my_card",
												element: <ProfileMyCard />,
											},
											{
												path: "share_and_earn",
												element: <ProfileShareAndEarn />,
											},
										],
									},
									{
										path: "cart",
										element: <ShopCart />,
									},
									{
										path: "favorites",
										element: <FavoritesPage />,
									},
									{
										path: "orders",
										children: [
											{
												path: "checkout",
												element: <MakeOrderPage />,
											},
											{
												path: "list",
												element: <RedirectOrdersPage />,
											},
											{
												path: ":orderId",
												element: <CustomerStoreOrderPage />,
											},
										],
									},
									// invoice with store; no store /shop/invoice/...
									{
										path: "invoice",
										children: [
											{
												index: true,
												element: <InvoicePage />,
											},
											{
												path: ":id",
												element: <InvoicePage />,
											},
										],
									},
									// document with store; no store /shop/document/:documentType
									{
										path: "document/:documentType",
										element: <ShopDocument />,
									},
									{
										path: "receipt/:receiptId",
										element: <ReceiptPage />,
									},
									{
										path: "scan_receipt",
										element: <ScanReceiptLandingPage />,
									},
									{
										path: "share_and_earn/:referralCode",
										element: <ShareAndEarnPage />,
									},
									{
										path: "add_coupon",
										element: <AddCouponPage />,
									},
									{
										path: "marketing",
										element: <MarketingPage />,
									},
								],
							},
						],
					},
					{
						path: "qrcode/:data",
						element: <QrCodePage />,
					},

					{
						path: "pages",
						children: [
							{
								path: ":pageName",
								element: <CustomHTMLPage />,
							},
						],
					},
					{
						path: "404",
						element: <ShopNotFound />,
					},
					{
						path: "/success_payment",
						element: <SuccessPaymentPage />,
					},
					{
						path: "/payment_friend",
						children: [
							{
								path: "orders/:id",
								element: <FriendPaymentPage mode={"order"} />,
							},
							{
								path: "invoices/:id",
								element: <FriendPaymentPage mode={"invoice"} />,
							},
						],
					},
					{
						path: "st/:short_token",
						element: <ShortTokenPage />,
					},
					{
						path: "oauth_consent",
						element: <OAuthConsentPage />,
					},
					{
						path: "*",
						element: <ShopNotFound />,
					},
				],
			},
			{
				path: "oauth_google",
				element: <OAuthWidget provider={"google"} />,
			},
			{
				path: "oauth_apple",
				element: <OAuthWidget provider={"apple"} />,
			},
		],
	},
]);

function Root() {
	return (
		<ErrorBoundary>
			<RouterProvider router={router} />
		</ErrorBoundary>
	);
}

const container = document.getElementById("root");

if (container) {
	const root = createRoot(container);
	root.render(<Root />);
}
export { isLocaleClockType24h } from "./helpers/isLocaleClockType24h";
