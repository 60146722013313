import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { Alert, Container } from "@mui/material";

import useAppContext from "../../../../useAppContext";
import api from "../../../../api";
import { IIncustCouponExt } from "../../types";
import CouponSkeleton from "../Skeletons/CouponSkeleton";
import { CouponContent } from "./CouponContent";
import { getErrorText } from "../../../../helpers/errors";
import CouponAuth from "./CouponAuth";

interface ICouponProps {
	codeOrId: string | null;
	needAddToWallet?: boolean | null;
	showOnPage?: boolean | null;
	orderToken?: string | null;
	externalSetShowAuthModal?: (value: boolean) => void;
	invoice_template_id?: number | null | undefined;
}

export default function Coupon(props: ICouponProps) {
	const {
		lang,
		brandInfo,
		localisation: { profile, global },
		loadingManager,
	} = useAppContext();
	const queryClient = useQueryClient();

	const codeOrId = props.codeOrId;

	const [showAuthModal, setShowAuthModal] = useState(false);
	const [showEmail, setShowEmail] = useState(false);

	const isInputDataError = useMemo(() => {
		if (!codeOrId) {
			console.log(profile.acceptCouponNoIdErr);
			return true;
		} else if (!brandInfo?.id) {
			console.log(global.brandNotLoadedText);
			return true;
		} else if (
			!brandInfo.loyalty_info?.loyalty_enabled ||
			brandInfo.loyalty_info.loyalty_type !== "incust"
		) {
			console.log(global.loyaltyNotConnectedText);
			return true;
		}

		return false;
	}, [codeOrId, brandInfo, profile, global]);

	const queryKey = useMemo(
		() => ["checkCoupon", codeOrId, props.orderToken, lang],
		[codeOrId, props.orderToken, lang]
	);
	const query = useQuery({
		queryKey: queryKey,
		placeholderData: keepPreviousData,
		queryFn: async () => {
			if (!codeOrId) {
				// not possible, for typescript
				throw new Error("Unknown error");
			}
			const { data } = await api.shop.loyalty.checkCoupon(codeOrId, props.orderToken);
			return data;
		},
		refetchOnMount: true,
		enabled: !!codeOrId && !isInputDataError,
	});

	const setCouponExt = useCallback(
		(couponExt: IIncustCouponExt) => {
			queryClient.setQueryData(queryKey, couponExt);
		},
		[queryClient, queryKey]
	);

	if (isInputDataError || query.isError) {
		return (
			<Container maxWidth={"xs"} sx={{ px: 0 }}>
				<Alert severity={"error"}>
					{isInputDataError
						? profile.inputDataError
						: getErrorText(query.error, global.errUnknown)}
				</Alert>
			</Container>
		);
	}

	if (loadingManager.isSomethingLoading || query.isPending) return <CouponSkeleton />;

	return (
		<>
			<CouponContent
				couponExt={query.data}
				setCouponExt={setCouponExt}
				needAddToWallet={props.needAddToWallet}
				showOnPage={props.showOnPage}
				setShowAuthModal={props.externalSetShowAuthModal || setShowAuthModal}
				invoice_template_id={props.invoice_template_id}
			/>

			{!props.externalSetShowAuthModal && (
				<CouponAuth
					show={showAuthModal}
					setShow={setShowAuthModal}
					showEmail={showEmail}
					setShowEmail={setShowEmail}
				/>
			)}
		</>
	);
}
