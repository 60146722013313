import { GlobalLocale } from "./GlobalLocale";
import { SelectLocale } from "./SelectLocale";
import { AuthLocale } from "./AuthLocale";
import { FormsLocale } from "./FormsLocale";
import { MenuLocale } from "./MenuLocale";
import { CartLocale } from "./CartLocale";
import { OrderLocale } from "./OrderLocale";
import { ProfileLocale } from "./ProfileLocale";
import { QrMenuLocale } from "./QrMenuLocale";
import { ExternalLoginWidgetLocale } from "./ExternalLoginWidgetLocale";
import { AILocale } from "./AILocale";
import { PaymentLocale } from "./PaymentLocale";
import { CheckLocale } from "./CheckLocale";
import { MapsLocale } from "./MapsLocale";
import { ReceiptLocale } from "./ScanReceipt";
import { TopupLocale } from "./TopupLocale";
import { ClientWebPages } from "./ClientWebPages";

const AppLocales = {
	global: GlobalLocale,
	select: SelectLocale,
	auth: AuthLocale,
	forms: FormsLocale,
	menu: MenuLocale,
	cart: CartLocale,
	orders: OrderLocale,
	profile: ProfileLocale,
	qrMenu: QrMenuLocale,
	externalLoginWidget: ExternalLoginWidgetLocale,
	ai: AILocale,
	payment: PaymentLocale,
	check: CheckLocale,
	maps: MapsLocale,
	receipt: ReceiptLocale,
	topup: TopupLocale,
	clientWebPages: ClientWebPages,
};

export type AppLocalesType = typeof AppLocales;

export default AppLocales;
