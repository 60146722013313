import { OutputBlockData } from "@editorjs/editorjs";

/**
 * Image parser plugin for the RichTextRenderer component.
 * @param jsEditorBlockData - The block data from Editor.js containing the image information.
 * @returns The HTML string representation of the image.
 * @example
 * ```typescript
 * import { OutputBlockData } from "@editorjs/editorjs";
 * import { imageParserPlugin } from "./imageParser.plugin";
 *
 * const blockData: OutputBlockData = {
 *  id: "block-id",
 *  type: "image",
 *  data: {
 *  url: "https://example.com/image.jpg",
 *  caption: "Example image"
 *  }
 * };
 * const htmlString = imageParserPlugin(blockData);
 * console.log(htmlString);
 */
export const imageParserPlugin = (jsEditorBlockData: OutputBlockData): string => {
	return `<div style="max-width: 100%"><img src="${jsEditorBlockData.data.url}" alt="${jsEditorBlockData.data.caption}" style="max-width: 100%" /></div>`;
};
