import { ThemeModal } from "../../../helpers/ThemeComponents";
import useEpayLibraries from "../hooks/useEpayLibraries";
import { IInvoiceCreatedResult } from "../../../api/invoice/types";
import { AvailablePayments, PaymentMethod } from "../../../api/shop/basic/types";
import { IUsePaymentsService } from "../hooks/useNewPaymentsService";
import PaymentItem from "./PaymentItem";
import { Alert, Box, CircularProgress, Collapse, IconButton, Link, Typography } from "@mui/material";
import { Order } from "../../../api/shop/order/types";
import CachedIcon from "@mui/icons-material/Cached";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import { LoadingButton } from "@mui/lab";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Interweave from "../../../features/Interweave";
import useAppContext from "../../../useAppContext";
import api from "../../../api";
import { removeOrderState } from "../../order/MakeOrder/OrderStepper/orderSessionState";
import changePage from "../../../helpers/changePage";
import IncustPaymentItems, { IncustPaymentForm } from "./IncustPayment";
import SelectedPayment, { SelectedPaymentFeeAndDesc } from "./SelectedPayment";
import SelectFriendEnd from "../../order/MakeOrder/OrderStepper/SelectFriendEnd";
import useIncustPayDataService from "../PaymentButtons/IncustPay/useIncustPayDataService";
import OrangePayModalContent from "../PaymentButtons/PayModal/methods/OrangePayModalContent";
import EWalletPayModalContent from "../PaymentButtons/PayModal/methods/EWalletPayModalContent";

import MUIPopConfirm from "../../../features/MUIPopConfirm";
import { IncustContentData } from "../PaymentButtons/IncustPay/IncustPay";
import { User } from "../../../api/auth/types";
import FriendPayment from "../MakeInvoice/FriendPayment";
import IncustPayModalButtons from "../PaymentButtons/IncustPay/modals/IncustPayModalButtons";
import formatCurrency from "../../../helpers/formatCurrency";
import { NO_CENT_CURRENCIES } from "../../../helpers/noCentCurrencies";

export const disabledShowingMethods = ["incust_pay"];

interface IPaymentsProps {
	invoiceCreated?: IInvoiceCreatedResult | null;
	orderId?: number;
	orderToken?: string | null;
	invoiceToken?: string | null;
	setTelegramInterval?: (interval: any) => void;
	telegramInterval?: any;
	setExAvailablePayments?: (availablePayments: AvailablePayments | null) => void;
	setIsSuccessPayment?: (isSuccess: boolean) => void;
	modalShow?: boolean;
	needAutoRedirect?: boolean;
	type: "order" | "invoice";
	selectedStoreId?: number | null;
	paymentService: IUsePaymentsService;
	createdOrder?: Order | null;
	cancelOrderLoading?: boolean;
	onlyOnlinePayments?: boolean;
	user?: User | null;
}

export default function Payments(props: IPaymentsProps) {
	const { brandInfo, lang, authService: { client: clientType, isAuthorised: isAuthorised }, bot } = useAppContext();
	const localisation = useLocalisation();
	const isIncustPayMethod = props.paymentService.methods.some(x => x.provider === "incust_pay");
	// const incustPayService = props.incustPayService;

	const [incustFormData, setInternalIncustFormData] = useState<IncustContentData | null>(null);
	const incustPayDataService = useIncustPayDataService();

	const [payButtonMaxHeight, setPayButtonMaxHeight] = useState<number | null>(null);
	const [incustPayAccount, setIncustPayAccount] = useState<string | null>(null);

	useEpayLibraries(
		props.paymentService.methods.some(i => i.provider === "epay"),
		props.paymentService.paymentsInfo?.is_epay_test_mode,
	);

	const setSelectedProvider = props.paymentService.setSelectedProvider;
	const setIncustFormData = useCallback(
		(data: IncustContentData | null) => {
			props.paymentService.setPaymentsCollapseOpen(false);
			setInternalIncustFormData(data);
			if (!data && props.paymentService.selectedProvider?.provider === "incust_pay")
				return setSelectedProvider(null);

			if (data?.accountData || data?.cardData) {
				const name = data?.accountData?.special?.title;
				if (name) {
					setIncustPayAccount(name);
				} else {
					setIncustPayAccount(null);
				}

				const settingsId =
					data?.accountData?.incust_pay_configuration.payment_settings_id ||
					data?.cardData?.payment_settings_id;
				const method =
					props.paymentService.methods.find(x => x.settings_id === settingsId) ?? null;
				if (method) {
					setSelectedProvider(method);
				}
			}
		},
		[props.paymentService, setSelectedProvider],
	);


	const isEWalletWebPayment = useMemo(() => {

			return !!(bot?.bot_type && clientType === "messanger" && isAuthorised);

		}, [bot?.bot_type, clientType, isAuthorised],
	);

	const submit = useCallback(() => {
		if (
			props.paymentService.paymentsInfo?.email_required.some(
				i => i === props.paymentService.selectedProvider?.provider,
			) &&
			!props.createdOrder?.email &&
			!props.invoiceCreated?.invoice?.email
		) {
			props.paymentService.setError(localisation.orders.emailError);
			return;
		}
		if (
			props.paymentService.selectedProvider?.need_comment === "required" &&
			!props.paymentService.comment
		) {
			props.paymentService.setError(localisation.payment.commentRequired);
			return;
		}
		props.paymentService.setError("");
		// Не робимо makePrePayment якщо це eWallet в веб режимі
		if (isEWalletWebPayment && props.paymentService.selectedProvider?.provider === "ewallet") {
			return;
		}
		props.paymentService.makePrePayment().then();
	}, [isEWalletWebPayment, localisation.orders.emailError, localisation.payment.commentRequired, props.createdOrder?.email, props.invoiceCreated?.invoice?.email, props.paymentService]);


	const setForceSingleMethod = props.paymentService.setForceSingleMethod;
	const filteredMethods = useMemo(() => {
		let methods = props.paymentService.methods.filter(
			method => !disabledShowingMethods.includes(method.provider),
		);
		if (props.onlyOnlinePayments) {
			methods = methods.filter(method => method.is_online);
		}
		if (!props.user) {
			methods = methods.filter(method => method.provider !== "friend");
		}

		if (methods.length === 1 && !isIncustPayMethod) {
			if (!props.onlyOnlinePayments) {
				setForceSingleMethod(methods[0]);
				return methods;
			}
			if (methods[0].is_online) {
				setForceSingleMethod(methods[0]);
			}
		}
		return methods;
	}, [
		props.paymentService.methods,
		props.onlyOnlinePayments,
		props.user,
		isIncustPayMethod,
		setForceSingleMethod,
	]);

	const additionalFieldsBorder = useMemo(() => {
		if (!props.paymentService.selectedProvider) return 0;
		// if (props.paymentService.selectedProvider?.provider === "incust_pay") return 0;
		if (
			props.paymentService.selectedProvider.provider === "friend" ||
			props.paymentService.selectedProvider.provider === "orange" ||
			props.paymentService.selectedProvider.provider === "ewallet" ||
			props.paymentService.selectedProvider.provider === "incust_pay" ||
			props.paymentService.selectedProvider.need_comment === "optional" ||
			props.paymentService.selectedProvider.need_comment === "required"
		) {
			return 1;
		}
		return 0;
	}, [props.paymentService.selectedProvider]);

	const setPaymentButtonMaxHeightFunc = useCallback((height?: number | null) => {
		if (height) {
			setPayButtonMaxHeight(prev => {
				if (!prev || prev < height) return height;
				return prev;
			});
		}
	}, []);

	const incustAmountToPay = useMemo(() => {
		return props.paymentService.sumsToPay.sumToPay + props.paymentService.feeValue;
	}, [props.paymentService.feeValue, props.paymentService.sumsToPay.sumToPay]);

	const clearCart = props.paymentService.clearCart;
	const incustPayedCallback = useCallback(() => {
		clearCart();
		removeOrderState();
	}, [clearCart]);

	const computedFeeAmount = useMemo(() => {
		const currency = props.createdOrder?.currency || props.invoiceCreated?.invoice.currency;
		const totalAmount =
			props.createdOrder?.total_sum_with_extra_fee ||
			props.invoiceCreated?.invoice?.total_sum_with_extra_fee ||
			0;
		const percentFee = props.paymentService.selectedProvider?.fee_percent
			? (totalAmount * props.paymentService.selectedProvider?.fee_percent) / 100
			: 0;
		const valueFee = props.paymentService.selectedProvider?.fee_value || 0;
		let value: number;
		if (valueFee && percentFee) {
			value = valueFee + percentFee;
		} else {
			value = valueFee || percentFee;
		}

		if (!value) return "";

		if (currency && NO_CENT_CURRENCIES.has(currency)) {
			value = Math.ceil(value);
		}

		return `${formatCurrency(value, brandInfo?.default_lang || lang, currency)}`;
	}, [
		brandInfo?.default_lang,
		lang,
		props.createdOrder?.currency,
		props.createdOrder?.total_sum_with_extra_fee,
		props.invoiceCreated?.invoice.currency,
		props.invoiceCreated?.invoice?.total_sum_with_extra_fee,
		props.paymentService.selectedProvider?.fee_percent,
		props.paymentService.selectedProvider?.fee_value,
	]);

	const computedFeeTooltip = useMemo(() => {
		const currency = props.createdOrder?.currency || props.invoiceCreated?.invoice.currency;

		const totalAmount =
			props.createdOrder?.total_sum_with_extra_fee  ||
			props.invoiceCreated?.invoice?.total_sum_with_extra_fee ||
			0;

		let fee = "";
		if (props.paymentService.selectedProvider?.fee_value) {
			let feeValue = props.paymentService.selectedProvider.fee_value;
			if (currency && NO_CENT_CURRENCIES.has(currency)) {
				feeValue = Math.ceil(feeValue);
			}
			fee = `${formatCurrency(feeValue, brandInfo?.default_lang || lang, currency)}`;
		}
		if (props.paymentService.selectedProvider?.fee_percent) {
			let feeValue = props.paymentService.selectedProvider?.fee_percent
				? (totalAmount * props.paymentService.selectedProvider?.fee_percent) / 100
				: 0;
			if (currency && NO_CENT_CURRENCIES.has(currency)) {
				feeValue = Math.ceil(feeValue);
			}
			if (fee) {
				fee += ` + ${formatCurrency(feeValue, brandInfo?.default_lang || lang, currency)}`;
				return fee;
			}
			fee = `${formatCurrency(feeValue, brandInfo?.default_lang || lang, currency)}`;
		}
		return fee;
	}, [
		brandInfo?.default_lang,
		lang,
		props.createdOrder?.currency,
		props.createdOrder?.total_sum_with_extra_fee,
		props.invoiceCreated?.invoice.currency,
		props.invoiceCreated?.invoice?.total_sum_with_extra_fee,
		props.paymentService.selectedProvider?.fee_percent,
		props.paymentService.selectedProvider?.fee_value,
	]);

	const computedDesc = useMemo(() => {
		if (
			props.paymentService.selectedProvider?.provider === "unipos" &&
			props.paymentService.uniposDesc
		) {
			return props.paymentService.uniposDesc;
		}
		return props.paymentService.selectedProvider?.desc;
	}, [
		props.paymentService.selectedProvider?.desc,
		props.paymentService.selectedProvider?.provider,
		props.paymentService.uniposDesc,
	]);

	const setOrderId = props.paymentService.setOrderId;
	const setOrderToken = props.paymentService.setOrderToken;
	useEffect(() => {
		if (props.orderId) {
			setOrderId(props.orderId);
		}
		if (props.orderToken) {
			setOrderToken(props.orderToken);
		}
	}, [props.orderId, props.orderToken, setOrderId, setOrderToken]);

	const setInvoiceId = props.paymentService.setInvoiceId;
	const setInvoiceToken = props.paymentService.setInvoiceToken;
	useEffect(() => {
		if (props.invoiceCreated?.invoice) {
			setInvoiceId(props.invoiceCreated.invoice.id);
		}
		if (props.invoiceToken) {
			setInvoiceToken(props.invoiceToken);
		}
	}, [props.invoiceCreated?.invoice, props.invoiceToken, setInvoiceId, setInvoiceToken]);

	const ewalletData = useMemo(() => {
	  if (props.paymentService.selectedProvider?.provider !== "ewallet") {
		return undefined;
	  }
	  return props.paymentService.selectedProvider?.incust_pay_data?.incust_pay_list?.[0];
	}, [props.paymentService.selectedProvider]);
	const hasCorporateAccess = ewalletData?.corporate_special_accounts_access?.[0];
	const hasSpecialAccount = ewalletData?.specials?.[0];
	const hasConfiguration = ewalletData?.incust_pay_configuration;

	return (
		<>
			<Collapse in={props.paymentService.paymentsCollapseOpen} timeout="auto" unmountOnExit>
				<Box mt={3} mx={3} border={1} borderColor={"divider"} sx={{ borderRadius: "8px" }}>
					{filteredMethods.length === 0 &&
						!props.paymentService.paymentsInfo?.single_payment_method &&
						!props.paymentService.forceSingleMethod && (
							<Box p={3}>{localisation.payment.emptyPayments}</Box>
						)}
					{filteredMethods.map((method, index) => (
						<Box key={index}>
							<PaymentItem
								method={method}
								isLast={index === props.paymentService.methods.length - 1}
								setSelectedProvider={props.paymentService.setSelectedProvider}
								totalAmount={
									props.createdOrder?.total_sum_with_extra_fee ||
									props.invoiceCreated?.invoice?.total_sum_with_extra_fee ||
									0
								}
								currency={
									props.createdOrder?.currency ||
									props.invoiceCreated?.invoice.currency
								}
								setCollapse={() =>
									props.paymentService.setPaymentsCollapseOpen(false)
								}
								paymentsService={props.paymentService}
								isSelectItem={false}
								isCollapsed={props.paymentService.paymentsCollapseOpen}
								payButtonMaxHeight={payButtonMaxHeight}
								setPayButtonMaxHeight={setPaymentButtonMaxHeightFunc}
								uniposDesc={props.paymentService.uniposDesc}
								uniposLoading={props.paymentService.uniposIsLoading}
							/>
						</Box>
					))}

					{isIncustPayMethod && (
						<IncustPaymentItems
							incustPayData={
								props.paymentService.methods.find(x => x.provider === "incust_pay")
									?.incust_pay_data
							}
							type={props.type}
							selectedStoreId={props.selectedStoreId}
							paymentService={props.paymentService}
							totalAmount={
								props.createdOrder?.total_sum_with_extra_fee ||
								props.invoiceCreated?.invoice?.total_sum_with_extra_fee ||
								0
							}
							currency={
								props.createdOrder?.currency ||
								props.invoiceCreated?.invoice.currency ||
								""
							}
							incustFormData={incustFormData}
							setInternalIncustFormData={setInternalIncustFormData}
							setIncustFormData={setIncustFormData}
							payButtonMaxHeight={payButtonMaxHeight}
							setPayButtonMaxHeight={setPaymentButtonMaxHeightFunc}
							topupAccountId={
								props.createdOrder?.order_products[0]?.incust_account?.id
							}
						/>
					)}
				</Box>
			</Collapse>

			{props.paymentService.paymentNoLongerAvailable && <PaymentNoLongerAvailableAlert />}

			<Box
				sx={{
					position: "sticky",
					bottom: 0,
					background: "var(--mui-palette-background-paper)",
					pt: 2,
				}}
			>
				<SelectedPayment
					paymentService={props.paymentService}
					totalAmount={
						props.createdOrder?.total_sum_with_extra_fee ||
						props.invoiceCreated?.invoice?.total_sum_with_extra_fee ||
						0
					}
					currency={
						props.createdOrder?.currency || props.invoiceCreated?.invoice.currency
					}
					uniposDesc={props.paymentService.uniposDesc}
					selectedIncustAccount={incustPayAccount}
					computedFeeTooltip={computedFeeTooltip}
					computedFeeAmount={computedFeeAmount}
				/>

				<Box
				  sx={{
					border:
					  ["incust_pay", "ewallet"].includes(props.paymentService.selectedProvider?.provider || '') ||
					  props.paymentService.paymentsCollapseOpen
						? 0
						: additionalFieldsBorder,
					borderRadius:
					  ["incust_pay", "ewallet"].includes(props.paymentService.selectedProvider?.provider || '') ||
					  props.paymentService.paymentsCollapseOpen
						? 0
						: "8px",
					borderColor: "divider",
					m: additionalFieldsBorder ? 3 : 0,
				  }}
				>
					{incustFormData && (
						<>
							<IncustPaymentForm
								paymentService={props.paymentService}
								formData={incustFormData}
								amountToPay={incustAmountToPay}
								setContentData={setIncustFormData}
								type={props.type}
								paymentData={props.paymentService.paymentData}
								selectedStoreId={props.selectedStoreId}
								incustPayDataService={incustPayDataService}
								additionalFields={
									<>
										{!props.paymentService.paymentsCollapseOpen && (
											<PaymentFields
												method={props.paymentService.selectedProvider}
												setPaymentComment={props.paymentService.setComment}
											/>
										)}
									</>
								}
								payedCallback={incustPayedCallback}
								descAndFeeNode={
									<>
										{!!(
											props.paymentService.selectedProvider &&
											props.paymentService.selectedProvider.provider ===
											"incust_pay" &&
											(computedDesc ||
												props.paymentService.selectedProvider.fee_value ||
												props.paymentService.selectedProvider.fee_percent)
										) && (
											<SelectedPaymentFeeAndDesc
												computedFeeTooltip={computedFeeTooltip}
												computedFeeAmount={computedFeeAmount}
												computedDesc={computedDesc}
												mx={0}
												mt={0}
												notShow={props.paymentService.paymentsCollapseOpen}
											/>
										)}
									</>
								}
								buttonsNode={
									<>
										{!!(incustFormData && incustFormData.accountData) && (
											<IncustPayModalButtons
												accountData={incustFormData.accountData}
												onClose={() => {
												}}
												incustPayDataService={incustPayDataService}
												hideCancel={true}
												paymentsService={props.paymentService}
												cancelOrderLoading={props.cancelOrderLoading}
												amountToPay={incustAmountToPay}
											/>
										)}
									</>
								}
							/>
						</>
					)}

					{isEWalletWebPayment &&
					  props.paymentService.selectedProvider?.provider === "ewallet" &&
					  ewalletData &&
					  hasConfiguration &&
					  (hasCorporateAccess || hasSpecialAccount) && (
						<IncustPaymentForm
						  formData={{
							type: "account", // завжди account для ewallet, ніколи не card
							accountData: hasCorporateAccess ? {
							  type: "corporate-special-account-access" as const,
							  incust_pay_configuration: hasConfiguration,
							  special: null,
							  corporate_special_account_access: hasCorporateAccess
							} : {
							  type: "special-account" as const,
							  incust_pay_configuration: hasConfiguration,
							  special: hasSpecialAccount!,
							  corporate_special_account_access: null
							}
						  }}
						  is_ewallet_payment={true}
						  amountToPay={incustAmountToPay}
						  setContentData={setIncustFormData}
						  type={props.type}
						  paymentData={props.paymentService.paymentData}
						  selectedStoreId={props.selectedStoreId}
						  incustPayDataService={incustPayDataService}
						  payedCallback={incustPayedCallback}
						  descAndFeeNode={null}
						  buttonsNode={
							<IncustPayModalButtons
							  accountData={hasCorporateAccess ? {
								type: "corporate-special-account-access" as const,
								incust_pay_configuration: hasConfiguration,
								special: null,
								corporate_special_account_access: hasCorporateAccess
							  } : {
								type: "special-account" as const,
								incust_pay_configuration: hasConfiguration,
								special: hasSpecialAccount!,
								corporate_special_account_access: null
							  }}
							  onClose={() => {}}
							  incustPayDataService={incustPayDataService}
							  hideCancel={true}
							  paymentsService={props.paymentService}
							  cancelOrderLoading={props.cancelOrderLoading}
							  amountToPay={incustAmountToPay}
							/>
						  }
						  paymentService={props.paymentService}
						/>
					)}

					{!props.paymentService.paymentsCollapseOpen &&
						props.paymentService.selectedProvider?.provider !== "incust_pay" && (
							<>
								{props.paymentService.selectedProvider?.provider === "orange" && (
									<Box p={3}>
										<OrangePayModalContent
											additionalFieldsNode={
												<PaymentFields
													method={props.paymentService.selectedProvider}
													setPaymentComment={
														props.paymentService.setComment
													}
												/>
											}
											paymentsService={props.paymentService}
										/>
									</Box>
								)}

								{props.paymentService.selectedProvider?.provider === "ewallet" && !isEWalletWebPayment && (
									<Box p={3}>
										<EWalletPayModalContent
											additionalFieldsNode={
												<PaymentFields
													method={props.paymentService.selectedProvider}
													setPaymentComment={
														props.paymentService.setComment
													}
												/>
											}
											paymentsService={props.paymentService}
										/>
									</Box>
								)}
								<Box px={3} pb={3}>
									{!!(
											props.paymentService.selectedProvider?.provider ===
											"friend" &&
											(props.createdOrder || props.invoiceCreated) &&
											props.paymentService.paymentData
										) &&
										(!!props.invoiceCreated?.invoice ? (
											<FriendPayment
												invoiceCreated={props.invoiceCreated}
												paymentsService={props.paymentService}
											/>
										) : (
											props.createdOrder && (
												<SelectFriendEnd
													orderId={props.createdOrder.id}
													orderToken={props.createdOrder.token}
													selectedStoreId={props.createdOrder.store_id}
													paymentService={props.paymentService}
												/>
											)
										))}

									{props.paymentService.selectedProvider?.provider !== "orange" &&
										props.paymentService.selectedProvider?.provider !==
										"ewallet" &&
										props.paymentService.selectedProvider?.provider !==
										"incust_pay" && !isEWalletWebPayment && (
											<PaymentFields
												method={props.paymentService.selectedProvider}
												setPaymentComment={props.paymentService.setComment}
											/>
										)}
								</Box>
							</>
						)}
				</Box>

				{props.paymentService.selectedProvider?.provider !== "friend" &&
					props.paymentService.selectedProvider?.provider !== "orange" &&
					props.paymentService.selectedProvider?.provider !== "ewallet" &&
					props.paymentService.selectedProvider?.provider !== "incust_pay" && (
						<Box width={"100%"} position={"sticky"} px={3} pb={3}>
							<LoadingButton
								fullWidth={true}
								disabled={
									props.paymentService.isLoading ||
									!props.paymentService.selectedProvider ||
									props.cancelOrderLoading
								}
								variant={"contained"}
								onClick={submit}
								loading={props.paymentService.isPrePaymentLoading}
								size={"large"}
							>
								{props.paymentService.selectedProvider?.is_online
									? localisation.orders.payButton
									: localisation.global.confirmButton}{" "}
								{props.paymentService.calcSumToPay}
							</LoadingButton>
						</Box>
					)}

				{props.paymentService.error && (
					<PaymentErrorAlert
						text={props.paymentService.error}
						setText={props.paymentService.setError}
					/>
				)}
			</Box>
		</>
	);
}

interface IPaymentsModalProps extends IPaymentsProps {
	show: boolean;
	setShow: (val: boolean) => void;
}

export function PaymentsModal(props: IPaymentsModalProps) {
	const localisation = useLocalisation();
	const { showError } = useAppContext();
	const [cancelOrderLoading, setCancelOrderLoading] = useState<boolean>(false);

	// const selectedStore = useSelectedStoreOptional();
	//
	// const isIncustPayMethod = props.paymentService.methods.some(x => x.provider === "incust_pay");
	// const incustPayService = useIncustPayData(selectedStore, isIncustPayMethod);

	const cancelOrder = useCallback(async () => {
		if (!props.orderId) return;

		try {
			setCancelOrderLoading(true);
			const response = await api.shop.order.cancelOrder(props.orderId, props.orderToken);
			if (response.status === 200) {
				removeOrderState();
				changePage(`/shop/${props.selectedStoreId}/menu`);
			}
		} catch (ex) {
			showError(ex);
		} finally {
			setCancelOrderLoading(false);
		}
	}, [props.orderId, props.orderToken, props.selectedStoreId, showError]);

	const refetchPayments = props.paymentService.refetch;
	useEffect(() => {
		if (props.show) refetchPayments();
	}, [props.show, refetchPayments]);

	return (
		<ThemeModal
			open={props.show}
			setOpen={props.setShow}
			maxWidth={"sm"}
			fullWidth={true}
			onClose={() => {
				props.paymentService.setSelectedProvider(null);
				props.paymentService.setComment("");
				props.paymentService.setPaymentsCollapseOpen(true);
			}}
			additionalCloseNode={
				<IconButton
					sx={{ ml: 1 }}
					onClick={() => {
						props.paymentService.refetch();
						// incustPayService.refetchPayData();
					}}
				>
					{props.paymentService.isLoading ? (
						<CircularProgress size={32} />
					) : (
						<CachedIcon fontSize={"large"} />
					)}
				</IconButton>
			}
			sx={{
				".MuiDialogContent-root": { p: 0 },
			}}
			title={
				<Box py={2}>
					<Typography
						variant={"h6"}
						fontWeight={"bold"}
						textAlign={"left"}
						fontSize={"large"}
					>
						{localisation.orders.payChose}
					</Typography>
					<Box width={"fit-content"}>
						<MUIPopConfirm
							node={
								<Typography variant={"body2"} textAlign={"left"} width={"auto"}>
									<Link
										sx={{
											cursor: "pointer",
											"&:hover": { color: "error.main" },
										}}
										color={"error"}
									>
										{localisation.orders.cancelOrder}
									</Link>
								</Typography>
							}
							message={<Interweave content={localisation.orders.cancelOrderMsg} />}
							cancelText={localisation.global.cancelButton}
							confirmText={localisation.global.confirmButton}
							onConfirm={cancelOrder}
						/>
					</Box>
				</Box>
			}
		>
			<Payments
				{...props}
				cancelOrderLoading={cancelOrderLoading}
				// incustPayService={incustPayService}
			/>
		</ThemeModal>
	);
}

interface IPaymentFieldsProps {
	method: PaymentMethod | null;
	setPaymentComment: (comment: string) => void;
}

function PaymentFields(props: IPaymentFieldsProps) {
	const localisation = useLocalisation();

	return (
		<>
			{!!(
				props.method &&
				(props.method.need_comment === "required" ||
					props.method.need_comment === "optional")
			) && (
				<TextField
					fullWidth
					required={props.method.need_comment === "required"}
					multiline={true}
					label={
						props.method.label_comment
							? props.method.label_comment
							: localisation.payment.commentLabel
					}
					variant={"standard"}
					onChange={e => props.setPaymentComment(e.target.value)}
				/>
			)}
		</>
	);
}

function PaymentErrorAlert({ text, setText }: { text: string; setText: (val: string) => void }) {
	return (
		<Alert
			severity={"error"}
			variant={"filled"}
			onClose={() => {
				setText("");
			}}
		>
			<Interweave content={text} />
		</Alert>
	);
}

function PaymentNoLongerAvailableAlert() {
	const localisation = useLocalisation();

	return (
		<Alert severity={"warning"} variant={"filled"} onClose={() => {
		}}>
			<Interweave content={localisation.payment.paymentNoLongerAvailable} />
		</Alert>
	);
}
