import ShopBanner from "./ShopBanner";
import { ShopInfoHeader } from "./ShopInfo";
import ShopClosedAlert from "../ShopClosedAlert";

export default function ShopBannerAndInfo() {
	return (
		<div id="shop-info-bar">
			<ShopBanner />
			<div className={"mt-1 px-2"}>
				<ShopClosedAlert />
			</div>
			<ShopInfoHeader />
		</div>
	);
}
