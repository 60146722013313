import { useCallback } from "react";
import useSearchParamsFixed from "../../../features/hooks/useSearchParamsFixed";
import { Product } from "../../../api/shop/basic/types";
import { SelectedProductTabType } from "../../menu/product/DetailedProduct";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import useAppContext from "../../../useAppContext";
import { useQueryClient } from "@tanstack/react-query";
import getGetProductQueryKey from "./getGetProductQueryKey";
import { useSetRecoilState } from "recoil";
import HideDetailedProductModal from "./HideDetailedProductModal";

export default function useSelectProduct() {
	const { brandInfo, lang } = useAppContext();
	const selectedStore = useSelectedStoreOptional();
	const [, setSearchParams] = useSearchParamsFixed();

	const setHideDetailedProductModal = useSetRecoilState(HideDetailedProductModal);

	const queryClient = useQueryClient();
	return useCallback(
		(product: Product | null, tab?: SelectedProductTabType) => {
			setSearchParams(prev => {
				const newParams = new URLSearchParams(prev.toString());
				if (product) {
					newParams.set("product_id", product.id.toString());
					if (tab) {
						newParams.set("tab", tab);
					}
				} else {
					newParams.delete("product_id");
					newParams.delete("tab");
				}
				return newParams;
			});
			if (selectedStore?.id && product) {
				queryClient.setQueryData(
					getGetProductQueryKey(selectedStore.id, product.id, brandInfo?.id, lang),
					product
				);
			}
			if (product) {
				setHideDetailedProductModal(false);
			}
		},
		[
			brandInfo?.id,
			lang,
			queryClient,
			selectedStore?.id,
			setHideDetailedProductModal,
			setSearchParams,
		]
	);
}
