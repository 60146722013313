import { useEffect, useRef } from "react";
import { Alert, Box, Button, Divider, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import { ThemeModal } from "../../../../helpers/ThemeComponents";
import { IUseProductGiftType } from "../hooks/useProductGift";
import { LoadingButton } from "@mui/lab";
import useAppContext from "../../../../useAppContext";
import { ProductGiftAwards } from "./ProductGift";
import WalletIcon from "@mui/icons-material/Wallet";
import changePage from "../../../../helpers/changePage";
import { Markup } from "interweave";
import f from "../../../../helpers/formatText";
import { Product } from "../../../../api/shop/basic/types";
import ImageStub from "../../../../features/ImageStub";
import useSelectProduct from "../../../services/useNewProductsService/useSelectProduct";
import ConsentWrapper from "../../../../auth/Autorisation/inputs/ConsentWrapper";
import AgreementCheckbox from "../../../../auth/Autorisation/inputs/AgreementCheckbox";
import MarketingCheckbox from "../../../../auth/Autorisation/inputs/MarketingCheckbox";
import { useSelectedStoreContext } from "../../../SelectedStore/context";
import ConsentInfo from "../../../../auth/Autorisation/inputs/ConsentInfo";

interface IProductGiftModal {
	gift: IUseProductGiftType;
	product: Product;
}

export default function ProductGiftModal(props: IProductGiftModal) {
	const {
		localisation: { menu },
		brandInfo,
		authService: { user },
	} = useAppContext();
	const selectProduct = useSelectProduct();
	const { selectedStore } = useSelectedStoreContext();

	const onClose = () => {
		if (props.gift.isSuccessBuy) {
			selectProduct(null);
		}
		props.gift.setShowGiftModal(false);
	};

	const isOfferEnabled = brandInfo?.is_offer_doc_exist || selectedStore.is_offer_doc_exist;

	return (
		<ThemeModal
			open={props.gift.showGiftModal}
			setOpen={props.gift.setShowGiftModal}
			title={props.gift.computedModalTitle}
			onClose={onClose}
			maxWidth={"xs"}
		>
			<Box>
				{props.gift.isSuccessBuy && (
					<>
						{props.product.image_url ? (
							<img
								width={"100%"}
								height={"auto"}
								src={props.product.image_url}
								alt={props.product.name}
							/>
						) : (
							<ImageStub ratio={[1, 1]} emptyStub={false} />
						)}
					</>
				)}

				<Box mt={props.gift.isSuccessBuy ? 2 : 0}>
					{!props.gift.isSuccessBuy && (
						<Typography>
							{props.product.price > 0 ? (
								<Markup
									tagName={"span"}
									content={f(menu.giftConfirmHeader, {
										sum: props.gift.computedBonusesSumText,
									})}
								/>
							) : (
								menu.giftConfirmZeroPriceHeader
							)}
						</Typography>
					)}

					<ProductGiftModalAlerts {...props} />
					{props.gift.isSuccessBuy && (
						<ProductGiftAwards gift={props.gift} awarded={true} />
					)}

					<Box mt={2}>
						<Divider />
						<Box py={2} fontSize={"small"}>
							<ConsentWrapper>
								<>
									<AgreementCheckbox
										value={props.gift.consent || false}
										setValue={newValue => props.gift.setConsent(newValue)}
										isOfferEnabled={isOfferEnabled}
									/>
									{!!user &&
										(!user.marketing_consent ||
											brandInfo?.consent_mode === "per_order") && (
											<MarketingCheckbox
												value={props.gift.marketingConsent}
												setValue={newValue =>
													props.gift.setMarketingConsent(newValue)
												}
											/>
										)}
								</>
							</ConsentWrapper>
							<ConsentInfo isOfferEnabled={!!brandInfo?.is_offer_doc_exist} />
						</Box>
						<Divider />
					</Box>

					<ProductGiftModalButtons onClose={onClose} {...props} />
				</Box>
			</Box>
		</ThemeModal>
	);
}

function ProductGiftModalAlerts(props: IProductGiftModal) {
	const {
		localisation: { menu },
	} = useAppContext();

	return (
		<>
			{!!props.gift.error && (
				<Alert severity={"error"} sx={{ mt: 2 }}>
					{props.gift.error}
				</Alert>
			)}

			{props.gift.isSuccessBuy && (
				<Alert severity={"success"} sx={{ mb: 2 }}>
					<Markup
						content={f(menu.giftSuccess, { sum: props.gift.computedBonusesSumText })}
					/>
				</Alert>
			)}
		</>
	);
}

interface IProductGiftModalButtons extends IProductGiftModal {
	onClose: () => void;
}

function ProductGiftModalButtons(props: IProductGiftModalButtons) {
	const {
		localisation: { global },
		brandInfo,
	} = useAppContext();

	const confirmBtnRef = useRef<HTMLButtonElement | null>(null);
	const walletBtnRef = useRef<HTMLButtonElement | null>(null);

	useEffect(() => {
		if (!!props.gift.error) confirmBtnRef.current?.scrollIntoView({ behavior: "smooth" });
		if (props.gift.isSuccessBuy) walletBtnRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [props.gift.isSuccessBuy, props.gift.error]);

	return (
		<Box width={"100%"} textAlign={"end"} sx={{ mt: 2 }}>
			<Button variant={"text"} color={"error"} sx={{ mr: 2 }} onClick={props.onClose}>
				{props.gift.isSuccessBuy ? global.close : global.cancelButton}
			</Button>

			{props.gift.isSuccessBuy ? (
				<Button
					ref={walletBtnRef}
					variant={"contained"}
					onClick={() => changePage("/shop/profile/loyalty")}
				>
					<WalletIcon sx={{ mr: 2 }} />
					{props.gift.computedButtonText}
				</Button>
			) : (
				<LoadingButton
					ref={confirmBtnRef}
					variant={"contained"}
					onClick={props.gift.handleBuy}
					startIcon={<CheckOutlinedIcon />}
					loading={props.gift.isLoading}
					loadingPosition="start"
					disabled={
						props.gift.isGiftSomethingLoading ||
						!!props.gift.processedCheck?.amount_to_pay ||
						(!props.gift.consent && brandInfo?.consent_mode !== "info")
					}
				>
					{global.confirmButton}
				</LoadingButton>
			)}
		</Box>
	);
}
