import { SxProps, Theme } from "@mui/material";
import { OutputData } from "@editorjs/editorjs";
import { AppLocalesType } from "../../../AppLocales";

export enum RichTextRendererPluginKeys {
	image = "image",
	list = "list",
	paragraph = "paragraph",
	table = "table",
	row = "row",
}

export interface JsEditorListParserItem {
	content: string;
	meta?: {
		checked?: boolean;
	};
	items?: JsEditorListParserItem[];
}

export interface RichTextRendererOptions {
	localization?: AppLocalesType["clientWebPages"];
	rendererTextContent?: string;
	rendererRicheTextEditorContent?: OutputData;
	richeTextRendererContainerStyles?: SxProps<Theme>;
	selectedPluginsRichTextRenderer?: RichTextRendererPluginKeys[];
}
