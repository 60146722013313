import { NO_CENT_CURRENCIES } from "./noCentCurrencies";

export default function formatNumber(
	sum: number | bigint | string,
	locales?: string | string[] | null,
	currency?: string | null,
	withCurrency: boolean = true
) {
	const hasFraction = (n: number | bigint) => {
		return Number(n) % 1 !== 0;
	};

	if (typeof sum === "string") {
		sum = parseFloat(sum) || 0.0;
	}

	const options: Intl.NumberFormatOptions = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	};

	if (currency) {
		if (withCurrency) {
			options.style = "currency";
			options.currency = currency;
			options.currencyDisplay = "narrowSymbol";
		}

		if (NO_CENT_CURRENCIES.has(currency) && !hasFraction(sum)) {
			options.minimumFractionDigits = 0;
			options.maximumFractionDigits = 0;
		}
	}

	const formatter = new Intl.NumberFormat(locales || undefined, options);
	return formatter.format(sum);
}
