import { MDBContainer } from "mdb-react-ui-kit";

import { useShopContext } from "../../context";
import { useSelectedStoreOptional } from "../../SelectedStore/context";
import useLocalisation from "../../../hooks/localisation/useLocalisation";
import BrandInfo from "../../BrandInfo";
import LangPicker from "../../../helpers/LangPicker";
import ShopAuthButton from "../../../auth/buttons";
import FavoritesButton from "../../favorites/FavoritesButton";
import { Box } from "@mui/material";
import BackToQrMenuButton from "../../qr_menu/BackToQrMenuButton";
import useScreenService from "../../../services/useScreenService";

export default function BaseShopHeaderDesktop({ children }: { children?: any }) {
	const { brandInfo } = useShopContext();

	const { isMobile } = useScreenService();

	const localisation = useLocalisation();

	const selectedStore = useSelectedStoreOptional();

	return (
		<>
			<MDBContainer breakpoint={"xxl"} className={"px-1 px-lg-5 m-0 mx-auto"}>
				<nav className={"w-100 mx-0 my-2"} style={{ wordBreak: "break-word" }}>
					<div className={"d-flex w-100 m-0 align-items-center"}>
						{brandInfo && <BrandInfo />}

						{/* <div className="d-flex align-items-center" style={{ marginLeft: "20px" }}>
							<ClientWebPagesDesktopBar />
						</div> */}

						<Box ml={"auto"} display={"flex"} color={"primary.main"}>
							{!isMobile && !window.location.pathname.includes("qr_menu") && (
								<Box mr={2} display={"flex"} alignItems={"center"}>
									<BackToQrMenuButton />
								</Box>
							)}

							{children && children}

							<ShopAuthButton />
							{!!selectedStore && (
								<div className={"fs-5 align-self-center me-2"}>
									<FavoritesButton />
								</div>
							)}

							<LangPicker
								searchPlaceholder={localisation.global.langSearch}
								selectHeader={localisation.global.langSelect}
							/>
						</Box>
					</div>
				</nav>
			</MDBContainer>
		</>
	);
}
