import Card from "@mui/material/Card";
import { Box, Divider, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "@mui/icons-material/Check";

import { Invoice } from "../../../api/invoice/types";
import useAppContext from "../../../useAppContext";
import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import Interweave from "../../../features/Interweave";
import InvoiceCheck from "./InvoiceCheck";
import MessangerButton from "../../../features/MessangerButton";
import { useState } from "react";

interface IInvoiceSuccessProps {
	invoice: Invoice;
	computedInvoiceImage: string;
}

export default function InvoiceSuccess(props: IInvoiceSuccessProps) {
	const {
		localisation,
		authService: { user },
	} = useAppContext();

	const [imageError, setImageError] = useState(false);

	const handleImageError = () => {
		setImageError(true);
	};

	const { data: invoiceBot } = useQuery({
		queryKey: ["bot-service-bot", props.invoice.payed_in_bot_id],
		enabled: !!props.invoice.payed_in_bot_id,
		initialData: null,
		queryFn: () => {
			if (!props.invoice.payed_in_bot_id) return null;
			return api.bot.getBot(props.invoice.payed_in_bot_id).then(response => response.data);
		},
	});

	const isInvoiceUser = !props.invoice.is_friend || props.invoice.user_id === user?.id;

	return (
		<Card>
			{!imageError && (
				<Box
					sx={{
						width: "100%",
						height: "fit-content",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor:
							"var(--variant-softBg, var(--joy-palette-neutral-softBg, var(--joy-palette-neutral-100, #F0F4F8)));",
					}}
				>
					<img
						src={props.computedInvoiceImage}
						alt={props.invoice.title}
						style={{
							maxWidth: "100%",
							height: "auto",
							maxHeight: "40vh",
							objectFit: "contain",
						}}
						onError={handleImageError}
					/>
				</Box>
			)}
			<div className={"position-relative"}>
				<div className={"rounded-icon-success-wrapper"}>
					<CheckIcon
						color={"success"}
						sx={{
							// width: "2em",
							// height: "2em",
							height: "100%",
							width: "100%",
						}}
					/>
				</div>
			</div>
			<CardContent>
				<Typography variant={"h5"} gutterBottom>
					{localisation.orders.invoiceHeader.replace(
						"{invoice_id}",
						props.invoice.id.toString()
					)}
				</Typography>

				<Typography fontWeight={"bold"}>
					<Interweave content={props.invoice.title} />
				</Typography>
				<Typography variant={"body2"} color={"text.secondary"}>
					<Interweave content={props.invoice.description || ""} />
				</Typography>

				{props.invoice.user_comment && (
					<Typography mt={2}>
						{props.invoice.user_comment_label}: {props.invoice.user_comment}
					</Typography>
				)}

				<Divider sx={{ mt: 3 }} />

				<InvoiceCheck invoice={props.invoice} />

				{isInvoiceUser &&
					invoiceBot?.id &&
					user?.messangers?.includes(invoiceBot.bot_type) && (
						<Box mt={3} textAlign={"end"}>
							<MessangerButton bot={invoiceBot} />
						</Box>
					)}
			</CardContent>
		</Card>
	);
}
