import { useMemo } from "react";
import TextField from "@mui/material/TextField";
import { Box, Button, Tooltip, Typography } from "@mui/material";

import formatCurrency from "../../../../helpers/formatCurrency";
import { TgLink } from "../../../../helpers/ThemeComponents";
import { IIncustBonusItem } from "../../types";
import useAppContext from "../../../../useAppContext";
import { getBonusesItemByCurrency } from "../../../../features/services/useIncustService/functions";
import useCurrency from "../../../../services/useCurrencyService";
import { SetState } from "../../../../types";
import Interweave from "../../../../features/Interweave";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";
import f from "../../../../helpers/formatText";
import formatNumber from "../../../../helpers/formatNumber";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface IBonusesProps {
	editBonuses: boolean;
	setEditBonuses: (value: boolean) => void;

	bonusesRedeem: number;
	bonusesRedeemInput: string;
	setBonusesRedeemInput: SetState<string>;
	computedMaxBonusesForCheck: number;
	invoice_template_id: number | null | undefined;
	currency: string | null | undefined;
}

export default function Bonuses(props: IBonusesProps) {
	const { lang, localisation, brandInfo } = useAppContext();
	const currency = useCurrency(props.currency);

	const { customerData } = useUserIncustCustomerData(null, props.invoice_template_id);

	const computedAvailableBonuses = useMemo(() => {
		if (
			customerData?.user_card &&
			customerData?.user_card.bonuses &&
			customerData?.user_card.bonuses.length > 0 &&
			currency
		) {
			const bonusesItem: IIncustBonusItem | undefined | null = getBonusesItemByCurrency(
				customerData?.user_card,
				currency
			);
			if (bonusesItem) return bonusesItem.bonuses_amount;
		}
		return 0;
	}, [currency, customerData?.user_card]);

	const computedAvailablePermanentBonuses = useMemo(() => {
		if (
			customerData?.user_card &&
			customerData?.user_card.bonuses &&
			customerData?.user_card.bonuses.length > 0 &&
			currency
		) {
			const bonusesItem: IIncustBonusItem | undefined | null = getBonusesItemByCurrency(
				customerData?.user_card,
				currency
			);
			if (bonusesItem) return bonusesItem.permanent_bonuses_amount;
		}
		return 0;
	}, [currency, customerData?.user_card]);

	const computedAvailableTemporaryBonuses = useMemo(() => {
		if (
			customerData?.user_card &&
			customerData?.user_card.bonuses &&
			customerData?.user_card.bonuses.length > 0 &&
			currency
		) {
			const bonusesItem: IIncustBonusItem | undefined | null = getBonusesItemByCurrency(
				customerData?.user_card,
				currency
			);
			if (bonusesItem) return bonusesItem.temporary_bonuses_amount;
		}
		return 0;
	}, [currency, customerData?.user_card]);

	const handleEditBonusesClick = () => {
		props.setEditBonuses(true);
		props.setBonusesRedeemInput(() => {
			if (!props.bonusesRedeem) {
				return props.computedMaxBonusesForCheck.toString();
			} else {
				return props.bonusesRedeemInput;
			}
		});
	};

	if (!currency) return null;

	return (
		<div className={"d-flex"}>
			<div>
				<TgLink onClick={handleEditBonusesClick}>
					{localisation.profile.loyaltyRedeem}
				</TgLink>
				<Box
					component={"span"}
					sx={{ ml: 1, display: "inline-flex", alignItems: "center" }}
				>
					(
					{f(localisation.profile.loyaltyMaxBonusesLabel, {
						max_bonuses: formatNumber(
							props.computedMaxBonusesForCheck,
							lang,
							currency,
							false
						),
					})}
					<Tooltip
						title={
							<Typography
								variant={"body2"}
								sx={{
									whiteSpace: "pre-wrap",
								}}
							>
								<Interweave
									content={f(localisation.profile.loyaltyBonusesTooltip, {
										temp_amount: formatCurrency(
											computedAvailableTemporaryBonuses,
											brandInfo?.default_lang || lang,
											currency
										),
										permanent_amount: formatCurrency(
											computedAvailablePermanentBonuses,
											brandInfo?.default_lang || lang,
											currency
										),
										max_bonuses: formatCurrency(
											props.computedMaxBonusesForCheck,
											brandInfo?.default_lang || lang,
											currency
										),
									})}
								/>
							</Typography>
						}
					>
						<HelpOutlineIcon
							sx={{
								ml: 1,
								fontSize: "1.2rem",
							}}
						/>
					</Tooltip>
					):
				</Box>
			</div>
			<div className={"ms-auto align-self-center"}>
				{!props.editBonuses ? (
					<strong>
						{!!computedAvailableBonuses ? (
							<TgLink className={"cursor-pointer"} onClick={handleEditBonusesClick}>
								{props.bonusesRedeemInput && props.bonusesRedeem > 0 ? "-" : ""}
								{formatCurrency(
									props.bonusesRedeem ? props.bonusesRedeem.toFixed(2) : 0,
									brandInfo?.default_lang || lang,
									currency
								)}
							</TgLink>
						) : (
							formatCurrency(
								props.bonusesRedeem ? props.bonusesRedeem.toFixed(2) : 0,
								brandInfo?.default_lang || lang,
								currency
							)
						)}
					</strong>
				) : (
					<TextField
						className={"input-padding-bottom-0"}
						value={props.bonusesRedeemInput}
						type={"number"}
						size={"small"}
						hiddenLabel
						variant="standard"
						autoFocus
						onKeyUp={e => {
							if (e.key === "Enter") {
								props.setEditBonuses(false);
							}
						}}
						InputProps={{
							endAdornment: (
								<Button
									className={"p-0"}
									onClick={() => props.setEditBonuses(false)}
								>
									OK
								</Button>
							),
							"aria-valuemin": 0,
						}}
						onChange={e => {
							props.setBonusesRedeemInput(e.target.value);
						}}
					/>
				)}
			</div>
		</div>
	);
}
