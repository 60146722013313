import { Invoice } from "../../../api/invoice/types";
import useAppContext from "../../../useAppContext";
import { styled } from "@mui/material/styles";
import { AspectRatio } from "@mui/joy";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import api from "../../../api";
import Alert from "@mui/material/Alert";
import useScreenService from "../../../services/useScreenService";
import Interweave from "../../../features/Interweave";
import InvoiceCheck from "./InvoiceCheck";
import { PaymentsModal } from "../Payment/Payments";
import useNewPaymentsService from "../hooks/useNewPaymentsService";
import { useSelectedStoreOptional } from "../../SelectedStore/context";

interface IInvoiceProps {
	invoice: Invoice;
	computedInvoiceImage: string;
	invoiceToken: string | null;
}

const InvoiceImage = styled("img")({
	borderRadius: 4,
	borderBottomLeftRadius: 0,
	borderBottomRightRadius: 0,
	width: "100%",
	height: "auto",
});

export default function InvoiceContent(props: IInvoiceProps) {
	const {
		localisation,
		authService: { user },
	} = useAppContext();
	const { isMobile } = useScreenService();
	const [invoiceUserName, setInvoiceUserName] = useState<string | null>(null);
	const [showPayments, setShowPayments] = useState(false);

	const selectedStore = useSelectedStoreOptional();
	const paymentsService = useNewPaymentsService(
		selectedStore?.id, null, props.invoice.currency, null, undefined, null,
		props.invoice.invoice_template_id,
	);

	console.log(
		"props.invoice.user_id !== props.invoice.payer_id = ",
		props.invoice.user_id,
		props.invoice.payer_id
	);

	const { makePrePayment } = paymentsService;
	const payHandler = useCallback(() => {
		if (
			(paymentsService.paymentsInfo?.single_payment_method?.is_online ||
				paymentsService.forceSingleMethod?.is_online) &&
			paymentsService.paymentsInfo?.single_payment_method?.need_comment !== "required" &&
			paymentsService.forceSingleMethod?.need_comment !== "required"
		) {
			try {
				makePrePayment(false, props.invoice.id, props.invoiceToken).then();
				return;
			} catch (err) {
				console.log(err);
			}
		}

		setShowPayments(true);
	}, [
		makePrePayment,
		paymentsService.forceSingleMethod?.is_online,
		paymentsService.forceSingleMethod?.need_comment,
		paymentsService.paymentsInfo?.single_payment_method?.is_online,
		paymentsService.paymentsInfo?.single_payment_method?.need_comment,
		props.invoice.id,
		props.invoiceToken,
	]);

	useEffect(() => {
		if (props.invoice.user_id !== props.invoice.payer_id) {
			const load = async () => {
				const response = await api.shop.friends.getFriend(props.invoice.user_id);
				console.log("response.data.name=", response.data.name);
				setInvoiceUserName(response.data.name);
			};
			load().then();
		}
	}, [props.invoice.user_id, props.invoice.payer_id]);

	const setSums = paymentsService.setSums;
	useEffect(() => {
		if (props.invoice.total_sum_with_extra_fee === 0) return;
		setSums({
			totalSum: props.invoice.total_sum_with_extra_fee,
			sumToPay: props.invoice.total_sum_with_extra_fee,
			totalSumWithExtraFee: props.invoice.total_sum_with_extra_fee,
		});
	}, [props.invoice.total_sum_with_extra_fee, setSums]);

	return (
		<>
			<AspectRatio ratio={"6/4"} objectFit={"contain"}>
				<InvoiceImage src={props.computedInvoiceImage} alt={props.invoice.title} />
			</AspectRatio>

			<Box
				sx={{
					borderRadius: 1,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					borderColor: "borderColor",
					px: 3,
					pb: 3,
					pt: 2,
				}}
			>
				<Box>
					<Typography variant={"h5"} gutterBottom>
						{localisation.orders.invoiceHeader.replace(
							"{invoice_id}",
							props.invoice.id.toString()
						)}
					</Typography>

					<Typography fontWeight={"bold"}>
						<Interweave content={props.invoice.title} />
					</Typography>
					<Typography variant={"body2"} color={"text.secondary"}>
						<Interweave content={props.invoice.description || ""} />
					</Typography>

					{props.invoice.user_comment && (
						<Typography mt={2}>
							{props.invoice.user_comment_label}: {props.invoice.user_comment}
						</Typography>
					)}
				</Box>

				<Divider sx={{ my: 2 }} />

				<InvoiceCheck invoice={props.invoice} paymentService={paymentsService} />

				{props.invoice.status !== "payed" &&
					props.invoice.user_id !== props.invoice.payer_id &&
					props.invoice.payer_id === user?.id &&
					props.invoice.invoice_type !== "INTEGRATION" && (
						<Alert severity={"warning"} sx={{ mt: 2 }}>
							{localisation.orders.payInvoiceFriendHeader.replace(
								"{name}",
								invoiceUserName || ""
							)}
						</Alert>
					)}

				{props.invoice.status !== "payed" && (
					<Box>
						<Button
							size={"large"}
							variant={"contained"}
							onClick={() => payHandler()}
							className={isMobile ? "w-100" : ""}
						>
							{localisation.orders.payButton}
						</Button>
					</Box>
				)}

				{showPayments && (
					<PaymentsModal
						show={showPayments}
						setShow={setShowPayments}
						invoiceCreated={{
							invoice: props.invoice,
							token_data: { token: props.invoiceToken || "", token_type: "bearer" },
						}}
						type={"invoice"}
						selectedStoreId={selectedStore?.id}
						paymentService={paymentsService}
						invoiceToken={props.invoiceToken}
						user={user}
						onlyOnlinePayments={true}
					/>
				)}

				{props.invoice.status === "payed" && (
					<Alert severity={"success"} sx={{ mt: 2 }}>
						{localisation.orders.payedInvoice.replace(
							"{invoice_number}",
							props.invoice.id.toString()
						)}
					</Alert>
				)}
			</Box>
		</>
	);
}
