import { OutputBlockData } from "@editorjs/editorjs";

/**
 * Custom row parser plugin for the RichTextRenderer component.
 * @param jsEditorBlockData - The block data from the EditorJS editor.
 * @returns The HTML string representation of the row.
 * @example
 * ```typescript
 * import { OutputBlockData } from "@editorjs/editorjs";
 * import { customRowParserPlugin } from "./customRowParser.plugin";
 *
 * const blockData: OutputBlockData = {
 *  id: "block-id",
 * type: "customRow",
 * data: {
 *  html: "<div>Custom row content</div>"
 * }
 * };
 * const htmlString = customRowParserPlugin(blockData);
 * console.log(htmlString);
 * // Output: <div>Custom row content</div>
 * ```
 */
export const customRowParserPlugin = (jsEditorBlockData: OutputBlockData): string => {
	return `${jsEditorBlockData.data.html}`;
};
