import { MDBContainer } from "mdb-react-ui-kit";
import { Outlet } from "react-router-dom";
import EmailAlert from "../components/alerts/EmailAlert";
import MainSidebarDrawer from "./Sidebars/MainSidebarDrawer/MainSidebarDrawer";
import BaseShopHeader from "./ShopHeader/BaseShopHeader/BaseShopHeader";
import ProfileSidebarDrawer from "./Sidebars/ProfileSidebarDrawer/ProfileSidebarDrawer";
import NoSelectedStoreShopFooter from "./ShopFooter/NoSelectedStoreShopFooter";

export default function ShopNoStoreSelectedWrapper() {
	const needEmailAlert = !window.location.pathname.includes("share_and_earn");

	return (
		<>
			<BaseShopHeader />
			<MainSidebarDrawer />
			<ProfileSidebarDrawer />
			<MDBContainer
				id={"shop-container-main"}
				breakpoint={"xxl"}
				className={"px-0 pt-2"}
				style={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
				}}
			>
				{needEmailAlert && <EmailAlert />}
				<Outlet />
			</MDBContainer>
			<NoSelectedStoreShopFooter />
		</>
	);
}
