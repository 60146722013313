import { OutputBlockData } from "@editorjs/editorjs";

/**
 * Parses a block of type "table" and converts it into an HTML table string with optional styles.
 *
 * @param block - The block data to be parsed. It should contain a type and data with content.
 * @returns The HTML string representation of the table or an empty string if the block type is not "table" or content is missing.
 *
 * @example
 * const block = {
 *   type: "table",
 *   data: {
 *     withHeadings: true,
 *     content: [
 *       ["Heading 1", "Heading 2"],
 *       ["Row 1 Col 1", "Row 1 Col 2"],
 *       ["Row 2 Col 1", "Row 2 Col 2"]
 *     ]
 *   }
 * };
 * const htmlTable = tableParserPlugin(block);
 * console.log(htmlTable);
 * Output: <table style="width: 100%; border-collapse: collapse; margin: 20px 0;"><thead><tr><th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f4f4f4;">Heading 1</th><th style="padding: 10px; border: 1px solid #ddd; text-align: left; background-color: #f4f4f4;">Heading 2</th></tr></thead><tbody><tr style="background-color: #f9f9f9;" onmouseover="this.style='background-color: #f1f1f1;'" onmouseout="this.style='background-color: #f9f9f9;'"><td style="padding: 10px; border: 1px solid #ddd; text-align: left;">Row 1 Col 1</td><td style="padding: 10px; border: 1px solid #ddd; text-align: left;">Row 1 Col 2</td></tr><tr style="background-color: #f9f9f9;" onmouseover="this.style='background-color: #f1f1f1;'" onmouseout="this.style='background-color: #f9f9f9;'"><td style="padding: 10px; border: 1px solid #ddd; text-align: left;">Row 2 Col 1</td><td style="padding: 10px; border: 1px solid #ddd; text-align: left;">Row 2 Col 2</td></tr></tbody></table>
 *
 */
export const tableParserPlugin = (block: OutputBlockData): string => {
	if (block.type !== "table" || !block.data?.content) {
		return "";
	}

	const { withHeadings, content } = block.data;

	if (!content || content.length === 0) {
		return "";
	}

	const tableStyle =
		"width: 100%; border-collapse: collapse; margin: 20px 0; table-layout: fixed;";
	const thTdStyle =
		"padding: 6px 12px; border: 1px solid #e8e8eb; text-align: left; font-size: 14px; word-wrap: break-word; word-break: break-word;";
	const thStyle = `${thTdStyle} font-weight: 600;`;

	let html = `<table style="${tableStyle}">`;

	if (withHeadings && content.length > 0) {
		html += "<thead><tr style='border-bottom: 2px solid #e8e8eb;'>";
		content[0].forEach((heading: string, index: number) => {
			html += `<th style="${thStyle} ${index === 0 ? "border-left: none" : ""} ${index === content[0]?.length - 1 ? "border-right: none" : ""}">${heading || ""}</th>`;
		});
		html += "</tr></thead>";
	}

	html += "<tbody>";
	for (let i = withHeadings ? 1 : 0; i < content.length; i++) {
		html += `<tr style="">`;
		content[i].forEach((cell: string, index: number) => {
			html += `<td style="${thTdStyle} ${index === 0 ? "border-left: none" : ""} ${index === content[i]?.length - 1 ? "border-right: none" : ""}">${cell || ""}</td>`;
		});
		html += "</tr>";
	}
	html += "</tbody>";

	html += "</table>";

	return html;
};
