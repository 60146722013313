import { IIncustCoupon, IIncustSettings } from "../../types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BrandInfo } from "../../../../api/shop/basic/types";
import { Box, Checkbox, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import useAppContext from "../../../../useAppContext";
import {
	IUseIncustServiceType,
	OnCheckProcessedCallbackType,
} from "../../../../features/services/useIncustService/types";
import { LoadingButton } from "@mui/lab";
import { SetState } from "../../../../types";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { formatCouponCode } from "../../../../helpers/formatText";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

interface ICouponModalContent {
	incustSettings: IIncustSettings;
	userIncustToken: string;
	brandInfo: BrandInfo;
	open: boolean;
	setOpen: SetState<boolean>;
	checkedCoupons: IIncustCoupon[];
	incustService: IUseIncustServiceType;
}

export default function CouponsModalContent(props: ICouponModalContent) {
	const { localisation } = useAppContext();
	const [coupons, setCoupons] = useState<IIncustCoupon[] | null>(null);
	const [checkedCoupons, setCheckedCoupons] = useState<IIncustCoupon[]>(props.checkedCoupons);
	const {
		authService: { user },
	} = useAppContext();
	const [isApplyLoading, setIsApplyLoading] = useState(false);
	const [couponsWithError, setCouponsWithError] = useState<IIncustCoupon[] | null>(null);

	const { customerData } = useUserIncustCustomerData(null, props.incustService.invoiceTemplateId);

	const userCoupons = useCallback(async () => {
		if (user && customerData?.user_card?.coupons) {
			setCoupons(customerData?.user_card?.coupons || []);
		}
	}, [customerData?.user_card?.coupons, user]);

	useEffect(() => {
		if (props.userIncustToken) {
			userCoupons().then();
		}
	}, [userCoupons, props.userIncustToken]);

	const { setOpen } = props;
	const { setOnCheckProcessedCallback } = props.incustService;
	const onProcessedCheckCallback = useCallback<OnCheckProcessedCallbackType>(
		({ couponsWithError }) => {
			setIsApplyLoading(false);
			if (couponsWithError.length) {
				setCouponsWithError(couponsWithError);
			} else {
				setOpen(false);
				setCouponsWithError(null);
			}
			setOnCheckProcessedCallback(null);
		},
		[setOnCheckProcessedCallback, setOpen]
	);

	const showUserCoupons = useMemo(() => {
		return !!(coupons && coupons?.filter(cp => cp.batch?.type === "check-modifier").length > 0);
	}, [coupons]);

	const isCouponChecked = (coupon: IIncustCoupon) => {
		if (!coupon.id) return false;
		return checkedCoupons.some(checkedCoupon => checkedCoupon.id === coupon.id);
	};

	const getSetIsCouponChecked =
		(coupon: IIncustCoupon) =>
		(event: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>) => {
			event.stopPropagation();
			event.preventDefault();
			setCheckedCoupons(prev => {
				const isCouponChecked = prev.some(checkedCoupon => checkedCoupon.id === coupon.id);
				console.log("isCouponChecked", isCouponChecked);
				return isCouponChecked
					? prev.filter(checkedCoupon => checkedCoupon.id !== coupon.id)
					: [...prev, coupon];
			});

			setCouponsWithError(prev => {
				if (!prev?.length) return prev;
				return prev.filter(couponWithError => couponWithError.id !== coupon.id);
			});
		};

	const isCouponWithError = (coupon: IIncustCoupon) => {
		if (!isCouponChecked(coupon)) return false;
		if (!coupon.id || !couponsWithError?.length) return false;
		return couponsWithError.some(couponWithError => couponWithError.id === coupon.id);
	};

	const getCouponShadows = (coupon: IIncustCoupon, index: number) => {
		const errorOrPrimary = isCouponWithError(coupon) ? "error" : "primary";

		const borderBottom = index + 1 === coupons?.length ? 0 : "-1";

		return {
			...(isCouponChecked(coupon)
				? {
						boxShadow:
							`inset 2px 0px 0px 0px var(--mui-palette-${errorOrPrimary}-main), ` +
							`inset -2px 0px 0px 0px var(--mui-palette-${errorOrPrimary}-main), ` +
							`inset 0px ${borderBottom}px 0px 0px var(--mui-palette-divider)`,
					}
				: {
						boxShadow: `inset 0px ${borderBottom}px 0px 0px var(--mui-palette-divider)`,
						"&:hover": {
							boxShadow:
								`inset 2px 0px 0px 0px rgba(var(--mui-palette-${errorOrPrimary}-mainChannel) / 0.5), ` +
								`inset -2px 0px 0px 0px rgba(var(--mui-palette-${errorOrPrimary}-mainChannel) / 0.5), ` +
								`inset 0px ${borderBottom}px 0px 0px var(--mui-palette-divider)`,
						},
					}),
		};
	};

	return (
		<>
			{showUserCoupons && (
				<Box sx={{ userSelect: "none" }}>
					<Typography
						variant={"h6"}
						fontSize={"medium"}
						borderBottom={1}
						borderColor={"divider"}
						px={3}
						pt={2}
						pb={1}
					>
						{localisation.profile.loyaltyUsersCoupons}
					</Typography>

					{coupons?.map(
						(coupon, index) =>
							coupon.batch?.type === "check-modifier" && (
								<Box
									key={coupon.id}
									display="flex"
									justifyContent="space-between"
									py={2}
									pl={3}
									pr={1}
									gap={2}
									width="100%"
									onClick={getSetIsCouponChecked(coupon)}
									sx={{
										...getCouponShadows(coupon, index),
									}}
								>
									<Box display="flex" gap={2} alignItems="center">
										<Avatar
											src={coupon.image || undefined}
											alt={coupon.title || ""}
											sx={{
												width: 56,
												height: 56,
												borderRadius: "0%",
												bgcolor:
													"rgba(var(--mui-palette-primary-mainChannel) / 0.8)",
											}}
										>
											{!coupon.image && <CardGiftcardIcon />}
										</Avatar>
										<Box>
											<Typography
												sx={{ fontSize: "1.3rem", fontWeight: 400 }}
											>
												{formatCouponCode(coupon.code || "")}
											</Typography>
											{coupon.title}
										</Box>
									</Box>
									<Checkbox
										id={coupon.id || ""}
										color={isCouponWithError(coupon) ? "error" : "primary"}
										onChange={getSetIsCouponChecked(coupon)}
										checked={isCouponChecked(coupon)}
									/>
								</Box>
							)
					)}
				</Box>
			)}

			<Box
				display={"flex"}
				py={2}
				px={3}
				gap={2}
				justifyContent={"flex-end"}
				sx={{
					position: "sticky",
					bottom: 0,
					right: 20,
					backgroundColor: "background.paper",
					zIndex: 1,
				}}
			>
				{showUserCoupons && (
					<LoadingButton
						variant={"contained"}
						loading={isApplyLoading}
						onClick={() => {
							props.incustService.setUsedCoupons(checkedCoupons);
							setIsApplyLoading(true);
							setOnCheckProcessedCallback(onProcessedCheckCallback);
						}}
					>
						{localisation.profile.loyaltyApplyCoupons}
					</LoadingButton>
				)}
			</Box>
		</>
	);
}
