import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface ISidebarButtonProps {
	text: string;
	action: () => void;
	icon: ReactNode;
}

export default function SidebarButton(props: ISidebarButtonProps) {
	return (
		<div className={"d-flex pb-2 cursor-pointer"} onClick={props.action}>
			<div>
				<Typography variant={"h6"} className={"m-0 theme-text"}>
					{props.text}
				</Typography>
			</div>
			<div className="ms-auto theme-text align-self-center">{props.icon}</div>
		</div>
	);
}
