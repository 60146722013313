import { marked } from "marked";
import { OutputBlockData } from "@editorjs/editorjs";

/**
 * Parses a given block of text from the Editor.js output data and converts it into HTML.
 * The function handles HTML decoding, HTML validation, Markdown parsing, and text sanitization.
 *
 * @param jsEditorBlockData - The block data from Editor.js containing the text to be parsed.
 * @returns The parsed HTML string.
 *
 * @example
 * const blockData = {
 *   type: "paragraph",
 *   data: {
 *     text: "This is a **bold** statement."
 *   }
 * };
 * const html = paragraphParserPlugin(blockData);
 * console.log(html); // <div><p>This is a <strong>bold</strong> statement.</p></div>
 */
export const paragraphParserPlugin = (jsEditorBlockData: OutputBlockData): string => {
	try {
		const { text } = jsEditorBlockData.data;

		const decodeHTML = (html: string): string => {
			const txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;
		};

		const isValidHTML = (html: string): boolean => {
			const doc = new DOMParser().parseFromString(html, "text/html");

			if (doc.body.children.length === 0) return false;

			return true;
		};

		const sanitizeText = (html: string): string => {
			html = html.replace(/<\/?\s*[a-z][^>]*?>/gi, "");

			html = html.replace(/<[^>\s]*$/gi, "");

			return html;
		};

		const parseMarkdown = (markdown: string): string => {
			console.log({ markdown });
			const res = marked(markdown);
			if (typeof res === "string") {
				return res;
			} else {
				return markdown;
			}
		};

		const decodedText = decodeHTML(text);

		if (isValidHTML(decodedText)) {
			console.log({ html: decodedText });
			return `<div>${decodedText}</div>`;
		}

		const isMarkdown = (text: string): boolean => {
			const markdownPatterns = [
				/^#{1,6}\s/,
				/(\*\*|__)(.*?)\1/,
				/(\*|_)(.*?)\1/,
				/`([^`]+)`/,
				/^>\s/,
				/-\s|\*\s|\+\s/,
				/\d+\.\s/,
			];

			return markdownPatterns.some(pattern => pattern.test(text));
		};

		if (isMarkdown(decodedText)) {
			console.log({ markdown: decodedText });
			const markdownHTML = parseMarkdown(decodedText);
			return `<div>${markdownHTML}</div>`;
		}

		const cleanText = sanitizeText(decodedText);

		console.log({ text: cleanText });

		return `<p>${cleanText}</p>`;
	} catch (error) {
		console.error("Error parsing paragraph block", error);
		return "<div></div>";
	}
};
