import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApplyCouponType, IApplyCouponInfo, IIncustCouponExt } from "../../types";
import useAppContext from "../../../../useAppContext";
import api from "../../../../api";
import f from "../../../../helpers/formatText";
import { AnimatePresence } from "framer-motion";
import changePage from "../../../../helpers/changePage";
import { CouponDesktopFrontSide } from "./desktop/DesktopFrontSide";
import { CouponDesktopBackSide } from "./desktop/DesktopBackSide";
import { Theme, useMediaQuery } from "@mui/material";
import { MobileFrontSide } from "./mobile/MobileFrontSide";
import { MobileBackSide } from "./mobile/MobileBackSide";
import { useMutation } from "@tanstack/react-query";
import { getErrorText } from "../../../../helpers/errors";
import { useSelectedStoreOptional } from "../../../SelectedStore/context";
import useUserIncustCustomerData from "../../../../hooks/incust/useUserIncustCustmerData";

const AnimatePresenceFixedType = AnimatePresence as React.ElementType;

interface IShowCouponProps {
	couponExt: IIncustCouponExt;
	setCouponExt: (couponExt: IIncustCouponExt) => unknown;
	needAddToWallet?: boolean | null;
	showOnPage?: boolean | null;
	setShowAuthModal: (value: boolean) => void;
	invoice_template_id?: number | null | undefined;
}

export function CouponContent({
	couponExt: { coupon, is_in_wallet: isInWallet, transaction: redeemCouponCheck },
	needAddToWallet,
	setCouponExt,
	showOnPage,
	setShowAuthModal,
	invoice_template_id,
}: IShowCouponProps) {
	const { customerData } = useUserIncustCustomerData(null, invoice_template_id);
	const store = useSelectedStoreOptional();
	const contentRef = useRef<HTMLDivElement | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);

	const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);

	const [firstRenderCoupon, setFirstRenderCoupon] = useState(true);

	const {
		localisation: { profile, global },
		brandInfo,
		lang,
		authService: { user },
		bot,
		showError,
	} = useAppContext();

	const shareMutation = useMutation({
		mutationFn: async () => {
			if (
				!brandInfo?.id ||
				!coupon.id ||
				!coupon.batch?.share_allowed ||
				(coupon.expire_date && new Date().toISOString().split("T")[0] < coupon.expire_date)
			) {
				throw Error(profile.loyaltyCouponIsExpired);
			}
			const { data } = await api.shop.loyalty.shareCoupon(brandInfo.id, coupon.id);
			return data;
		},
		onError: (error: any) => {
			if (error?.response?.status === 404) {
				showError(profile.couponNotFound);
			} else {
				showError(getErrorText(error, global.errUnknown, true));
			}
		},
	});

	const shareCode = shareMutation.data?.message || null;
	const openShareCode = !!shareCode;
	const closeShareModal = shareMutation.reset;
	const isShareLoading = shareMutation.isPending;

	const addCouponToWalletMutation = useMutation({
		mutationFn: async () => {
			if (!brandInfo?.id || !coupon.code) throw Error;

			const { data } = await api.shop.loyalty.processCoupon(
				coupon.id ? coupon.id : coupon.code,
				store?.id
			);
			return data;
		},
		onSuccess: data => {
			console.log("Coupon data:", data);
			if (data) {
				setCouponExt(data);
			}
		},
		onError: (error: any) => {
			if (error.response?.status === 404) {
				showError(profile.couponNotFound);
			} else {
				showError(getErrorText(error, global.errUnknown));
			}
		},
	});

	const isAddCouponToWalletLoading = addCouponToWalletMutation.isPending;
	const isApplyCouponError = addCouponToWalletMutation.isError;
	const addCouponToWallet = addCouponToWalletMutation.mutate;

	const handleApplyCoupon = useCallback(() => {
		if (!customerData?.token) {
			setShowAuthModal(true);
		} else {
			setShowAuthModal(false);
			addCouponToWallet();
		}
	}, [addCouponToWallet, customerData?.token]);

	// for add coupon page case...
	useEffect(() => {
		if (
			needAddToWallet &&
			customerData &&
			!coupon.redeem_dt &&
			!coupon.redeem_at_terminal &&
			!addCouponToWalletMutation.isPending &&
			!addCouponToWalletMutation.isSuccess &&
			!addCouponToWalletMutation.isError &&
			!isInWallet
		) {
			addCouponToWallet();
		}
	}, [
		addCouponToWallet,
		needAddToWallet,
		isInWallet,
		coupon.redeem_at_terminal,
		coupon.redeem_dt,
		addCouponToWalletMutation.isPending,
		addCouponToWalletMutation.isSuccess,
		addCouponToWalletMutation.isError,
		customerData,
	]);

	const handleDownload = async () => {
		try {
			setIsLoadingPdf(true);

			const invoice_pdf_url = brandInfo?.incust_data?.server_api?.replace(
				"api.",
				"mf-invoices."
			);
			const apiURL = `${invoice_pdf_url}/api/generate_coupon/${
				coupon.id ? coupon.id : coupon.code
			}?lang=${lang}`;

			const response = await fetch(apiURL);
			if (!response.ok) {
				throw new Error(profile.ErrorDownloadFile + `: ${coupon.code}.pdf`);
			}
			const data = await response.json();
			const base64String = data.data;

			const downloadPdf = (base64String: string, fileName: string) => {
				const linkSource = `data:application/pdf;base64,${base64String}`;
				const downloadLink = document.createElement("a");
				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			};

			downloadPdf(base64String, `${coupon.code}.pdf`);
		} catch (error) {
			console.error(`Error load PDF file: ${coupon.code}`, error);
			showError(profile.ErrorDownloadFile + `: ${coupon.code}.pdf`);
		} finally {
			setIsLoadingPdf(false);
		}
	};

	const qrBotUrl = useMemo(() => {
		if (bot?.bot_type === "telegram" && user?.messangers?.includes(bot.bot_type)) {
			return `https://t.me/${bot.username}?start=coupon-${shareCode}`;
		}

		if (bot?.bot_type === "whatsapp" && user?.messangers?.includes(bot.bot_type)) {
			return `https://wa.me/${bot.whatsapp_from_phone_number}?text=coupon-${shareCode}`;
		}

		return null;
	}, [bot, user, shareCode]);

	const qrWebUrl = useMemo(() => {
		return `${window.location.origin}/shop/coupon/show/${shareCode}`;
	}, [shareCode]);

	const couponBenefitAmount = useMemo(() => {
		if (
			["regular-bonuses", "promotional-bonuses", "bonuses"].includes(
				coupon.recommendation_fee_type || ""
			)
		) {
			return f(profile.shareCouponFeeBonuses, {
				recommendation_fee_amount: coupon.recommendation_fee_amount,
			});
		}
		if (coupon.recommendation_fee_type === "coupon") {
			return `${coupon.recommendation_fee_coupon_batch_title} ${
				coupon.recommendation_fee_amount ? ": " + coupon.recommendation_fee_amount : ""
			}`;
		}
		if (coupon.recommendation_fee_type === "special-account") {
			return `${coupon.recommendation_fee_special_account_title}: ${coupon.recommendation_fee_amount}`;
		}
	}, [coupon, profile.shareCouponFeeBonuses]);

	const [isInsideCoupon, setIsInsideCoupon] = useState<boolean>(false);

	const isShowApplyButton = useMemo(() => {
		if (coupon.redeem_dt) return false;
		return !!(
			coupon.type === "certificate" ||
			(coupon.type === "external" &&
				!coupon.redeem_at_terminal &&
				coupon.external_code_visibility_type !== "always-open") ||
			(coupon.type === "check-modifier" && !user) ||
			(coupon.type === "check-modifier" && user && !isInWallet)
		);
	}, [
		coupon.external_code_visibility_type,
		coupon.redeem_at_terminal,
		coupon.redeem_dt,
		coupon.type,
		isInWallet,
		user,
	]);

	const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

	const applyCouponInfo = useMemo(() => {
		const applyCouponInfo = (data: IIncustCouponExt | undefined): IApplyCouponInfo | null => {
			if (!addCouponToWalletMutation.isSuccess) return null;

			if (data?.coupon.status === "redeemed") {
				return {
					show: true,
					title: null,
					type: ApplyCouponType.SUCCESS,
				};
			} else if (data?.is_in_wallet && data.message && data?.coupon.status !== "redeemed") {
				return {
					show: true,
					title: data.message,
					type: ApplyCouponType.USED,
				};
			}

			return {
				show: false,
				title: null,
				type: ApplyCouponType.SUCCESS,
			};
		};

		return applyCouponInfo(addCouponToWalletMutation.data);
	}, [addCouponToWalletMutation.data, addCouponToWalletMutation.isSuccess]);

	return (
		<>
			{!isTablet && (
				<AnimatePresenceFixedType mode="wait">
					{!isInsideCoupon && (
						<MobileFrontSide
							key={"mobile-front-side"}
							coupon={coupon}
							isShowApplyButton={isShowApplyButton}
							insideCoupon={isInsideCoupon}
							isApplyCouponError={isApplyCouponError}
							isAddCouponToWalletLoading={isAddCouponToWalletLoading}
							handlePdf={handleDownload}
							handleApply={handleApplyCoupon}
							handleWallet={() => changePage("/shop/profile/loyalty")}
							handleIsInside={() => setIsInsideCoupon(true)}
							couponBenefitAmount={couponBenefitAmount || ""}
							handleShare={shareMutation.mutate}
							shareCode={shareCode}
							openShareCode={openShareCode}
							closeShareModal={closeShareModal}
							isShareLoading={isShareLoading}
							botUrl={qrBotUrl}
							webUrl={qrWebUrl}
							isInWallet={isInWallet}
							applyCouponInfo={applyCouponInfo}
							redeemedCouponCheck={redeemCouponCheck}
							onCloseApplyPopup={addCouponToWalletMutation.reset}
						/>
					)}

					{isInsideCoupon && (
						<MobileBackSide
							key={"mobile-back-side"}
							coupon={coupon}
							isInWallet={isInWallet}
							insideCoupon={isInsideCoupon}
							isApplyCouponError={isApplyCouponError}
							handleIsInside={() => setIsInsideCoupon(false)}
							handleDownload={handleDownload}
							isShowApplyButton={isShowApplyButton}
							isAddCouponToWalletLoading={isAddCouponToWalletLoading}
							handleApply={handleApplyCoupon}
							handleWallet={() => changePage("/shop/profile/loyalty")}
							applyCouponInfo={applyCouponInfo}
							onCloseApplyPopup={addCouponToWalletMutation.reset}
							redeemedCouponCheck={redeemCouponCheck}
						/>
					)}
				</AnimatePresenceFixedType>
			)}

			{isTablet && (
				<AnimatePresenceFixedType mode="wait">
					{!isInsideCoupon && (
						<CouponDesktopFrontSide
							key={"desktop-front-side"}
							showOnPage={showOnPage}
							containerRef={containerRef}
							contentRef={contentRef}
							insideCoupon={isInsideCoupon}
							coupon={coupon}
							handleIsInside={() => setIsInsideCoupon(true)}
							handleDownload={handleDownload}
							disabled={isLoadingPdf}
							handlePdf={handleDownload}
							isInWallet={isInWallet}
							handleApply={handleApplyCoupon}
							isAddCouponToWalletLoading={isAddCouponToWalletLoading}
							isApplyCouponError={isApplyCouponError}
							redeemedCouponCheck={redeemCouponCheck}
							handleWallet={() => changePage("/shop/profile/loyalty")}
							couponBenefitAmount={couponBenefitAmount || ""}
							handleShare={shareMutation.mutate}
							shareCode={shareCode}
							openShareCode={openShareCode}
							closeShareModal={closeShareModal}
							isShareLoading={isShareLoading}
							botUrl={qrBotUrl}
							webUrl={qrWebUrl}
							isShowApplyButton={isShowApplyButton}
							applyCouponInfo={applyCouponInfo}
							onCloseApplyPopup={addCouponToWalletMutation.reset}
							firstRender={firstRenderCoupon}
							setFirstRender={setFirstRenderCoupon}
						/>
					)}

					{isInsideCoupon && (
						<CouponDesktopBackSide
							key={"desktop-back-side"}
							showOnPage={showOnPage}
							setIsInsideCoupon={() => setIsInsideCoupon((prev: boolean) => !prev)}
							containerRef={containerRef}
							contentRef={contentRef}
							insideCoupon={isInsideCoupon}
							coupon={coupon}
							setIsInsideCouponTrue={() => setIsInsideCoupon(true)}
							handleDownload={handleDownload}
							isLoadingPdf={isLoadingPdf}
							isInWallet={isInWallet}
							handleApplyCoupon={handleApplyCoupon}
							isAddCouponToWalletLoading={isAddCouponToWalletLoading}
							isShowApplyButton={isShowApplyButton}
							applyCouponInfo={applyCouponInfo}
							redeemedCouponCheck={redeemCouponCheck}
							onCloseApplyPopup={addCouponToWalletMutation.reset}
						/>
					)}
				</AnimatePresenceFixedType>
			)}
		</>
	) as JSX.Element;
}
