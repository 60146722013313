import { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

import useAppContext from "../../../../useAppContext";
import useCurrency from "../../../../services/useCurrencyService";
import useIncustService from "../../../services/useIncustService";
import { NonModalDialogPage } from "../../../NonModalDialog";
import CollapsableComponent from "../../../CollapsableComponent";
import useScreenService from "../../../../services/useScreenService";
import Interweave from "../../../Interweave";
import ImageStub from "../../../ImageStub";
import { useSelectedStoreContextOptional } from "../../../../shop/SelectedStore/context";

export default function ScanReceiptLandingPage() {
	const {
		scanReceiptService,
		brandInfo,
		lang,
		loadingManager,
		localisation: { receipt },
	} = useAppContext();
	const { isMobile } = useScreenService();

	const store = useSelectedStoreContextOptional();

	const currency = useCurrency();
	const incustService = useIncustService(
		brandInfo?.id,
		store.selectedStore?.id, null,
		brandInfo?.incust_data,
		lang,
		currency || ""
	);

	useEffect(() => {
		if (!incustService.settings && incustService.internalLoading) {
			loadingManager.setIsLoading("receipt-landing", true);
		} else {
			loadingManager.setIsLoading("receipt-landing", false);
		}
	}, [incustService.internalLoading, incustService.settings, loadingManager]);

	if (!brandInfo?.loyalty_info?.loyalty_enabled && !incustService.internalLoading) {
		return (
			<Typography color={"error"} variant={"h5"} fontWeight={"bold"} textAlign={"center"}>
				{receipt.scanReceiptNotConnectedLoyalty}
			</Typography>
		);
	}
	if (!brandInfo?.scan_receipts_settings?.enabled) {
		return (
			<Typography color={"error"} variant={"h5"} fontWeight={"bold"} textAlign={"center"}>
				{receipt.scanReceiptDisabled}
			</Typography>
		);
	}
	if (!incustService.settings) return <></>;

	return (
		<NonModalDialogPage
			containerProps={{
				maxWidth: "sm",
				sx: {
					mt: 2,
				},
			}}
			header={
				<Box textAlign={"start"} px={3}>
					<Box>{incustService.settings.loyalty.title}</Box>
				</Box>
			}
		>
			{!!incustService.settings.loyalty.photos?.length ? (
				<img
					width={"100%"}
					height={"auto"}
					src={incustService.settings.loyalty.photos[0]}
					alt={incustService.settings.loyalty.title || ""}
				/>
			) : (
				<ImageStub ratio={[1, 1]} emptyStub={false} />
			)}
			<Box p={3}>
				<CollapsableComponent linesCount={isMobile ? 3 : 5} sx={{ width: "100%" }}>
					<Typography>
						<Interweave
							content={incustService.settings.loyalty.public_description?.replaceAll(
								"\n",
								"<br/>"
							)}
						/>
					</Typography>
				</CollapsableComponent>

				<Button
					variant={"contained"}
					fullWidth
					onClick={() => scanReceiptService.setShowModal(true)}
					sx={{ mt: 2 }}
				>
					{receipt.scanButton}
				</Button>
			</Box>
		</NonModalDialogPage>
	);
}
