import { ReactNode, useCallback, useMemo } from "react";
import { MDBListGroup, MDBListGroupItem, MDBTypography } from "mdb-react-ui-kit";
import { Link, useLocation } from "react-router-dom";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Box } from "@mui/material";

import { LogoutButton } from "../../auth/buttons";
import { useShopContext } from "../context";
import StickyComponent from "../../features/StickyComponent";
import { ProfileTab } from "./types";
import { useSelectedStoreOptional } from "../SelectedStore/context";
import changePage from "../../helpers/changePage";
import useAppContext from "../../useAppContext";
import useCurrency from "../../services/useCurrencyService";
import useIncustService from "../../features/services/useIncustService";
import { TgLink } from "../../helpers/ThemeComponents";
import useUserIncustCustomerData from "../../hooks/incust/useUserIncustCustmerData";
import api from "../../api";

interface IProfileSidebarTabsProps {
	isMobileSidebar?: boolean;
	needLogout?: boolean;
}

interface IProfileSidebarWrapper extends IProfileSidebarTabsProps {
	withWrapper: boolean;
}

interface IProfileSidebarStickyWrapperProps {
	children: ReactNode;
}

export default function ProfileSidebar(props: IProfileSidebarWrapper) {
	return props.withWrapper ? (
		<ProfileSidebarStickyWrapper>
			<ProfileTabs isMobileSidebar={props.isMobileSidebar} needLogout={props.needLogout} />
		</ProfileSidebarStickyWrapper>
	) : (
		<ProfileTabs isMobileSidebar={props.isMobileSidebar} />
	);
}

function ProfileSidebarStickyWrapper(props: IProfileSidebarStickyWrapperProps) {
	return (
		<div className={"d-flex flex-column h-100 border-end ps-1 pe-3 me-3"}>
			<StickyComponent wrapperClasses={"flex-1 mb-2 d-none d-md-block"}>
				<div
					className={"scrollbar-thin"}
					style={{
						maxHeight: "calc(100vh - 100px)",
						overflowX: "hidden",
						overflowY: "auto",
					}}
					id={"profile-tabs-container"}
				></div>
				{props.children}
			</StickyComponent>
		</div>
	);
}

function ProfileTabs(props: IProfileSidebarTabsProps) {
	const location = useLocation();
	const {
		authService: { user, loadUser },
		localisation,
		profileService,
		sidebarsService,
		groupService,
		lang,
		scanReceiptService,
		toastService,
	} = useAppContext();
	const { webAppData, brandInfo } = useShopContext();
	const selectedStore = useSelectedStoreOptional();

	const { customerData } = useUserIncustCustomerData();

	const currency = useCurrency();
	const incustService = useIncustService(
		brandInfo?.id,
		selectedStore?.id, null,
		brandInfo?.incust_data,
		lang,
		currency || ""
	);

	const isProfilePage = location.pathname.includes("/profile");

	const handleChangeTab = (tab: ProfileTab) => {
		try {
			if (tab === ProfileTab.overall) {
				if (selectedStore) {
					return changePage(`/shop/${selectedStore.id}/profile`);
				}
				return changePage(`/shop/profile`);
			}
			if (selectedStore) {
				return changePage(`/shop/${selectedStore.id}/profile/${String(tab)}`);
			}
			return changePage(`/shop/profile/${String(tab)}`);
		} finally {
			sidebarsService.setIsProfileSidebarOpen(false);
		}
	};

	const computedLinkBase = useMemo(() => {
		if (selectedStore) {
			return `/shop/${selectedStore.id}/profile`;
		}
		return `/shop/profile`;
	}, [selectedStore]);

	const handleScan = () => {
		scanReceiptService.setShowModal(true);
		sidebarsService.setIsProfileSidebarOpen(false);
		toastService.showToast({
			severity: "success",
			message: localisation.auth.marketingDeclineMessage,
			autoHideDuration: 10000,
		});
	};

	const handleDeclineMarketing = useCallback(async () => {
		const payload = { marketing_consent: false };
		await api.auth.updateMarketingConsent(payload);
		await loadUser();
	}, [loadUser]);

	return (
		<MDBListGroup
			light
			className={"theme-text bg-transparent shop-categories"}
			style={props.isMobileSidebar ? { borderRadius: "0" } : {}}
		>
			{brandInfo.loyalty_info?.loyalty_enabled &&
				incustService.loyaltyReferralSettings?.referral_program?.active && (
					<MDBListGroupItem
						onClick={() => handleChangeTab(ProfileTab.shareAndEarn)}
						noBorders
						active={
							profileService.activeTab === ProfileTab.shareAndEarn && isProfilePage
						}
						className="py-1 d-flex cursor-pointer theme-text bg-transparent tg-list-group-item"
						style={props.isMobileSidebar ? { borderRadius: "0" } : {}}
					>
						<Link
							to={`${computedLinkBase}/share_and_earn`}
							className={"react-list-link theme-text px-3"}
						>
							<MDBTypography className="mb-0 me-auto">
								{groupService.group?.texts.web.loyalty.referral_system
									.info_button || localisation.profile.shareAndEarn}
							</MDBTypography>
						</Link>
					</MDBListGroupItem>
				)}
			<MDBListGroupItem
				onClick={() => handleChangeTab(ProfileTab.overall)}
				noBorders
				active={
					(profileService.activeTab === ProfileTab.overall ||
						!profileService.activeTab) &&
					isProfilePage
				}
				className="py-1 d-flex cursor-pointer theme-text bg-transparent tg-list-group-item"
				style={props.isMobileSidebar ? { borderRadius: "0" } : {}}
			>
				<Link to={`${computedLinkBase}`} className={"react-list-link theme-text px-3"}>
					<MDBTypography className="mb-0 me-auto">
						{localisation.profile.accountHeader}
					</MDBTypography>
				</Link>
			</MDBListGroupItem>

			{!!(user && brandInfo.stores_count > 0) && (
				<MDBListGroupItem
					onClick={() => handleChangeTab(ProfileTab.orders)}
					noBorders
					active={profileService.activeTab === ProfileTab.orders && isProfilePage}
					className="py-1 d-flex cursor-pointer theme-text bg-transparent tg-list-group-item"
					style={props.isMobileSidebar ? { borderRadius: "0" } : {}}
				>
					<Link
						to={`${computedLinkBase}/orders`}
						className={"react-list-link theme-text px-3"}
					>
						<MDBTypography className="mb-0 me-auto">
							{localisation.orders.myOrders}
						</MDBTypography>
					</Link>
				</MDBListGroupItem>
			)}

			{brandInfo.stores_count > 0 && (
				<MDBListGroupItem
					onClick={() => handleChangeTab(ProfileTab.favorites)}
					noBorders
					active={profileService.activeTab === ProfileTab.favorites && isProfilePage}
					className="py-1 d-flex cursor-pointer theme-text bg-transparent tg-list-group-item"
					style={props.isMobileSidebar ? { borderRadius: "0" } : {}}
				>
					<Link
						to={`${computedLinkBase}/favorites`}
						className={"react-list-link theme-text px-3"}
					>
						<MDBTypography className="mb-0 me-auto">
							{localisation.profile.favoritesHeader}
						</MDBTypography>
					</Link>
				</MDBListGroupItem>
			)}

			{!!(brandInfo.loyalty_info?.loyalty_enabled && user) && (
				<>
					<MDBListGroupItem
						onClick={() => handleChangeTab(ProfileTab.loyalty)}
						noBorders
						active={profileService.activeTab === ProfileTab.loyalty && isProfilePage}
						className="py-1 d-flex cursor-pointer theme-text bg-transparent tg-list-group-item"
						style={props.isMobileSidebar ? { borderRadius: "0" } : {}}
					>
						<Link
							to={`${computedLinkBase}/loyalty`}
							className={"react-list-link theme-text px-3"}
						>
							<MDBTypography className="mb-0 me-auto">
								{localisation.profile.hiddenIncustWalletHeader}
							</MDBTypography>
						</Link>
					</MDBListGroupItem>

					{customerData?.token && (
						<MDBListGroupItem
							onClick={() => handleChangeTab(ProfileTab.myCard)}
							noBorders
							active={profileService.activeTab === ProfileTab.myCard && isProfilePage}
							className="py-1 d-flex cursor-pointer theme-text bg-transparent tg-list-group-item"
							style={props.isMobileSidebar ? { borderRadius: "0" } : {}}
						>
							<Link
								to={`${computedLinkBase}/loyalty_card`}
								className={"react-list-link theme-text px-3"}
							>
								<MDBTypography className="mb-0 me-auto">
									{localisation.profile.myCard}
								</MDBTypography>
							</Link>
						</MDBListGroupItem>
					)}
				</>
			)}

			{brandInfo?.is_friend_payment && (
				<MDBListGroupItem
					onClick={() => handleChangeTab(ProfileTab.friends)}
					noBorders
					active={profileService.activeTab === ProfileTab.friends && isProfilePage}
					className="py-1 d-flex cursor-pointer theme-text bg-transparent tg-list-group-item"
					style={props.isMobileSidebar ? { borderRadius: "0" } : {}}
				>
					<Link
						to={`${computedLinkBase}/friends`}
						className={"react-list-link theme-text px-3"}
					>
						<MDBTypography className="mb-0 me-auto">
							{localisation.profile.friendsHeader}
						</MDBTypography>
					</Link>
				</MDBListGroupItem>
			)}

			{!!user && !!user.marketing_consent && (
				<Box sx={{ ml: 3, mt: 2 }}>
					<TgLink onClick={handleDeclineMarketing}>
						{localisation.auth.marketingDecline}
					</TgLink>
				</Box>
			)}

			{brandInfo.scan_receipts_settings?.enabled && (
				<Box sx={{ ml: 3, mt: 2 }}>
					<TgLink onClick={handleScan}>
						<QrCodeScannerIcon fontSize={"inherit"} sx={{ mr: 2 }} />
						{localisation.receipt.scanButton}
					</TgLink>
				</Box>
			)}

			{!webAppData && user && props.needLogout && (
				<div className={"cursor-pointer p-3 mt-2 border-top sidebar-border"}>
					<LogoutButton
						text={localisation.profile.logout}
						onClosePopConfirm={() => {
							if (isProfilePage) handleChangeTab(ProfileTab.overall);
							sidebarsService.setIsProfileSidebarOpen(false);
						}}
					/>
				</div>
			)}
		</MDBListGroup>
	);
}
