import React from "react";
import {
	customRowParserPlugin,
	imageParserPlugin,
	listParserPlugin,
	paragraphParserPlugin,
	tableParserPlugin,
} from "../plugins";
import { RichTextRendererPluginKeys } from "../types";

const allRichTextRendererPlugins = {
	[RichTextRendererPluginKeys.image]: imageParserPlugin,
	[RichTextRendererPluginKeys.list]: listParserPlugin,
	[RichTextRendererPluginKeys.paragraph]: paragraphParserPlugin,
	[RichTextRendererPluginKeys.row]: customRowParserPlugin,
	[RichTextRendererPluginKeys.table]: tableParserPlugin,
};

export const useRichTextRendererPlugins = (selectedPlugins?: RichTextRendererPluginKeys[]) => {
	return React.useMemo(() => {
		if (!selectedPlugins?.length) {
			return allRichTextRendererPlugins;
		}

		return selectedPlugins.reduce(
			(acc, key) => {
				if (allRichTextRendererPlugins[key]) {
					acc[key] = allRichTextRendererPlugins[key];
				}

				return acc;
			},
			{} as typeof allRichTextRendererPlugins
		);
	}, [selectedPlugins]);
};
