import { Outlet } from "react-router-dom";

import PageTitle from "./helpers/PageTitle";
import BlurFilter from "./features/BlurFilter";
import { Backdrop, CircularProgress } from "@mui/material";
import GroupColorSchemaProvider from "./theme/GroupColorSchemaProvider";
import AppContextProvider from "./AppContextProvider";
import useAppContext from "./useAppContext";
import { useEffect } from "react";
import ScrollToHashElement from "./features/ScrollToHashElement";
import Toast from "./components/Toast";
import AuthorisationModal from "./auth/Autorisation/AuthorisationModal";
import Dialog from "./components/alerts/Dialog";
import ScanReceiptModal from "./features/Receipt/scan/ScanReceiptModal";
import { RecoilRoot } from "recoil";
import useMessageListener from "./shop/services/useMessageListener/useMessageListener";

export default function App() {
	return (
		<AppContextProvider>
			<AppInner />
		</AppContextProvider>
	);
}

function AppInner() {
	return (
		<GroupColorSchemaProvider>
			<ColorSchemaProvided />
		</GroupColorSchemaProvider>
	);
}

function ColorSchemaProvided() {
	const { loadingManager, pageTitle, scanReceiptService } = useAppContext();

	useMessageListener();

	useEffect(() => {
		if (loadingManager.isSomethingLoading) {
			document.documentElement.classList.add("scroll-lock");
		} else {
			document.documentElement.classList.remove("scroll-lock");
		}
	}, [loadingManager.isSomethingLoading]);

	return (
		<RecoilRoot>
			<BlurFilter />

			<PageTitle title={pageTitle} />

			<Backdrop
				sx={{
					color: "white",
					zIndex: theme => theme.zIndex.drawer + 1,
					backdropFilter: "blur(5px)",
				}}
				open={loadingManager.isSomethingLoading}
			>
				<CircularProgress size={"5rem"} color={"inherit"} />
			</Backdrop>

			<Toast />
			<Dialog />

			<AuthorisationModal />

			<ScanReceiptModal service={scanReceiptService} />

			<AppOutletContextProvider />

			<ScrollToHashElement />
		</RecoilRoot>
	);
}

function AppOutletContextProvider() {
	const context = useAppContext();
	return <Outlet context={context} />;
}
