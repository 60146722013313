import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import RichTextRenderer from "../../../features/RichTextRenderer";
import { useGetClientWebPage } from "../hooks/useGetClientWebPage";

export default function ClientWebPageView() {
	const { slug } = useParams();

	const { data } = useGetClientWebPage(slug);

	const rendererContent = React.useMemo(() => {
		if (data?.page_content?.blocks?.length) {
			return data?.page_content;
		} else {
			return {
				blocks: [
					{
						type: "paragraph",
						data: {
							text: "No content",
						},
					},
				],
			};
		}
	}, [data]);

	if (!data) return null;

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 3,
				width: "100%",
			}}
		>
			<RichTextRenderer rendererRicheTextEditorContent={rendererContent} />
		</Box>
	);
}
